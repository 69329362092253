import React from 'react';
import NavNotSignedIn from '../../NavNotSignedIn/NavNotSignedIn';
import Footer from '../Footer';

const SellerAgreement = (props) => {

    return (
        <div>
            <NavNotSignedIn />
            <h4 style={{ textAlign: 'center' }}>Seller Agreement</h4>
            <div className="container">
                <div className="row">
                    <p>
                        PLEASE READ THE ENTIRE AGREEMENT.<br />
                        YOU MAY PRINT THIS PAGE FOR YOUR RECORDS. <br />
                        THIS IS A LEGAL AGREEMENT BETWEEN YOU AND MERCHANT,(Bongkonect) <br />
                        BY SUBMITTING THE ONLINE APPLICATION YOU ARE AGREEING THAT YOU HAVE READ AND UNDERSTAND THE TERMS AND CONDITIONS OF THIS AGREEMENT AND THAT YOU AGREE TO BE LEGALLY RESPONSIBLE FOR EACH AND EVERY TERM AND CONDITION.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        1) Overview
                    </h6>
                </div>

                <div className="row">
                    <p>
                        This Agreement contains the complete terms and conditions that apply to you becoming a Seller in Bongkonect's Seler Program.The purpose of this Agreement is to alow HTML linking between your website and the Bongkonect website. Please note that throughout this Agreement, "we," "us," and "our" refer to Bongkonect, and "you, ""your" and "yours" refer to the seler.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        2) PURPOSE
                    </h6>
                </div>

                <div className="row">
                    <p>
                        This Agreement is made and is efective by and between M/S Bongkonect, a company,legaly owned trademark, copyright, software products asociated with Bongkonect.com or bongkonect.in.
    &
    You(Seler),
    Who is looking for a technology enabled platform to offer for sell of various goods or rendering services in his own capacity.
    This Agreement shal be efective from 25th December,2019 and remains in efect until terminated in acordance with the terms of this Agreement.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        3) AGREEMENT TERMS
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Seller agrees to provide various products or services to general users of Bongkonect.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        4) Seller Obligations
                    </h6>
                </div>

                <div className="row">
                    <p>
                        4.1. To begin the enrolment proces, you will complete and submit the online application at the Bongkonect's server.The fact that wea uto-approve applications doesnot imply that we may not re-evaluate your application at a later time. We may reject your application at our solediscretion. We may cancel your application if we determine that your post on our site is unsuitable for our Program, including if it: <br />
                        4.1.1. Promotes sexualy explicit materials. <br />
                        4.1.2. Promotes violence. <br />
                        4.1.3. Promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age. <br />
                        4.1.4. Promotes ilegal activity. <br />
                        4.1.5. Incorporates any materials which infringe or asist others to infringe on any copyright, trademark or other intelectual property rights or to violate the law. <br />
                        4.1.6. Includes "Merchant" or variations or mispelings thereof in its domain name. <br />
                        4.1.7. Is otherwise in any way unlawful, harmful, threatening, defamatory, obscene, harasing, or racialy, ethnicaly or otherwise objectionable to us in our solediscretion. <br />
                        4.1.8. Contains software downloads that potentialy enabled versions of commision from other selers in our program. <br />
                        4.1.9. You may not create or design your website or any other website that you operate, explicitly or implied in a manner which resembles our website nor design your website in a manner which leads customers to be lieve you are Bongkonect or any other seler’s busines. <br /><br />
                        4.2. As a member of Bongkonect's Seler Program, you will have access to SelerAcount Manager. Here you will be able to review our Program’s details and previously-published Seler newsleters, download HTML code (that provides for links to webpages with in the Bongkonect website) and banner creatives, browse and get tracking codes for our coupons and deals. In order for us to acurately keep track of al guest visits from yoursite to ours, you must use the HTML code that we provide for each banner, text link, orother Seler link we provide you with.<br />
                        4.3. Bongkonect reserves the right, at anytime, to review your placement and approve the use of Your Links and require that you change the placement or use to comply with the guidelines provided to you.<br />
                        4.4. The maintenance and the updating of your own site (if any) will be your responsibility. We may monitor your activities as we feel necesary to make sure that it is up-to-date and to notify you of any changes that we feel should enhance your performance.<br />
                        4.5. It is entirely your responsibility to folow al applicable intelectual property andother laws that pertain to site. You must have expres permision to use any person's copyrighted material, whether it be a writing, an image, or any other copyrightable work. We wil not be responsible (and you wil be solely responsible) if you use another person's copyrighted material or other intelectual property inviolation of the law or anyt hird party rights.<br />
                    </p>

                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        5) Bongkonect Rights and Obligations
                    </h6>
                </div>

                <div className="row">
                    <p>
                        5.1. We have the right to monitor your activities at our site at any time to determine if you are folowing the terms and conditions of this Agreement. We may notify you of any changes to your behavior that we feel should be made, or to make sure that your links to our website are appropriate and to notify further you of any changes that we feel should be made. If you donot make the changes to your activities that we feel are necesary, we reserve the right to terminate your participation in the Bongkonect Seler Program. <br />
                        5.2. Bongkonect reserves the right to terminate this Agreement and your participation in the Bongkonect Seler Program immediately and without notice to you should you commit fraud in your use of the Bongkonect Seler Program or should you abuse this program ina nyway. If such fraud or abuse is detected, Bongkonect shal not be liable to you for any refund for such fraudulent activities. <br />
                        5.3. This Agreement wil begin upon our aceptance of your Seler application, and wil continue unles terminated here under. <br />
                    </p>

                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        6) Termination
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Either you or we may end this Agreement AT ANYTIME, with or without cause, by giving the other party written notice . Written notice can be in the form of mail, email or fax. In addition, this Agreement wil terminate immediately upon any breach of this Agreement by you.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        7) Modification
                    </h6>
                </div>

                <div className="row">
                    <p>
                        We may modify any of the terms and conditions in this Agreement at any time at our sole discretion. In such event, you wil be notified by Email. Modifications may include, but are not limited to, changes in the payment procedures and Bongkonect's Seller Program rules. If any modification is unaceptable to you, your only option is to end this Agreement. Your continued participation in Bongkonect's Seler Program folowing the posting of a change notice or new Agreement on our site wil indicate your agreement to the changes.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        8) Payment
                    </h6>
                </div>

                <div className="row">
                    <p>
                        You agree to pay Bongkonect a fixed membership fee for your unlimited uses of Bongkonect which may what so ever change from time to time. Refunds wil not be made if you cancel your membership plan. Bongkonect uses a third party to handle al of the tracking and payment. The third party is the payment gateways such as Paytm or any other payment methods. Kindly review the gateway’s payment terms and conditions.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        9) Access to Seler Account Interface
                    </h6>
                </div>

                <div className="row">
                    <p>
                        You will create a pasword so that you may enter bongkonect' secure Seler acount interface.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        10) Promotion Restrictions
                    </h6>
                </div>

                <div className="row">
                    <p>
                        10.1. You are free to promote your own products or services but naturaly any promotion that mentions Bongkonect could be perceived by the public or the pres as a joint efort. You should know that certain forms of advertising are always prohibited by Bongkonect. For example, advertising commonly refered to as "spamming" is unaceptable to us and could cause damage to our name. Other generaly prohibited forms of advertising include the use of unsolicited commercial email(UCE), postings to non-commercial news groups and cros-posting to multiple news groups at once. In addition, you may not advertise in anyway that efectively conceals or misrepresents your identity, your domain name, or your return email addres. You may use mailings to customers to promote Bongkonect so long as the recipient is already a customer or subscriber of your services or website, and recipients have the option to remove themselves from future mailings. Also, you may post to newsgroups to promote Bongkonect so long asthe newsgroup specificaly welcomes commercial mesages. At al times, you must clearly represent your self and your websites as independent from Bongkonect. If it comes to our atention that you are spamming, we wil consider that cause for immediate termination of this Agreement and your participation in th eBongkonect Seler Program. Any pending balances owed to you wil not be paid if your account is terminated due to such unaceptable advertising or solicitation. <br />
                        10.2. Selers that among other keywords or exclusively bid in their Pay-Per-Click campaignson keywords such as Bongkonect, www.Bongkonect, and/or any mispelings or similar alterations of these–be it separately or in combination with other keywords–and do not direct the trafic from such campaigns to their own website prior to re-directingit to ours, wil be considered
                        trademark violators, and will be banned from Merchant’s Seller Program . We will do everything possible to contact the Seler prior to the ban. However, were serve the right to expel any trademark violator from our Seler program without prior notice, and on the first ocurence of such PPC bidding behavior.<br />
                        10.3. Selers are not prohibited from keying in prospect’s information into the lead form as long as the prospects’ information is real and true, and the searevalid leads (i.e. sincerely interested in Merchant’s service).
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        11) Grant of Licences
                    </h6>
                </div>

                <div className="row">
                    <p>
                        11.1. We grant to you a non-exclusive, non-transferable, revocable right to
                        access our site through HTML links solely in acordance with the terms of this Agreement and solely in connection with such links, to use our logos, trade names, trade marks, and similar identifying material (colectively, the "Licensed Materials") that we provide to you or authorized for such purpose.
                        You are only entitled to use the Licensed Materials to the extent that you are a member in good standing of Bongkonect's Seler Program. You agree that al uses of the Licensed Materials wil be on behalf of Bongkonect and the good wil asociated there with wil be atached to the sole benefit of Bongkonect.<br />
                        11.2. Each party agrees not to use the other's proprietary materials in any manner that is disparaging, misleading, obscene or that otherwise portrays the party in a negative light. Each party reserves al of its respective rights in the proprietary materials covered by this license. Other than the license granted in this Agreement, each party retains alright, title, and interest to
                        its respective rights and no right,title,or interest is transfered to the other.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        12) Disclaimer
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Bongkonect MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES
                        REGARDING Bongkonect SERVICE AND WEBSITE OR THE PRODUCTS OR SERVICES PROVIDED THERE IN, ANY IMPLIED WARRANTIES OF Bongkonect ABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT ARE EXPRESSLY DISCLAIMEDAND EXCLUDED . IN ADDITION , WE MAKE NO REPRESENTATION THAT THE OPERATION OF OUR SITE WILL BEU NINTERRUPTED OR ERROR FREE, AND WE WILL NOT BE LIABLE FOR THE CONSEQUENCES OF ANY INTERRUPTIONS OR ERRORS.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        13) Representations and Waranties
                    </h6>
                </div>

                <div className="row">
                    <p>
                        You represent and warant that: <br />
                        13.1. This Agreement has been duly and validly executed and delivered by you and constitutes your legal, valid, and binding obligation, enforce able against you ina cordance with its terms. <br />
                        13.2. You have the ful right, power, and authority to enter into and bound  by the terms and conditions of this Agreement and to perform your obligations under this Agreement, without the approval or consent of any other party. <br />
                        11.3. You have suficient right, title, and interest in your ofered goods or services.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        14) Limitations of Liability
                    </h6>
                </div>

                <div className="row">
                    <p>
                        W E WILL NOT BE LIABLE TO YOU WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,SPECIAL OR EXEMPLARY DAMAGES (INCLUDING, WITHOUT LIMITATION,LOSS OF REVENUE OR GOOD WILL OR ANTICIPATED PROFITS ORL OST BUSINESS), EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FURTHER , NOT WITHSTANDING ANYTHING TO  THE CONTRARY CONTAINED IN THIS
                        AGREEMENT, IN NO EVENT SHALL Bongkonect'S CUMULATIVR LIABILITY TO YOU
                        ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER BASED IN CONTRACT, NEGLIGENCE, STRICT LIABILITY, TO OTHER LEGAL OR EQUITABLE THEORY,
                        EXCEED THE TOTAL COMMISSION FEES PAID TO YOU UNDER THIS AGREEMENT.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        15) Indemnification
                    </h6>
                </div>

                <div className="row">
                    <p>
                        You here by agree to indemnify and hold harmles Bongkonect, and its subsidiaries and other selers, and their directors, oficers, employees, agents, shareholders, partners, members, and other owners, against any and al claims, actions, demands, liabilities, loses, damages, judgments, setlements, costs, and expenses (including reasonable atorneys' fees) (any or al of the foregoing here in after refered to as"Loses") insofaras such Loses (or actions in respect there of) a rise out of or a rebased on (i) any claim that our use of the Seller trademarks in fringes on any trademark, tradename, servicemark, copyright, license, intelectual property, or other proprietary right of any third party, (i) any mis representation of are presentation or warant yor breach of acovenantand agreement made by you herein, or (i) any claim related to your site, including, without limitation, content there in not atributable to us.
                    </p>
                </div>


                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        16) Confidentiality
                    </h6>
                </div>

                <div className="row">
                    <p>
                        All confidential information, including, but not limited to, any busines, technical, financial, and customer information, disclosed by one party to the other during negotiations or the efective term of this Agreement which is marked "Confidential," wil remain the sole property of the disclosing party, and each party wil keep in confidence and not use or disclose such proprietary information of the other party without expres write and permision of the disclosing party.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        17) Miscelaneous
                    </h6>
                </div>

                <div className="row">
                    <p>
                        17.1. You agree that you are an independent contractor, and nothing in this Agreement will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between you and Bongkonect. You wil have no authority to make or acept any
                                ofers or represent ations on our behalf. You wil not make any statement, whether on Your Site or any other of Your Site or otherwise, that reasonably would contradict anything in this Section. <br />
                        17.2. Neither party may asign its rights or obligations under thisA greement to any party, except to a party who obtains all or substantialy all of the busines or asets of a third party.<br />
                        17.3. This Agreement shal be governed by and interpreted in acordance with the laws of India without regard to the conflicts of laws and principles thereof.<br />
                        17.4. You may not amend or waive any provision of this Agreement unles in writing and signed bybothparties.<br />
                        17.5. This Agreement represents the entire agreement between us and you, and shal supersede al prior agreements and communications of the parties, or al or writen.<br />
                        17.6. The headings and titles contained in this Agreement are included for convenienceonly, and shal not limit or otherwise afect the terms of this Agreement.<br />
                        17.7. If any provision of this Agreement is held to be invalid or unenforceable,that provision shal be eliminated or limited to the minimum extent necesary such that the intent of the parties is efectuated, and there main der of this  agreement shal have ful force and efect.<br />
                    </p>
                </div>

            </div>

        </div>
    )
}

export default SellerAgreement;