import React from 'react';
import NavNotSignedIn from '../../NavNotSignedIn/NavNotSignedIn';
import Footer from '../Footer';

const CommunityGuidelines = (props) => {

    return (
        <div>
            <NavNotSignedIn />
            <h4 style={{ textAlign: 'center' }}>Community Guidelines</h4>
            <div className="container">
                <div className="row">
                    <p>
                        We want Bongkonect to continue to be an authentic and safe place for inspiration and expression. Help us foster this community. Bongkonect is a reflection of our diverse community of cultures, ages, and beliefs. We’ve spent a lot of time thinking about the different points of view that create a safe and open environment for everyone.
                    </p>
                    <p>
                        We created the Community Guidelines so you can help us foster and protect this amazing community . By using Bongkonect & sharing your content with our other community members, you agree to the guidelines and our Terms of Use. We’re commited to these guidelines and we hope you are too. Overstepping thes eboundaries may result in deleted content, disabled accounts, or other restrictions.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        Share only Video Recordings and videos that you’ve taken or have the right to share.
                    </h6>
                </div>

                <div className="row">
                    <p>
                        As always, you own the content you post on Bongkonect. Remember to post authentic content, and don’t post anything you’ve copied or collected from the Internet that you don’t have the right to post or Copyright in the content is owned by someone, or any such copyrighted material which is beyond the scope of fair use.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        Do Not Post Video Recordings and videos that are non-appropriate for a diverse audience.
                    </h6>
                </div>

                <div className="row">
                    <p>
                        This includes Video Recordings, videos, and some digitaly-created content that sound sexual intercourse, women screaming, other sexual orientation. It also includes some Video Recordings of scaring and women activity.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        Foster meaning ful and genuine interactions.
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Help us stay spam-free by not artificialy colecting likes, followers, or shares, posting repetitive comments or content, or repeatedly contacting people for commercial purposes without their consent.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        Follow the law.
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Bongkonect is not a place to support or praise terorism, organized crime, or hate groups, Political Discusion or Discourse, Ofering sexual services, buying or seling fire arms and illegal or prescription drugs (even if it’s legal in your region) is also not allowed.
                    </p>

                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        Respect other members of the Bongkonect community.
                    </h6>
                </div>

                <div className="row">
                    <p>
                        We want to foster a positive, diverse community. We remove content  that contains credible threats or hate speech, content that targets private individuals to degrade or sham e them , personal information meant to blackmail or haras someone, and repeated unwanted messages. We do generally allow stronger conversation around people  who are featured in the news or have a large public audience due to their profesion or chosen activities.
                    </p>
                    <p>
                        It's never OK to encourage violence or atack any one based on their race, ethnicity, national origin, sex, gender, gender identity, sexual orientation, religious af filiation, disabilities, or diseases. When hate speech is being shared to chalenge it or to raise awarenes, we may allow it. In those instances, we ask that you express your intent clearly.
                    </p>

                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        Help us keep the community strong:
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Each of us is an important part of the Bongkonect community . If you see something that you think may violate our guidelines, please help us by using our built-in reporting/EmailSupport
                        option. We have a global team that reviews these reports and works as quickly as possible to remove content that doesn’t meet our guidelines. Even if you or someone you know doesn’t have an Bongkonect account, you can still file a report. When you complete the report, try to provide as much information as possible,such as links, usernames, and descriptions of the content, so we can find and review it quickly. We may remove entire posts if either the imagery or associated captions violate our guidelines. For more information, check out our Help Center and Terms of Use.
                    </p>
                    <p>
                        Thank you for helping us create one of the best communities in the world, The Bongkonect Team.
                    </p>
                </div>

            </div>

        </div>
    )
}

export default CommunityGuidelines;