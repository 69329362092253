import * as actionTypes from './actionsTypes';
import axios from '../../axios';

export const fetchPosterBusinessesStart = () => {
    return {
        type: actionTypes.FETCH_POSTER_BUSINESSES_START
    }
}

export const fetchPosterBusinessesSuccess = (posterBusinesses) => {
    return {
        type: actionTypes.FETCH_POSTER_BUSINESSES_SUCCESS,
        posterBusinesses
    }
}

export const fetchPosterBusinessesFailed = () => {
    return {
        type: actionTypes.FETCH_POSTER_BUSINESSES_FAILED
    }
}

export const searchBusinessStart = () => {
    return {
        type: actionTypes.SEARCH_BUSINESSES_START
    }
}

export const searchBusinessSuccess = (businesses) => {
    return {
        type: actionTypes.SEARCH_BUSINESSES_SUCCESS,
        businesses
    }
}

export const searchBusinessFailed = () => {
    return {
        type: actionTypes.SEARCH_BUSINESSES_FAILED
    }
}


export const searchBusiness = (category = '', city = '') => {
    return dispatch => {
        dispatch(searchBusinessStart());
        axios.post('getBusinesses', { category, city })
            .then(response => dispatch(searchBusinessSuccess(response.data.posts)))
            .catch(err => dispatch(searchBusinessFailed()))
    }
}

export const fetchPosterBusinesses = (size = 6) => {
    return dispatch => {
        dispatch(fetchPosterBusinessesStart());
        axios.get('getPosterBusiness', { params: { size } })
            .then(response => dispatch(fetchPosterBusinessesSuccess(response.data.posts)))
            .catch(err => dispatch(fetchPosterBusinessesFailed()))
    }
}