import React, { useEffect } from "react";
import M from "materialize-css";
import { NavLink } from "react-router-dom";
// import './NavNotSignedIn.css'
import { IMAGE_URL } from "../../axios";

const NavNotSignedIn = (props) => {
  useEffect(() => {
    M.AutoInit();
  }, []);

  return (
    <div>
      <div className="navbar-fixed">
        <nav className="bg-darkRed">
          <div className="bg-darkRed">
            <NavLink to="/" className="brand-logo logo-container">
              <img
                className="logo-img"
                src={IMAGE_URL + "images/logo-white-bg.png"}
                alt="logo"
              />
            </NavLink>
            <a href="#" data-target="mobile-demo" className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </a>
            <ul
              className="right hide-on-med-and-down"
              style={{ marginRight: "50px" }}
            >
              <li>
                <NavLink to="/signup">
                  <strong style={{ fontWeight: "bold" }}>Join Now</strong>
                </NavLink>
              </li>
              <li>
                <NavLink to="/login">
                  <strong>Sign In</strong>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <ul className="sidenav" id="mobile-demo">
        <li>
          <NavLink to="/login" className="sidenav-close">
            <strong style={{ fontWeight: "bold" }}>Sign In</strong>
          </NavLink>
        </li>
        <li>
          <NavLink to="/signup" className="sidenav-close">
            <strong style={{ fontWeight: "bold" }}>Join Now</strong>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default NavNotSignedIn;
