export const BANNERSUBMIT = 'BANNERSUBMIT';
export const POSTERSUBMIT = 'POSTERSUBMIT';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const AUTHENTICATE_INDIVIDUAL = 'AUTHENTICATE_INDIVIDUAL';
export const AUTHENTICATE_BUSINESS = 'AUTHENTICATE_BUSINESS';

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const LOGOUT = 'LOGOUT';

export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const UPDATE_PROFILEPIC_START = 'UPDATE_PROFILEPIC_START';
export const UPDATE_PROFILEPIC_SUCCESS = 'UPDATE_PROFILEPIC_SUCCESS';
export const UPDATE_PROFILEPIC_FAILED = 'UPDATE_PROFILEPIC_FAILED';

export const UPDATE_PROFILEBACKGROUND_START = 'UPDATE_PROFILEBACKGROUND_START';
export const UPDATE_PROFILEBACKGROUND_SUCCESS = 'UPDATE_PROFILEBACKGROUND_SUCCESS';
export const UPDATE_PROFILEPBACKGROUND_FAILED = 'UPDATE_PROFILEBACKGROUND_FAILED';


export const POST_UPLOAD_START = 'POST_UPLOAD_START';
export const POST_UPLOAD_SUCCESS = 'POST_UPLOAD_SUCCESS';
export const POST_UPLOAD_FAILED = 'POST_UPLOAD_FAILED';

export const FETCH_POSTS_START = 'FETCH_POSTS_START';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILED = 'FETCH_POSTS_FAILED';
export const ADD_POST = 'ADD_POST';

export const DELETE_POST_START = 'DELETE_POSTS_START';
export const DELETE_POST_SUCCESS = 'DELETE_POSTS_SUCCESS';
export const DELETE_POST_FAILED = 'DELETE_POSTS_FAILED';

export const FETCH_BUSINESSES_START = 'FETCH_BUSINESSES_START';
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS';
export const FETCH_BUSINESSES_FAILED = 'FETCH_BUSINESSES_FAILED';

export const FETCH_POSTER_BUSINESSES_START = 'FETCH_POSTER_BUSINESSES_START';
export const FETCH_POSTER_BUSINESSES_SUCCESS = 'FETCH_POSTER_BUSINESSES_SUCCESS';
export const FETCH_POSTER_BUSINESSES_FAILED = 'FETCH_POSTER_BUSINESSES_FAILED';

export const SEARCH_BUSINESSES_START = 'SEARCH_BUSINESSES_START';
export const SEARCH_BUSINESSES_SUCCESS = 'SEARCH_BUSINESSES_SUCCESS';
export const SEARCH_BUSINESSES_FAILED = 'SEARCH_BUSINESSES_FAILED';



