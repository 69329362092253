import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    posts: [],
    loading: false
}

const postsReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_POSTS_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.FETCH_POSTS_FAILED:
            return {
                ...state,
                loading: false
            }

        case actionTypes.FETCH_POSTS_SUCCESS:
            return {
                ...state,
                posts: action.payload.posts.posts,
                loading: false
            }

        case actionTypes.DELETE_POST_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.DELETE_POST_SUCCESS:

            const myPosts = state.posts.filter(function (obj) {
                
                return obj.post._id !== action.postId;
            });
            return {
                ...state,
                posts: myPosts,
                loading: false
            }

        case actionTypes.DELETE_POST_FAILED:
            return {
                ...state,
                loading: false
            }

        default:
            return state
    }
}

export default postsReducer;