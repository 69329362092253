import React, { useState, useEffect } from "react";
import MyNav from "../../components/Nav/Nav";
import axios from "../../axios";
import { IMAGE_URL } from "../../axios";
import debounce from "lodash.debounce";
import M from "materialize-css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NavNotSignedIn from "../../components/NavNotSignedIn/NavNotSignedIn";
import { Redirect } from "react-router-dom";
import {
  Player,
  ControlBar,
  VolumeMenuButton,
  Shortcut,
  BigPlayButton,
  PlayToggle,
} from "video-react";
import "./Video.css";

const Video = (props) => {
  const [category, setCategory] = useState("");
  const [youtubeVideoCategory, setYoutubeVideoCategory] = useState("");
  const [youtubeVideoLink, setYoutubeVideoLink] = useState("");
  const [youtubeVideos, setYoutubeVideos] = useState([]);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [moviePosterLoading, setMoviePosterLoading] = useState(false);
  const [video, setVideo] = useState(null);
  const [moviePoster, setMoviePoster] = useState(null);
  const [termsChecked, setTerms] = useState(false);
  const [termsCheckedPoster, setTermsPoster] = useState(false);

  const [adVideo, setAdVideo] = useState(null);
  const [adLoading, setAdLoading] = useState(false);
  const [videoError, setVideoError] = useState("");
  const [posterError, setPosterError] = useState("");
  const [searchText, setSearchText] = useState("");

  const [moviePosterFile, setMoviePosterFile] = useState(null);

  const [showPaymentVideo, setShowPaymentVideo] = useState(false);
  const [orderVideo, setOrderVideo] = useState({});
  const [showPaymentPoster, setShowPaymentPoster] = useState(false);
  const [orderPoster, setOrderPoster] = useState({});
  const [tempVideoId, setTempVideoId] = useState("");
  const [tempPosterId, setTempPosterId] = useState("");

  const [profileColor, setProfileColor] = useState("grey");
  const [posterColor, setPosterColor] = useState("grey");
  const [video_ad_place_holder, setAdPlaceholder] = useState("Select Video");
  const [poster_place_holder, setPosterPlaceholder] = useState("Select Poster");

  const MyLoader = () => (
    <ContentLoader
      height={250}
      width={320}
      speed={2}
      primaryColor="#dfdcdc"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="320" height="250" />
    </ContentLoader>
  );

  useEffect(() => {
    // Binds our scroll event handler
    window.onscroll = debounce(() => {
      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (error || isLoading || !hasMore) return;

      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        loadYoutubeVideos();
      }
    }, 100);
  });

  useEffect(() => {
    M.AutoInit();
    getMoviePoster();
    loadVideo();
    loadYoutubeVideos();
  }, []);

  const loadVideo = () => {
    axios
      .get("getVideos")
      .then((response) => {
        setVideo(response.data);
        incrementView(response.data._id);
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };

  const getMoviePoster = () => {
    axios
      .get("getMoviePoster")
      .then((response) => {
        setMoviePoster(response.data);
        incrementPosterView(response.data._id);
      })
      .catch((err) => console.log(err.data));
  };

  const incrementView = (id) => {
    axios
      .get("incrementView", { params: { videoId: id } })
      .then((response) => {})
      .catch((err) => {});
  };

  const incrementPosterView = (id) => {
    axios
      .get("incrementPosterView", { params: { posterId: id } })
      .then((response) => {})
      .catch((err) => {});
  };

  const loadYoutubeVideos = () => {
    setIsLoading(true);

    let params = { category };
    if (youtubeVideos && youtubeVideos.length > 0)
      params = {
        category,
        startValue: youtubeVideos[youtubeVideos.length - 1]._id,
      };
    axios
      .get("getYoutubeVideos", { params: params })
      .then((response) => {
        setHasMore(youtubeVideos.length < response.data.count);
        setIsLoading(false);
        setYoutubeVideos([...youtubeVideos, ...response.data.videos]);
      })
      .catch((err) => {
        setError(err.response.data);
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    search();
    //loadYoutubeVideos();
  };

  const search = () => {
    setIsLoading(true);
    let params = { category };

    axios
      .get("getYoutubeVideos", { params: params })
      .then((response) => {
        setHasMore(youtubeVideos.length < response.data.count);
        setIsLoading(false);
        setYoutubeVideos(response.data.videos);
      })
      .catch((err) => {
        setError(err.response.data);
        setIsLoading(false);
      });
  };

  const uploadVideo = (e) => {
    e.preventDefault();
    setLoading(true);
    const id = youtubeVideoLink.split("=")[1];
    const link = `https://www.youtube.com/embed/${id}`;
    axios
      .post("uploadYoutubeVideo", { category: youtubeVideoCategory, link })
      .then((response) => {
        setLoading(false);
        setYoutubeVideos([response.data, ...youtubeVideos]);
        //videos.unshift(response.data);
        //setYoutubeVideos(videos);
        //loadYoutubeVideos();
      })
      .catch((err) => setLoading(false));
  };

  const uploadAd = (e) => {
    e.preventDefault();

    setAdLoading(true);

    const data = new FormData();
    data.append("video", adVideo);
    axios
      .post("uploadVideo", data)
      .then((response) => {
        setAdLoading(false);
        if (response.data.slotFull) {
          let date = new Date(response.data.lastVideoExpireDate);
          toast.warn(
            `The slot for video ad is full. Please try after some days`,
            {
              position: "top-center",
              autoClose: 20000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        } else {
          //var date = new Date(response.data.startsOn);

          setOrderVideo(response.data.order);
          setTempVideoId(response.data.video._id);
          setShowPaymentVideo(true);
          // toast.success('Video Ad posted successfully. Ad will be live on - ' + date.getDate() + "/" + date.getUTCMonth() + "/" + date.getFullYear(), {
          //     position: "top-center",
          //     autoClose: 20000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true
          // });

          // props.history.push(
          //     `/payment?type=video&videoId=${response.data._id}`
          // );
        }
      })
      .catch((err) => {
        setAdLoading(false);
        setShowPaymentVideo(false);
      });
  };

  const uploadMoviePoster = (e) => {
    e.preventDefault();

    setMoviePosterLoading(true);

    const data = new FormData();
    data.append("poster", moviePosterFile);

    axios
      .post("uploadMoviePoster", data)
      .then((response) => {
        setMoviePosterLoading(false);
        if (response.data.slotFull) {
          let date = new Date(response.data.lastVideoExpireDate);
          toast.warn(
            `The slot for movie poster upload is full. Please try after some days`,
            {
              position: "top-center",
              autoClose: 20000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        } else {
          setOrderPoster(response.data.order);
          setTempPosterId(response.data.moviePoster._id);
          setShowPaymentPoster(true);

          //var date = new Date(response.data.startsOn);
          // toast.success('Video Ad posted successfully. Ad will be live on - ' + date.getDate() + "/" + date.getUTCMonth() + "/" + date.getFullYear(), {
          //     position: "top-center",
          //     autoClose: 20000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true
          // });

          // props.history.push(
          //     `/payment?type=poster&posterId=${response.data._id}`
          // );
        }
      })
      .catch((err) => {
        setAdLoading(false);
        setShowPaymentPoster(false);
      });
  };

  const onVideoFileChange = (e) => {
    if (e.target.files.length !== 0) {
      if (e.target.files[0].type.search("video") !== -1) {
        var video = document.createElement("video");
        video.preload = "metadata";
        video.src = URL.createObjectURL(e.target.files[0]);
        setVideoError("");
        setAdVideo(e.target.files[0]);
        setAdPlaceholder(e.target.files[0].name);
        video.onloadedmetadata = function () {
          if (video.duration > 30) {
            setVideoError(
              "Invalid Video! Video should not be greater than 30 seconds"
            );
            setAdVideo(null);
            setProfileColor("grey");
            setAdPlaceholder("Select Video");
            window.URL.revokeObjectURL(video.src);
          } else {
            setProfileColor("green");
          }
        };
      } else {
        setVideoError("Invalid file type. Please select a Video.");
        setAdVideo(null);
        setProfileColor("grey");
        setAdPlaceholder("Select Video");
      }
    }
  };

  const onPosterFileChange = (e) => {
    if (e.target.files.length !== 0) {
      if (e.target.files[0].type.search("image") !== -1) {
        setPosterError("");
        setMoviePosterFile(e.target.files[0]);
        setPosterColor("green");
        setPosterPlaceholder(e.target.files[0].name);
      } else {
        setPosterError("Invalid file type. Please select an Image.");
        setMoviePosterFile(null);
        setPosterColor("grey");
        setPosterPlaceholder("Select Poster");
      }
    }
  };

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Bongkonect - konect your social business and professional community
        </title>
        <meta
          name="description"
          content="Bongkonect - konect your social business and professional community"
        />
        <meta
          name="keywords"
          content="BANGLA SONG, BANGLA VIDEO, BANGLA ADS, BANGLA SOCIAL MEDIA, BENGALI SOCIAL MEDIA, BENGALI VIDEO"
        />
      </Helmet>
      {props.isAuthenticatedIndividual || props.isAuthenticatedBusiness ? (
        <MyNav location={props.location} />
      ) : (
        <NavNotSignedIn location={props.location} />
      )}

      {showPaymentVideo && (
        <Redirect
          to={{
            pathname: "/payment",
            state: { order: orderVideo, videoId: tempVideoId, type: "video" },
          }}
        />
      )}
      {showPaymentPoster && (
        <Redirect
          to={{
            pathname: "/payment",
            state: {
              order: orderPoster,
              posterId: tempPosterId,
              type: "poster",
            },
          }}
        />
      )}

      {loading ? (
        <div>
          <div className="progress">
            <div className="indeterminate"></div>
          </div>
        </div>
      ) : null}

      {adLoading ? (
        <div>
          <div className="progress">
            <div className="indeterminate"></div>
          </div>
        </div>
      ) : null}

      {moviePosterLoading ? (
        <div>
          <div className="progress">
            <div className="indeterminate"></div>
          </div>
        </div>
      ) : null}
      <div id="uploadModal" className="modal">
        <div className="modal-content">
          <h5>Upload Video</h5>
          <div className="row">
            <form className="col s12" onSubmit={(e) => uploadVideo(e)}>
              <div className="row">
                <div className="input-field col s12">
                  <select
                    style={{
                      backgroundColor: "white",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "grey",
                      width: "100%",
                    }}
                    className="selectBox browser-default"
                    value={youtubeVideoCategory}
                    onChange={(e) => setYoutubeVideoCategory(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select Category
                    </option>
                    <option value="Movie">বাংলা Movie</option>
                    <option value="Music/Art/Culture">
                      বাংলা Music/Art/Culture/DIY
                    </option>
                    <option value="Astrology">বাংলা Astrology/Vastu</option>
                    <option value="Travel/Food/Restaurant">
                      বাংলা Travel/Food/Restaurant
                    </option>
                    <option value="news">
                      বাংলা 24/7 Bangla News (Worldwide)
                    </option>
                    <option value="education">
                      বাংলা Healthcare/Education/Information/Sports
                    </option>
                  </select>
                </div>
                <div className="input-field col s12">
                  <input
                    id="url"
                    type="text"
                    className="validate"
                    pattern="^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?$"
                    required
                    value={youtubeVideoLink}
                    onChange={(e) => setYoutubeVideoLink(e.target.value)}
                  />
                  <label htmlFor="url">Video Url</label>
                </div>
              </div>
              <button
                type="submit"
                disabled={
                  youtubeVideoCategory.length == 0 ||
                  youtubeVideoLink.length == 0 ||
                  (youtubeVideoLink.length != 0 &&
                    !/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?$/.test(
                      youtubeVideoLink
                    ))
                }
                className="waves-effect waves-light btn modal-close"
                style={{ backgroundColor: "#fe0000" }}
              >
                Upload
              </button>
            </form>
          </div>
        </div>
      </div>

      <div id="uploadAdModal" className="modal">
        <div className="modal-content">
          <h5>Upload Ad</h5>
          <div className="row">
            <form className="col s12" onSubmit={(e) => uploadAd(e)}>
              <div className="row">
                <div className="col s6 file-field input-field">
                  <div
                    style={{
                      backgroundColor: "#eeeeee",
                      borderRadius: "20px",
                      padding: "5px 20px 5px 20px",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        color: profileColor,
                      }}
                    >
                      photo_library
                    </i>
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                      }}
                    >
                      {video_ad_place_holder}
                    </span>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={(e) => onVideoFileChange(e)}
                      required
                    />
                    {adVideo && (
                      <i
                        className="material-icons right"
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          color: "green",
                        }}
                      >
                        check
                      </i>
                    )}
                  </div>
                  <br />
                  <span className="helper-text">
                    Ad length - 30secs. Ad duration 168hours.
                  </span>
                </div>
              </div>

              <div className="input-field">
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={termsChecked}
                      onChange={(e) => setTerms(e.target.checked)}
                      required
                    />
                    <span>
                      By clicking Upload, you agree to the{" "}
                      <a className="modal-trigger" href="#adPolicyModal">
                        Terms and Conditions
                      </a>{" "}
                      set out by this site.
                    </span>
                  </label>
                </p>
              </div>

              <button
                type="submit"
                disabled={
                  videoError !== "" || adVideo === null || !termsChecked
                }
                className="waves-effect waves-light btn modal-close"
                style={{ backgroundColor: "#fe0000" }}
              >
                Upload
              </button>
            </form>
            <br />
            {videoError && videoError !== "" ? (
              <span style={{ color: "red" }}>* {videoError}</span>
            ) : null}
          </div>
        </div>
      </div>

      <div id="uploadMoviePosterModal" className="modal">
        <div className="modal-content">
          <h5>Upload Movie Poster</h5>
          <div className="row">
            <form className="col s12" onSubmit={(e) => uploadMoviePoster(e)}>
              <div className="row">
                <div className="col s6 file-field input-field">
                  <div
                    style={{
                      backgroundColor: "#eeeeee",
                      borderRadius: "20px",
                      padding: "5px 20px 5px 20px",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        color: posterColor,
                      }}
                    >
                      photo_library
                    </i>
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                      }}
                    >
                      {poster_place_holder}
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => onPosterFileChange(e)}
                      required
                    />
                    {moviePosterFile && (
                      <i
                        className="material-icons right"
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          color: "green",
                        }}
                      >
                        check
                      </i>
                    )}
                  </div>
                  <br />
                  <span className="helper-text">Poster duration 168hours.</span>
                </div>
              </div>

              <div className="input-field">
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={termsCheckedPoster}
                      onChange={(e) => setTermsPoster(e.target.checked)}
                      required
                    />
                    <span>
                      By clicking Upload, you agree to the{" "}
                      <a className="modal-trigger" href="#adPolicyModal">
                        Terms and Conditions
                      </a>{" "}
                      set out by this site.
                    </span>
                  </label>
                </p>
              </div>

              <button
                type="submit"
                disabled={
                  posterError !== "" ||
                  moviePosterFile === null ||
                  moviePosterLoading ||
                  !termsCheckedPoster
                }
                className="waves-effect waves-light btn modal-close"
                style={{ backgroundColor: "#fe0000" }}
              >
                Upload
              </button>
            </form>
            <br />
            {posterError && posterError !== "" ? (
              <span style={{ color: "red" }}>* {posterError}</span>
            ) : null}
          </div>
        </div>
      </div>

      <br />
      {!isMobile ? (
        <form className="row" onSubmit={(e) => handleSearch(e)}>
          <div
            className="col s9 m3 l3 xl3"
            style={isMobile ? null : { marginLeft: "50px" }}
          >
            <select
              style={{
                backgroundColor: "white",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "grey",
                width: "100%",
              }}
              className="selectBox browser-default"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              <option value="Movie">বাংলা Movie</option>
              <option value="Music/Art/Culture">
                বাংলা Music/Art/Culture/DIY
              </option>
              <option value="Astrology">বাংলা Astrology/Vastu</option>
              <option value="Travel/Food/Restaurant">
                বাংলা Travel/Food/Restaurant
              </option>
              <option value="news">বাংলা 24/7 Bangla News (Worldwide)</option>
              <option value="education">
                বাংলা Healthcare/Education/Information/Sports
              </option>
            </select>
          </div>

          <div className="col s1">
            <button
              className="btn"
              type="submit"
              style={{ height: "45px", backgroundColor: "#fe0000" }}
            >
              <i
                className="material-icons"
                style={{ fontSize: "2em", paddingTop: "5px" }}
              >
                search
              </i>
            </button>
          </div>

          <div className="col s0 m7 l7 xl7 right hide-on-small-only">
            <button
              type="submit"
              className="btn modal-trigger"
              data-target="uploadModal"
              style={{
                height: "45px",
                backgroundColor: "#fe0000",
                marginRight: "10px",
              }}
              onClick={() => {
                setYoutubeVideoLink("");
                setYoutubeVideoCategory("");
              }}
            >
              Video Upload
            </button>
            <button
              type="submit"
              className="btn modal-trigger"
              data-target="uploadAdModal"
              style={{
                height: "45px",
                backgroundColor: "#fe0000",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              onClick={() => {
                setAdVideo(null);
                setVideoError("");
                setProfileColor("grey");
                setAdPlaceholder("Select Video");
                setTerms(false);
              }}
            >
              Video ad
            </button>
            <button
              type="submit"
              className="btn modal-trigger"
              data-target="uploadMoviePosterModal"
              style={{
                height: "45px",
                backgroundColor: "#fe0000",
                marginLeft: "10px",
              }}
              onClick={() => {
                setMoviePosterFile(null);
                setPosterColor("grey");
                setPosterError("");
                setPosterPlaceholder("Select Poster");
                setTermsPoster(false);
              }}
            >
              Banner ad
            </button>
          </div>
        </form>
      ) : (
        <form onSubmit={(e) => handleSearch(e)}>
          <div className="row">
            <div className="col s9 m3 l3 xl3">
              <select
                style={{
                  backgroundColor: "white",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "grey",
                  width: "100%",
                }}
                className="selectBox browser-default"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                <option value="Movie">বাংলা Movie</option>
                <option value="Music/Art/Culture">
                  বাংলা Music/Art/Culture/DIY
                </option>
                <option value="Astrology">বাংলা Astrology/Vastu</option>
                <option value="Travel/Food/Restaurant">
                  বাংলা Travel/Food/Restaurant
                </option>
                <option value="news">বাংলা 24/7 Bangla News (Worldwide)</option>
                <option value="education">
                  বাংলা Healthcare/Education/Information/Sports
                </option>
              </select>
            </div>

            <div className="col s1">
              <button
                className="btn"
                type="submit"
                style={{ height: "45px", backgroundColor: "#fe0000" }}
              >
                <i
                  className="material-icons"
                  style={{ fontSize: "2em", paddingTop: "5px" }}
                >
                  search
                </i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col s4 m4">
              <button
                type="submit"
                className="btn modal-trigger"
                data-target="uploadModal"
                style={{
                  height: "45px",
                  backgroundColor: "#fe0000",
                  fontSize: "11px",
                }}
                onClick={() => {
                  setYoutubeVideoLink("");
                  setYoutubeVideoCategory("");
                }}
              >
                Video Upload
              </button>
            </div>
            <div className="col s4 m4">
              <button
                type="submit"
                className="btn modal-trigger"
                data-target="uploadAdModal"
                style={{
                  height: "45px",
                  backgroundColor: "#fe0000",
                  //fontSize: '13px'
                }}
                onClick={() => {
                  setAdVideo(null);
                  setVideoError("");
                  setProfileColor("grey");
                  setAdPlaceholder("Select Video");
                  setTerms(false);
                }}
              >
                Video ad
              </button>
            </div>
            <div className="col s4 m4">
              <button
                type="submit"
                className="btn modal-trigger"
                data-target="uploadMoviePosterModal"
                style={{
                  height: "45px",
                  backgroundColor: "#fe0000",
                  //fontSize: '10px'
                }}
                onClick={() => {
                  setMoviePosterFile(null);
                  setPosterColor("grey");
                  setPosterError("");
                  setPosterPlaceholder("Select Poster");
                  setTermsPoster(false);
                }}
              >
                Banner ad
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="row">
        <div className="col s12 m6 l6 xl6">
          {video ? (
            <div style={isMobile ? null : { marginLeft: "50px" }}>
              <Player
                playsInline
                autoPlay
                loop
                src={IMAGE_URL + (video === null ? "" : video.link)}
              >
                <ControlBar disableDefaultControls>
                  <VolumeMenuButton />
                </ControlBar>
                <BigPlayButton position="center" />
                <Shortcut clickable={false} dblclickable={false} />
              </Player>
              <strong
                className="left"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "1.2em",
                }}
              >
                {video.views} views <br />
              </strong>
            </div>
          ) : (
            <div style={isMobile ? null : { marginLeft: "50px" }}>
              <Player
                playsInline
                autoPlay
                loop
                src={IMAGE_URL + "videos/Video_Promo.mp4"}
              >
                <ControlBar disableDefaultControls>
                  <PlayToggle />
                  <VolumeMenuButton />
                </ControlBar>
                <BigPlayButton position="center" />
                <Shortcut dblclickable={false} />
              </Player>
            </div>
          )}
        </div>
        <div className="col s12 m5 l5 xl5 valign-wrapper">
          {moviePoster ? (
            <div>
              <img
                src={IMAGE_URL + moviePoster.poster}
                height={isMobile ? "250" : "300"}
                width={isMobile ? "100%" : "95%"}
                style={
                  isMobile ? null : { marginTop: "20px", marginBottom: "20px" }
                }
              />
              <strong
                className="left"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "1.2em",
                }}
              >
                {moviePoster.views} views <br />
              </strong>
            </div>
          ) : (
            <div>
              <img
                src={IMAGE_URL + "images/Banner_Promo.jpeg"}
                height={isMobile ? "250" : "300"}
                width={isMobile ? "100%" : "95%"}
                style={
                  isMobile
                    ? { marginTop: "15px" }
                    : { marginTop: "20px", marginBottom: "20px" }
                }
              />
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="divider"></div>
      </div>

      <div className="row">
        {youtubeVideos.map((video, index) => {
          return (
            <div className="col s12 m3" key={video._id}>
              <div className="video-container">
                <iframe
                  src={video.link}
                  title={video._id}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <br />
            </div>
          );
        })}

        {isLoading && (
          <div className="row">
            <div className="col s12 m3">
              <MyLoader />
            </div>
            <div className="col s12 m3">
              <MyLoader />
            </div>
            <div className="col s12 m3">
              <MyLoader />
            </div>
            <div className="col s12 m3">
              <MyLoader />
            </div>
          </div>
        )}
      </div>

      <div id="adPolicyModal" className="modal">
        <div className="modal-content">
          <h4 style={{ textAlign: "center" }}>Terms and Conditions</h4>
          <div className="">
            <div className="row">
              <p>
                This agreement is in between Advertiser (i.e "You" ) And M/S
                Bongkonect; an online community based platform. This agreement
                is at per guidelines laid down by Advertising Standards Council
                of India (ASCI) and other subsequent laws and Internet
                advertising policies in India, USA and European Union. You can
                target your desired audience by buying ads to be delivered on
                Bongkonet. Listing Policy For use of our Site and other
                services, you confirm and declare that you shall not list or
                post or provide information in relation to the sale or purchase
                or exchange of goods and services, content or information that
                are illegal under the laws of the Republic of India and/or are
                not permitted as per the prohibited items policy listed below.
              </p>
            </div>

            <div className="row">
              <p>1. Prohibited Items Policy.</p>
            </div>

            <div className="row">
              <p>
                A. We specifically prohibit any listing or posting of
                classifieds or information in relation to the following items:{" "}
                <br />
                Alcoholic Beverages, Liquor, tobacco products, prescription
                drugs, psychotropic substances, narcotics, intoxicants of any
                description, medicines, palliative/curative substances nor shall
                you provide link directly or indirectly to or include
                descriptions of items, goods or services that are prohibited
                under any applicable law for the time being in force including
                but not limited to the Drugs and Cosmetics Act, 1940, the Drugs
                And Magic Remedies (Objectionable Advertisements) Act, 1954
                Narcotic Drug and Prohibited Substances Act and the Indian Penal
                Code, 1860. <br />
                Living, dead person and/or the whole or any part of any human
                which has been kept or preserved by any means whether artificial
                or natural including any blood, bodily fluids and/ or body parts{" "}
                <br />
                Prostitution or any other service in the nature there of that
                purports to violate the provisions of Immoral Act or Indecent
                representation of women which violates the contemporary
                standards of morality and decency in Indian society. <br />
                Mature Audiences Policy includes films which do not have a
                certificate for public exhibition issued by the Central Board of
                Film Certification and or described and depict or otherwise deal
                with matters which arerevolting or repulsive and or tend to
                deprave a persons mind in such a way that they tend to offend
                against the standards of morality, decency and propriety
                generally accepted by reasonable adults <br />
                Obscene Items includes items which contain an indecent
                representation of women within the meaning of the Indecent
                Representation of Women (Prohibition) Act, 1986; Any publication
                or film or item that describes or depicts a minor who is, or who
                appears to be, under 18 (whether the minor is engaged in sexual
                activity or not) and any computer games not suitable for minor
                that are unsuitable for a minor to see or play. <br />
                Offensive Material intended for use in a sexual setting
                (including "bondage" and "fetish" items,) displaying sexual
                activity or portraying human genitalia in a "life-like" or
                realistic fashion <br />
                "Antiquities" and "Art Treasures" in violation of the provisions
                of the Antiquities and Art Treasures Act, 1972 ("the Act"){" "}
                <br />
                Information or items that are defamatory, libelous, threatening
                or abusive in nature Information that is fraudulent,
                misrepresenting as to the nature and use of the goods or the
                services. Counterfeit, Pirated and stolen goods or unauthorized
                illegal services (services for which you are not licensed or
                permitted to do or do not have the authority to under take).{" "}
                <br />
                Items, goods and services that infringe or attempt to pass off
                any third parties intellectual property or rights of publicity
                or moral rights and or purport's to breach any persons right to
                privacy. <br />
                Electronically transmitting through any medium computer viruses
                of any type or any computer program that facilitates hacking of
                a computer system which the intent to damage a computer or
                computer network or intercept any personal data. <br />
                Your information shall not include any hate content, that is
                derogatory or slanderous in nature that may directed to any
                individual or group or advocate violence against any users
                individuals and or animals. Hazardous chemicals and pesticides
                and/ or items in violation of Hazardous Chemicals Act, 1985.
                Fireworks, Destructive Devices and Explosives including any
                material that enables the making of fireworks, explosive
                triggers and explosive devices. <br />
                Identity Documents, Personal Financial Records & Personal
                Information (in any form, including mailing lists) <br />
                Lottery Tickets, Sweepstakes Entries and Slot Machines <br />
                Items in violation of the Food Adulteration Act, 1954 <br />
                Military or Police Badges, Uniforms, coats-of-arms and or any
                Government emblems, insignia, and/ or items in violation of
                Emblems and names (Prevention of improper use) Act, 1950 and/ or
                Flag Codes of India Act, 2002Weapons and related items (such as
                firearms, firearm parts and magazines, ammunition, tear gas,
                stun guns, switchblade knives or any other item which is
                prohibited under the Indian Arms Act, 1959. <br />
                "Pyramid schemes" and "Multilevel Marketing" and/ or similar
                scams which are solely listed for the purpose of defrauding
                users. <br />
                Spam, abusive, duplicate, listing, fraud schemes (e.g. "Get rich
                quick" "work at homes" scams which are solely listed for the
                purpose of duping users) Inappropriate, Wrong Category (e.g
                dining table listed as office furniture). <br />
                Incorrect City / Location of Listing (listing allowed only in
                the city you are based in, of listing) International Listings.{" "}
                <br />
              </p>
            </div>

            <div className="row">
              <p>
                B. Without prejudice to the generality of the above, Bongkonect
                does not permit posting or listing of classifieds in relation to
                the following: <br />
                "Securities" within the meaning of the Securities Contract
                Regulation Act, 1956, including shares, bonds, debentures, etc.
                and/or any other financial instruments/assets of any description
                Living, dead creatures and/or the whole or any part of any
                animal which has been kept or preserved by any means whether
                artificial or natural including rugs, skins, specimens of
                animals, antlers, horns, hair, feathers, nails, teeth, musk,
                eggs, nests, other animal products of any description the sale
                and purchase of which is prevented or restricted in any manner
                by applicable laws (including those prohibited under The
                Wildlife Protection Act, 1972 and/ or The Environment Protection
                Act, 1986)
              </p>
            </div>

            <div className="row">
              <p>
                C. Your listing, information, Advertisement Shall not be
                defamatory, trade libelous, unlawfully threatening or unlawfully
                harassing. Further shall not be fraudulent, misrepresenting,
                misleading or pertain to the sale of any illegal, counterfeit,
                stolen goods and or services which do not belong to you or you
                do not have the authority for. Further still shall not infringe
                any intellectual property, trade secret, or other proprietary
                rights or rights of publicity or privacy of any third party.{" "}
                <br />
                Shall not contain any viruses, Trojan horses, worms, time bombs,
                cancel bots, easter eggs or other computer programming routines
                that may damage, detrimentally interfere with, surreptitiously
                intercept or expropriate any system, data or personal
                information.Shall not be allowed to libel anyone or include
                hate, derogatory, slanderous speech directed at individuals or
                groups. You should not advocate violence against other users or
                individuals or groups.
              </p>
            </div>

            <div className="row">
              <p>
                2. In addition to the above and for the purposes of clarity all
                Users shall be expected to adhere to and comply with the
                following Policies while listing of items: <br />
                Restricted Item Policy: In addition to the above prohibited
                items policy users shall also adhere to and comply with the
                restricted items policy while listing, posting or providing
                information in relation to any goods or services. <br />
                Duplicate Ad listings are not allowed. Any ad posted more than
                once with the same content or Title in the same city and
                category would be considered as a Duplicate Ad. We advise you to
                post multiple ads only if you have different items or services
                for sale. All duplicate ads would be deleted and posters
                penalized if the problem persists. <br />
                Mature Audience/Sexually oriented material: Classifieds relating
                to items that includes items intended for use in sexual activity
                would not be permitted. (An example of such classifieds relating
                to an item not normally permitted would be a classified for the
                sale of a vibrator). Please also be aware that titles with
                graphic adult language are inappropriate, regardless of the item
                contained in the listing itself.
              </p>
            </div>

            <div className="row">
              <p>3. Payment :</p>
            </div>

            <div className="row">
              <p>
                You agree to pay per Ad to M/S Bongkonect. <br />
                The exact payment amount may vary time to time and can be
                changed without any notice. <br />
                However; the payment you already made will not effect any
                changes until your ongoing Ad is coming to an end. <br />
                <br />
                Slots : <br />
                You understand that your Paid Ad is not a perpetual contract{" "}
                <br />
                Hence, your Ad will be shown for a predetermined time frame, to
                the audience of our platform. Once the slot is over your Ad will
                be stopped, automatically. From time to time, we need to test
                improvements to our audiences and delivery systems, which could
                impact your advertising. Our testing is designed to improve the
                effectiveness of your advertising performance. We reserve the
                right to test when we believe it will be beneficial for
                advertiser performance. <br />
                We will determine the size, placement and positioning of your
                ads.Scheduling of delivery is subject to availability and may
                not be continuous. <br />
                We do not guarantee the reach or performance that your ads will
                receive, such as the number of people who will see your ads or
                the number of clicks your ads will get. <br />
              </p>
            </div>

            <div className="row">
              <p>(4) Consequences of Breach of Listing Policy :</p>
            </div>

            <div className="row">
              <p>
                Users who violate the prohibited items policy and or the
                restricted items policy may be subject to the following actions{" "}
                <br />
                Suspension or termination of membership. Permanent blocking of
                access to the site. <br />
                Reporting to Law Enforcement or Appropriate Authorities. <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="pagination">
  <a href="#">&laquo;</a>
  <a href="#">1</a>
  <a class="active" href="#">2</a>
  <a href="#">3</a>
  <a href="#">4</a>
  <a href="#">5</a>
  <a href="#">6</a>
  <a href="#">&raquo;</a>
</div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticatedIndividual:
      state.auth.token !== null && state.auth.isAuthenticatedIndividual,
    isAuthenticatedBusiness:
      state.auth.token !== null && state.auth.isAuthenticatedBusiness,
  };
};
export default connect(mapStateToProps)(Video);
