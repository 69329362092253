import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonIcon from "@material-ui/icons/Person";
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Logo from "../../Assets/images/logo-red-bg.svg";

const headersData = [
  {
    label: "Services",
    href: "/",
  },
  {
    label: "About Us",
    href: "/",
  },
  {
    label: "Contact Us",
    href: "/",
  },
];

const useStyles = makeStyles(() => ({
  paper: {
    background: "#ffffff",
  },
  header: {
    backgroundColor: "#ffffff",
    paddingRight: "79px",
    paddingLeft: "118px",
    // boxShadow: "rgba(102, 98, 98, 0.5) 2px 2px 15px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },

  menuButton: {
    fontWeight: 400,
    size: "20px",
    marginLeft: "38px",
    fontSize: "15px",
    fontFamily: "proxima-regular",
    "&:hover": {
      borderBottom: "2px solid #b70000",
      backgroundColor: "transparent",
      color: "#b70000",
    },

    borderRadius: 0,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff",
    backgroundColor: "#ffffff",
  },
  drawerContainer: {
    padding: "20px 30px",
    boxShadow: 0,
    color: "#2f3043",
    backgroundColor: "#ffffff",
    fontFamily: "sans",
  },
}));

export default function Header() {
  const { header, menuButton, toolbar, drawerContainer } = useStyles();
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {/* {femmecubatorLogo} */}
        <div className="flex items-center justify-end w-full">
          {/* {getMenuButtons()} */}
          <button
            onClick={() => history.push("/signup")}
            className="text-darkRed py-1 pl-3 pr-6 font-regular  bg-white rounded-full border-2 border-darkRed hover:bg-darkRed hover:border-darkRed hover:text-white mr-8 text-17"
          >
            {" "}
            <PersonAddIcon className="mr-2 my-px" />
            Join now
          </button>
          <button
            onClick={() => history.push("/login")}
            className="text-white py-1 pl-3 pr-6 font-regular  bg-darkRed rounded-full border-2 border-darkRed hover:bg-white hover:border-darkRed hover:text-darkRed mr-8 text-17"
          >
            {" "}
            <PersonIcon className="mr-2 my-px" />
            Sign In
          </button>
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "#b70000",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          classes={{ paper: classes.paper }}
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {/* {getDrawerChoices()} */}
            <br />
            <button
              onClick={() => history.push("/signup")}
              className="text-darkRed py-1 pl-3 pr-6 font-regular  bg-white rounded-full border-2 border-darkRed hover:bg-darkRed hover:border-darkRed hover:text-white mr-8 text-17"
            >
              {" "}
              <PersonAddIcon className="mr-2 my-px" />
              Join now
            </button>
            <br />
            <br />
            <button
              onClick={() => history.push("/login")}
              className="text-white py-1 pl-3 pr-6 font-regular  bg-darkRed rounded-full border-2 border-darkRed hover:bg-white hover:border-darkRed hover:text-darkRed mr-8 text-17"
            >
              {" "}
              <PersonIcon className="mr-2 my-px" />
              Sign In
            </button>
          </div>
        </Drawer>

        <div className="flex flex-row w-full p-1 items-center justify-between">
          <div className="flex flex-row items-center">
            {/* <img
              src={Logo}
              alt="logo"
              className="h-14 md:h-16 md:p-2 cursor-pointer opacity-80"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/");
              }}
            /> */}
            <div className="ml-2 font-proxima text-28 text-darkRed">
              Bongkonect
            </div>
          </div>
        </div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <div
          {...{
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
          // onClick={() => {
          //   scrollToView(label);
          //   //handleDrawerClose();
          // }}
        >
          <MenuItem>
            {<div className="font-sans color-black">{label}</div>}
          </MenuItem>
        </div>
      );
    });
  };

  const femmecubatorLogo = (
    <div className="flex flex-row items-center">
      <img
        src={Logo}
        alt="logo"
        className="h-14 md:h-16 md:p-2 cursor-pointer opacity-80"
        onClick={() => {
          window.scrollTo(0, 0);
          history.push("/");
        }}
      />
      <div className="ml-2 font-proxima text-28 text-darkRed">Bongkonect</div>
    </div>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: "#b70000",
            // component: RouterLink,
            className: menuButton,
          }}
          // onClick={() => scrollToView(label)}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <header>
      <AppBar className={header} elevation={0}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
