import React from 'react'

function Select({ options, value, title, handleSelectChange }) {
    return (
        <div>
            <select className="browser-default" name="title" value={value ? value : ''} onChange={handleSelectChange} required >
                { <option value="" disabled>{title}</option> }
                {options.map(option =>
                    <option key={option.id} value={(option.phoneCode)? option.id+"_"+option.name+"_+"+option.phoneCode : (option.country_id)? option.id+"_"+option.name : option.name}  >
                        {option.name}
                    </option>
                )}
            </select>
        </div>

    )
}

export default Select
