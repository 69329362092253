import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { logout } from '../../store/actions/index';

const Logout = (props) => {

    useEffect(() => {
        if (props.isAuthenticated)
            props.logout();
    });

    return(
        <div></div>
    )
}

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.auth.token !== null
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => dispatch(logout(ownProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);