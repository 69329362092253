import React, { useEffect } from "react";
import { connect } from "react-redux";
import M from "materialize-css";
import { NavLink, Link } from "react-router-dom";
import "./Nav.css";
import { IMAGE_URL } from "../../axios";

const MyNav = (props) => {
  useEffect(() => {
    M.AutoInit();
  }, []);

  const getNavLinkClass = (path) => {
    return props.location.pathname === path ? "active" : "";
  };

  return (
    <div>
      <ul id="myProfileDropdownMenu" className="dropdown-content dropdown-main">
        <li>
          <Link to="profile">Edit Profile</Link>
        </li>
        <li>
          <Link to="list-business">Business Listing</Link>
        </li>
        <li>
          <Link to="post-profile">Post Profile</Link>
        </li>
        <li>
          <Link to="post-job">Post Job</Link>
        </li>
        <li>
          <Link to="scope">Video Upload</Link>
        </li>
        <li>
          <Link to="scope">Video Ad / Premiere Upload</Link>
        </li>
        <li>
          <Link to="scope">Bangla Music/Movie Premiere Poster Upload</Link>
        </li>
        <li>
          <Link to="profile">My Query</Link>
        </li>
        <li>
          <Link to="/logout">Logout</Link>
        </li>
      </ul>

      <ul id="myProfileDropdownMenuMobile" className="dropdown-content">
        <li className="sidenav-close">
          <Link to="profile">My Profile</Link>
        </li>
        <li className="sidenav-close">
          <Link to="profile">Edit Profile</Link>
        </li>
        <li className="sidenav-close">
          <Link to="list-business">Business Listing</Link>
        </li>
        <li className="sidenav-close">
          <Link to="post-profile">Post Profile</Link>
        </li>
        <li className="sidenav-close">
          <Link to="post-job">Post Job</Link>
        </li>
        <li className="sidenav-close">
          <Link to="profile">My Query</Link>
        </li>
        <li className="sidenav-close">
          <Link to="/logout">Logout</Link>
        </li>
      </ul>

      <div className="navbar-fixed">
        <nav style={{ backgroundColor: "#fe0000" }}>
          <div className="nav-wrapper">
            <NavLink to="/" className="brand-logo logo-container">
              <img
                className="logo-img"
                src={IMAGE_URL + "images/logo-white-bg.png"}
                alt="logo"
              />
            </NavLink>
            <a href="#" data-target="mobile-demo" className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </a>
            <ul
              className="right hide-on-med-and-down"
              style={{ marginRight: "50px" }}
            >
              <li className={getNavLinkClass("/")}>
                <NavLink to="/">
                  <strong style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                    Konect
                  </strong>
                </NavLink>
              </li>
              <li className={getNavLinkClass("/near-me")}>
                <NavLink to="/near-me">
                  <strong style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                    NearMe
                  </strong>
                </NavLink>
              </li>
              <li className={getNavLinkClass("/life-line")}>
                <NavLink to="/life-line">
                  <strong style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                    LifeLine
                  </strong>
                </NavLink>
              </li>
              <li className={getNavLinkClass("/scope")}>
                <NavLink to="/scope">
                  <strong style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                    Scope
                  </strong>
                </NavLink>
              </li>
              <li>
                <a
                  className="dropdown-trigger"
                  href="#!"
                  data-target="myProfileDropdownMenu"
                >
                  <strong style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                    My Profile &nbsp;&nbsp;
                  </strong>
                  <i className="material-icons right">arrow_drop_down</i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <ul className="sidenav" id="mobile-demo">
        <li className={getNavLinkClass("/") + " sidenav-close"}>
          <NavLink to="/">
            <strong style={{ fontWeight: "bold" }}>Konect</strong>
          </NavLink>
        </li>
        <li className={getNavLinkClass("/near-me") + " sidenav-close"}>
          <NavLink to="/near-me">
            <strong style={{ fontWeight: "bold" }}>NearMe</strong>
          </NavLink>
        </li>
        <li className={getNavLinkClass("/life-line") + " sidenav-close"}>
          <NavLink to="/life-line">
            <strong style={{ fontWeight: "bold" }}>LifeLine</strong>
          </NavLink>
        </li>
        <li className={getNavLinkClass("/scope") + " sidenav-close"}>
          <NavLink to="/scope">
            <strong style={{ fontWeight: "bold" }}>Scope</strong>
          </NavLink>
        </li>
        <li>
          <a
            className="dropdown-trigger"
            href="#!"
            data-target="myProfileDropdownMenuMobile"
          >
            <strong style={{ fontWeight: "bold" }}>
              My Profile &nbsp;&nbsp;
            </strong>
            <i className="material-icons right">arrow_drop_down</i>
          </a>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(MyNav);
