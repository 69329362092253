import React, { useState } from 'react';
import MyNav from '../../components/Nav/Nav';
import axios from '../../axios';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom';

const PostJobForm = (props) => {

    const [jobEmail, setJobEmail] = useState("");
    const [jobSkills, setJobSkills] = useState("");
    const [jobLocation, setJobLocation] = useState("");
    const [jobPoster, setJobPoster] = useState(null);
    const [jobTermsChecked, setJobTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [profileColor, setProfileColor] = useState("grey");

    const [imageErrors, setImageErrors] = useState('');
    const [showPayment, setShowPayment] = useState(false);
    const [order, setOrder] = useState({});
    const [job, setJob] = useState({});

    const submitJob = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('poster', jobPoster);
        data.append('email', jobEmail);
        data.append('skills', jobSkills);
        data.append('location', jobLocation.toLowerCase());

        axios.post('postJob', data)
            .then(response => {

                setLoading(false);
                setOrder(response.data.order);
                setJob(response.data.job);
                setShowPayment(true);
            })
            .catch(err => {setLoading(false); setShowPayment(false)});
    }

    const onFileChange = (e) => {

        setImageErrors('');

        if (e.target.files.length > 0) {

            setJobPoster(e.target.files[0]);
            var fileName, fileExtension;

            // FILE NAME AND EXTENSION.
            fileName = e.target.files[0].name;
            fileExtension = fileName.replace(/^.*\./, '');

            // CHECK IF ITS AN IMAGE FILE.
            // TO GET THE IMAGE WIDTH AND HEIGHT, WE'LL USE fileReader().
            if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
                readImageFile(e.target.files[0]);             // GET IMAGE INFO USING fileReader().
            }
            else {
                // IF THE FILE IS NOT AN IMAGE.
                setImageErrors('Please select a valid image file');
                setJobPoster(null);
            }
        }
    }

    function readImageFile(file) {
        var reader = new FileReader(); // CREATE AN NEW INSTANCE.

        reader.onload = function (e) {
            var img = new Image();
            img.src = e.target.result;

            img.onload = function () {
                var w = this.width;
                var h = this.height;

                if (w !== 800 && h !== 1100) {
                    setImageErrors('Please select an image of mentioned dimensions')
                    setJobPoster(null);
                }
            }
        };
        reader.readAsDataURL(file);
    }

    return (
        <div style={{ backgroundColor: '#f5f5f5' }}>
            <MyNav location={props.location} />

            {
                loading ? <div>
                    <div className="progress">
                        <div className="indeterminate"></div>
                    </div>
                </div> : null
            }

            {showPayment && <Redirect to={{pathname: '/payment', state: {order, job, type: 'job'}}} />}

            <div className="container">
                <div className="card">
                    <div className="card-content">
                        <h5>Post Job</h5>
                        <form encType='multipart/form-data' onSubmit={(e) => submitJob(e)}>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="jobemail" type="email" className="validate" value={jobEmail} onChange={(e) => setJobEmail(e.target.value)} required />
                                    <label htmlFor="jobemail">Email</label>
                                    <small className="helper-text">A verification mail will be sent to this Email Id </small>
                                </div>
                                <div className="col s6 file-field input-field">
                                    <div style={{ backgroundColor: '#eeeeee', borderRadius: '20px', padding: '5px 20px 5px 20px' }}>
                                        <i className="material-icons" style={{ display: 'inline-block', verticalAlign: 'middle', color: profileColor }}>photo_library</i>
                                        <span style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px' }}>Job Poster</span>
                                        <input type="file" onChange={(e) => {onFileChange(e); setProfileColor('green')}} required />
                                        {
                                                        jobPoster &&
                                                        <i className="material-icons right" style={{ display: 'inline-block', verticalAlign: 'middle', color: 'green' }}>check</i>
                                                    }
                                    </div>
                                    <br />
                                    <small className="helper-text" style={{ marginLeft: '20px' }}>Select an image having width 800px and height 1100px </small>
                                    <small className="helper-text" style={{ marginLeft: '20px', color: 'red' }}> {imageErrors !== '' ? '* ' + imageErrors : null} </small>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="jobskills" type="text" className="validate" maxLength="50" placeholder="Java, Php ..." value={jobSkills} onChange={(e) => setJobSkills(e.target.value)} required />
                                    <label className="active" htmlFor="jobskills">Skills</label>
                                    <small className="helper-text right">{jobSkills.length + "/50"}</small>
                                </div>
                                <div className="input-field col s6">
                                    <input id="joblocation" type="text" className="validate" value={jobLocation} onChange={(e) => setJobLocation(e.target.value)} required />
                                    <label htmlFor="joblocation">Location</label>
                                </div>
                            </div>

                            <div className="input-field">
                                <p>
                                    <label>
                                        <input type="checkbox" checked={jobTermsChecked} onChange={(e) => setJobTerms(e.target.checked)} required />
                                        <span>By clicking Register, you agree to the <a className="modal-trigger" href="#postJobGuidelinesModal">Terms and Conditions</a> set out by this site.</span>
                                    </label>
                                </p>
                            </div>
                            <button disabled={loading} type="submit" style={{ backgroundColor: '#fe0000' }} className="waves-effect waves-light btn">Submit</button>
                        </form>
                    </div>
                </div>
            </div>


            <div id="postJobGuidelinesModal" className="modal">
                <div className="modal-content">

                    <h4 style={{ textAlign: 'center' }}>Terms and Conditions</h4>
                    <div className="">
                        <div className="row">
                            <p>
                            Subscriber means the customer who is named in the Customer Service Agreement Form and has duly signed it.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Company means M/S Bongkonect.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Services mean access to the resume database and posting of jobs and/or any other product or service offered by the 
                            Company to the Subscriber.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Username mean username provided by the Company to the Subscriber. 
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Password mean the password designated to the specified username.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Third Party mean, any person other the Subscriber who is not party to this arrangement/agreement.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Website/Network means the Company's portal website www.bongkonect.com for providing Services under this agreement.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Material Breach mean violation of any terms & condition of the agreement by the Subscriber.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Tariff Plan refers to the details as mentioned in the rate card for products, services and packages introduced by the 
                            Company from time to time for providing 
                            the Services as a whole or in part for fixed and/or variable charges.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Provision of Services:
                            </p>
                        </div>

                        <div className="row">
                            <p>
                                
                              The Company would not be held liable for any loss of data technical or otherwise, information, 
                              particulars supplied by the Subscriber due to the reasons beyond its control like corruption of data 
                              as a result of any causes or conditions that are beyond the Company's reasonable control including but 
                              not limited to acts of Government, acts of God, Govt. policies, tampering of data by third party like hackers, 
                              terrorism or by viruses, trojan horses, trap doors, back doors, easter eggs, worms, time bombs, cancelbots or 
                              Computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously 
                              intercept or expropriate any system, data or personal information. In no event will the Company be liable for 
                              any such direct/indirect /consequential loss or damages, including loss of profit or loss of reputation/defamation,
                              even if advised of the possibility thereof.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Company reserves its right to reject any insertion or information/data provided by the Subscriber without assigning any 
                            reason whatsoever; either before uploading or after uploading the vacancy details, but in such an eventuality, any amount so 
                            paid only for that particular vacancy, shall be refunded to the Subscriber on a pro-rata basis at the sole discretion of the 
                            Company except when such rejection is in due to inappropriateness of content , violation of any terms and conditions of this 
                            Agreement of usage by the Subscriber.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Company has the right to make all necessary modifications/editing of the vacancy details in order to facilitate uploading. 
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Subscribers Obligation
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            All the creative for the package will be designed by Company; however, all the content (logo, pictures, text, etc.) shall be provided 
                            by the Subscriber.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Subscriber shall by action of signing this agreement issue an implicit & binding warranty to not use/circulate/forward any 
                            candidate(s) resume hosted on the Company's website to the candidate (s) current employer as mentioned by the 
                            person in his/her resume.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The information on the Company's website is for use by its Subscribers alone and does not authorize the Subscriber 
                            sell/distribute/circulate/forward the data and other information available in the website to any other person, Company 
                            and organization for commercial 
                            exploitation at the cost of Company.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Subscriber shall keep in confidence any information received by the Subscriber under this agreement, irrespective of the business 
                            or the matters concerning the other and shall not disclose the same to any third party, save and except to any State or Central 
                            Government or to any of their agencies and/or any other concerned legal and other competent authorities on specific demand or under
                            a general obligation.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Subscriber represents, warrants & assures that the data provided by the Subscriber in terms of this Agreement for 
                            uploading/posting shall not contain any viruses, trojan horses, trap doors, back doors, easter eggs, worms, time bombs, 
                            cancelbots or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously 
                            intercept or expropriate any system, data or personal information in the event of detection of which the Company will reserve the 
                            right to cancel the agreement ab initio and forefeit the consideration exchanged 
                            so for the purpose of this agreement. This will be apart from the right of the Company to take appropriate legal action, if required.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The data provided by the Subscriber shall be deemed to have been voluntarily supplied, non- confidential and the Subscriber
                            hereby discharges the Company of all obligations 
                            of confidentiality
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Subscriber further represents and warrants & assures that, the data provided by the Subscriber for the purpose of 
                            uploading in the Website, shall not be violative of any IPR, rights of privacy,
                             rights of publicity and/or any other rights of third party and shall not be violative of any provision of Law in force.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Subscriber represents, warrants and assures that data provided by the Subscriber for the purpose of uploading on 
                            the Website, shall not be fake or incorrect or inappropriate and that he shall ensure responsible use of the Services.
                            Subscriber further represents and warrants that he shall be solely responsible for the correctness of the data provided by 
                            him and in case of any third party action in this regard, the Subscriber shall indemnify and hold the Company harmless.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            In case by misrepresentation or false postings the Subscriber takes away the data which is the
                            intellectual property of the Company, then in addition to the rights available to the Company herein, the Company shall have the right to initiate appropriate legal action against the Subscriber.
 
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            By action of signing this agreement, the Subscriber agrees to use of information, materials, logos and or data as 
                            supplied by it, in any form or medium, including without limitation the internet and print by the Company for the purpose of this agreement
                            including but not limited to use of such information, material, logos and or data on Company's home page, and its publications.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Termination: The Company may terminate the Services in case the Subscriber:
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Commits any breach of these terms and condition, representation & warranties and Subscribers obligations as contemplated in this agreement.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Fails to make payments as per the terms & condition herein.
    
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Uses the Services provided by the Company for 
                            any illegal, unlawful or immoral purposes or in any fraudulent manner or for purposes not authorized by the Company.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Subscriber shall keep in confidence any information received by the Subscriber under this agreement, irrespective of 
                            the business or the matters concerning the other and shall not disclose the same to any third party, save and except to any State or Central Government or to any of 
                            their agencies and/or any other concerned legal and other competent authorities on specific demand or under a general obligation. 
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Advice received from regulatory or any other competent authorities.
                            Commits violation of any IPR, rights of privacy, rights of publicity and/or any other rights of third 
                            party and shall not be violative of any provision of Law in force.
 
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            General Terms & Condition:
                            </p>
                        </div>

                        <div className="row">
                            <p>
                                
                             These job postings may not be substituted with other job postings during this term without incurring additional
                              charges. Any jobs posted by Subscriber on the website and in excess of the number of jobs provided for in this
                               Agreement will be billed to the Subscriber and shall be payable by the Subscriber in accordance with the terms
                                hereof, at the Company's then prevailing rate for such job postings on the Website.The Company may, in its sole
                                 discretion, impose a interest equal to 18% per month on all overdue accounts. Any re activation of a deleted
                                  or expired job posting and any refreshing of any job posting constitutes use of an additional job posting 
                                  hereunder. Website's resume database (each a "Resume Database") is a private database for use by Subscriber's
                                   only. A Subscriber is defined as one unique user with one unique password provided by the Company. If the
                                    Subscriber (including its employees or consultants) is found to share passwords with any third party,the
                                     Company may revoke all passwords forthwith and no refund will be given.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Subscriber agrees to notify the Company promptly after the departure of any person to whom a password was provided and the 
                             Company shall on such intimation issue a new password to the Subscriber. The Company reserves the right to periodically change
                             issued passwords with prior notice
                             only to identified hierarchy head for security reason. However changed password shall be informed to the Subscriber immediately.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The charges paid by the Subscriber to the Company under this Agreement are non-refundable. 
                            The website (including without limitation all data therein), and all elements, which are a part of the foregoing, 
                            and all intellectual and other proprietary rights therein, are the property of the Company. Neither the Subscriber 
                            nor any of its employees shall do anything, which would in any way damage, injure or impair the validity of the Company's 
                            rights in the contents of the website. Notwithstanding the above, any data placed on the website by the Subscriber herein,
                            and all elements which are a part of the such data, and all intellectual and other proprietary rights 
                            therein, are and shall at all times remain the Subscriber's property.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                                To the extent permitted by law the Company makes no warranties, express or implied, including the 
                                warranties of merchantability, fitness for a particular purpose, or non-infringement with respect to its services 
                                or the website, or results of use thereon and all warranties and conditions, express or implied are hereby excluded.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Subscriber agree to indemnify the Company, its officers, directors, employees and agents, from and against any claims, actions or 
                            demands, including without limitation reasonable legal and accounting fees, arising or resulting from its breach of this Agreement 
                            or breach of representation and warranties as contemplated herein or from its provision of any material to the website, including but 
                            not limited to claims of
                            breach of any third party rights including intellectual property rights or breach of any provision of any law for time being in force.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Notwithstanding anything to the contrary contained herein, except as may arise under the immediately preceding paragraph, 
                            neither party will be liable to the other party (nor to any person claiming rights derived from the other party's rights) for
                             incidental, indirect, consequential, special, punitive or exemplary damages of any kind including lost revenues or profits, 
                             loss of business or loss of data arising out of this agreement (including without limitation as a result of any breach of any
                             warranty, or other term of this agreement), regardless of whether the party liable or allegedly liable was advised, had other 
                             reason to know, or in fact knew of the possibility thereof. Moreover, the Company's maximum liability arising out of or relating
                             to the transaction, which is the subject matter of this agreement, regardless of the cause of action (whether in contract, tort,
                             breach of warranty or otherwise), will not exceed the
                             amount paid by the Subscriber to Bongkonect hereunder minus any necessary service charges or taxation already incurred by the Company.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Not with standing anything to the contrary contained herein, the Subscriber's use of the website is 
                            subject to the Terms of Use/Private Policy/Disclaimer available from such website's homepage. 
                            By Subscriber's execution hereof it hereby agrees to abide by such Terms of Use/Private Policy/Disclaimer, 
                            as they may be amended from time to time.
 

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            This Agreement (i) constitutes the entire Agreement between the parties with respect to the subject matter hereof
                             and supersedes any previous oral or written arrangements or understandings relating thereto; (ii) may be signed in
                              counterparts, (iii) may not be amended, terminated or waived orally, (iv) may not be assigned, in whole or in part, 
                              directly or indirectly, or otherwise, by the Subscriber and only comes into existence when signed by its authorized 
                              signatory and (v) Company shall not be responsible for unauthorized access to data by third parties, or data lost whether
                               or not arising during operation or transmission as a result of server functions, virus, bugs or other causes beyond its control. 
                            The Company will be entitled to assign all or any of its rights and obligations hereunder to any third party.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Any terms of this Agreement that may be invalid shall not affect the validity of enforcement of the remaining valid terms
                             of this Agreement. 
                            The terms and conditions of this Agreement may not be amended without the affirmative written consent of Bongkonect.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Company shall address all billing statements/notices/correspondence under this Agreement to the address given in the page no.
                             1 of this Customer Service Agreement Form. 
                            The Subscriber shall inform the Company in writing of any changes in the address immediately and obtain an acknowledgement to such effect.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Company reserves the right to recover/charge any amounts to the Subscriber on account of any taxes levied by the Central/State Govt. 
                            on the services as contemplated in this agreement from time to time and which are not included in the total 
                            payment consideration received by the Company.The subscriber is mandatorily required to have a GSTIN number in compliance with the law.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Company shall not refund or give a credit note or 
                            charge additional charges to the Subscriber in the event of change in the tariff plans which the Company may introduce from time to time.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Terms of agreement:
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The agreement shall be effective for the period as specified in the Customer Service Agreement Form.
                             This agreement will be extended automatically for a further period of similar duration, unless otherwise 
                             specified or terminated by the Company or by the Subscriber through a written communication to the Company 
                             seeking withdrawal of services on or before atleast 1 week from the date of expiry of the agreement. 
                             The Company will accordingly raise an invoice basis the prices of services 
                            prevailing at the time of extension/renewal of this agreement and all other terms and conditions of the agreement shall remain unchanged.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            TheCompanyshaladdresalbilingstatements/notices/corespondenceunderthisAgrementtothe addresgiveninthepageno.1ofthisCustomerServiceAgrementForm.TheSubscribershalinform theCompanyinwritingofanychangesintheaddresimmediatelyandobtainanacknowledgementto suchefect.
                            </p>
                        </div>

                        <div className="row">
                            <p>

                            Governing Law:
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The Terms & Conditions between the Subscriber & the Company shall be governed by the laws of India and any dispute or
                             differences, if any between the Subscriber & 
                            the Company, shall be subject to the exclusive jurisdictions of the Courts in Bangalore alone.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Anti-Spam POLICY
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The use and access to database is subject to this policy. The services provided to you are aimed at providing recruitment solutions 
                            and should be restricted to contacting suitable candidates for genuine jobs in existence. Mailing practices such as transmitting 
                            marketing and promotional mailers/Offensive messages/messages with misleading subject lines in order to intentionally obfuscate
                            the original message, are strictly prohibited. We reserve the right 
                            to terminate services, without prior notice, to the originator of Spam. No refund shall be admissible under such circumstances.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Following is an illustrative (not exhaustive) list of the kinds of messages which can be classified as spam: 
                            </p>
                        </div>

                        <div className="row">
                            <p><ul>
                                <li>Unsolicited Bulk Messages/Unsolicited Commercial Messages.</li>
                                <li>Non Job related mails.</li>
                                <li>Messages with misleading subject lines. Blank Messages.</li>
                                <li>Extra ordinary High Number of mails. Mails soliciting payments.</li>
                                <li>Misleading/Fraudulent mails.</li>
                                <li>Users agree to indemnify and hold harmless Bongkonect from any damages or claims arising out of usage of their accounts for transmitting spam.</li>
                                <li>Users are advised to change their passwords frequently in order to reduce the possibility of misuse of their accounts.</li>

                            </ul>
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            To seek more information and to report Spam. Please mail us at: abuse@bongkonect.com
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            "Post a Job Package " - Terms & Conditions:
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            This pack will be applicable for a period of 30 days.
                            These terms and conditions are applicable to those who decide to avail the offer 
                            "Post jobs at prepaid rate on Bongkonect.com" a special job posting campaign/offer for the recruiters,
                             offered by BongKonect.com, a job portal of Bongkonect. By signing the following Terms, you will be deemed
                              have accepted the terms and conditions as provided herein. You agree to be bound by these Terms and 
                              Conditions or any subsequent future amendments thereof. Bongkonect reserves the right, in its sole 
                              discretion, to amend or revise these terms and conditions at any point of time, without prior notice 
                              and you agree to be bound by such amendments or revisions. The information provided by you while availing 
                              our services, you consent to the use, transfer and storage of the information so provided by you, on our servers.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                             The recruiter agree that they shall provide complete KYC as per the standard norms laid down by the company within
                              2 working days of purchasing the package i.e GSTIN Certificate, registered company's
                              Pan Card, registered company's certification of incorporation, registered contact person Aadhaar Card
                              (copy of both sides), registered contact person Pan Card. All documents should be self attested. 
                              In case your Company is a proprietorship or partnership Firm, we require company address proof like: 
                              electricity bill, telephone bill (Airtel or BSNL postpaid bills or landline bill), registration by
                               municipality or office registration copy etc. In case you are a freelancer, freelancer's Pan Card and 
                               Aadhar Card (copy of both sides self attested) will be acceptable.
 
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The recruiter acknowledges and agrees that if the document is not provided within 2 working days, the recruiter account will be suspended till the KYC documents are submitted deactivated and
                            This package is non refundable , and hence if the account is suspended or deactivated due to non submission of KYC or violation of the Terms and Use, the amount shall not be refunded. The recruiters agree that Bongkonect reserves 
                            the right to terminate the account post activation of account if the documents are found to be fake and during the course of verification.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The recruiter shall be bound by the terms and conditions available at
                            https://recruiter.Bongkonect.com/termsandconditions and shall be governed by the privacy policy at 
                            https://www.Bongkonect.com/privacypolicy/
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The information provided by you shall be used by us, including but not limited, to: Improve our website and 
                            enable us to provide you the most user-friendly experience which is safe, smooth and customized; Improve and 
                            customize our services, content and other commercial /non - 
                            commercial features on the website; Send you information on our products, services, special deals, promotions;
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Send you service-related announcements on rare occasions when it is necessary to do so; provide you the 
                            opportunity to participate in contests or surveys on our website (If you participate, we may request certain
                             additional personally identifiable information from you. Moreover, participation in these surveys or contests shall be completely 
                            voluntary and you therefore shall have a choice whether or not to disclose such additional information);
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Resolve disputes, if any and troubleshooting; Avoid/check illegal and/or potentially prohibited 
                            activities and to enforce Agreements; Provide service updates and promotional offers related to our services/products. Comply with any court judgment / decree / order / directive / legal & government authority /applicable law; Investigate potential violations or applicable national & international laws;
                            Investigate deliberate damage to the website/services or its legitimate operation; 
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Detect, prevent, or otherwise address security and/or technical issues; Protect the rights, property or safety of Bongkonect and/or its Directors, 
                            employees and the general public at large; and Respond to claims of third parties; To carry out our own analysis and research. 
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Account Protection:
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Your password is the key to your account. You shall be solely responsible for all the activities happening under your username
                             and you shall be solely responsible for keeping your password secure. Do not disclose your password to anyone. 
                             If you share your password or your personal information with others, you shall be solely responsible for all actions taken 
                             under your username and you may lose substantial control over your personal information and may be subject to legally binding 
                             actions taken on your behalf. Therefore, if your password has been compromised for any reason, you should immediately change 
                             
                             your password. Business Transaction In the event Bongkonect goes through a business transition, such as a merger, acquisition 
                             by another company, or sale of all or a portion of its assets, your personally identifiable information will likely be among 
                             the assets transferred. Where your information is transferred you will be notified 
                            via email/prominent notice on our website for 30 days of any such change in ownership or control of your personal information.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            Your password is the key to your account. You shall be solely responsible for all the activities happening under your 
                            username and you shall be solely responsible for keeping your password secure. Do not disclose your password to anyone.
                             If you share your password or your personal information with others, you shall be solely responsible for all actions taken
                              under your username and you may lose substantial control over your personal information and may be subject to legally binding 
                              actions taken on your behalf. Therefore, if your password has been compromised for any reason, you should immediately change your 
                              password. Business Transaction In the event Bongkonect goes through a business transition, such as a merger, acquisition by another 
                              company, or sale of all or a portion of its assets, your personally identifiable information will likely be among the assets transferred.
                               Where your information is transferred you will be 
                            notified via email/prominent notice on our website for 30 days of any such change in ownership or control of your personal information.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            General Terms:
                            </p>
                        </div>

                        <div className="row">
                            <p>
                             Bongkonect reserves the right to disqualify any recruiter if it has reasonable grounds to believe the recruiter has 
                             breached any of these Terms and Conditions.
                             The recruiter further agrees that Bongkonect cannot be made responsible for any damage, loss,
                             injury or disappointment suffered by it as a result of its deciding to avail the services as offered by Bongkonect herein.

                            </p>
                        </div>

                        <div className="row">
                            <p>
                            The recruiter undertakes to indemnify Bongkonect for any 
                            claims or damages arising from Bongkonect's posting of jobs on account of the recruiter following consent of the recruiter.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                            These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any disputes, 
                            differences and, or, any other matters in relation to and arising out of the campaign "Post a job at prepaid rate on 
                            Bongkonect.com" and, or the Terms and Conditions thereof the same shall be referred to arbitration under the Arbitration
                             & Conciliation Act, 1996. The arbitral tribunal shall consist of a sole arbitrator to be appointed by Bongkonect.
                             The venue of arbitration shall be Bangalore and the proceedings of such arbitration shall be in English Language only.
                            </p>
                        </div>

                        <div className="row">
                            <p>
                              All disputes shall be subject to the exclusive jurisdiction of Bangalore Courts only.
                              These Terms & Conditions are the complete and exclusive statements of the understanding between Bongkonect & the recruiter. It supersedes all the understanding or other prior understanding, whether oral & written, and all representation or other communications between Bongkonect & the recruiter.

                            </p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

export default connect(mapStateToProps)(PostJobForm);