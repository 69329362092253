import * as actionTypes from './actionsTypes';
import { fetchPosts} from '../actions/posts';
import axios from '../../axios';

export const postUploadStart = () => {
    return {
        type: actionTypes.POST_UPLOAD_START
    }
}

export const postUploadSuccess = (post) => {

    return {
        type: actionTypes.POST_UPLOAD_SUCCESS,
        payload: { post }
    }
}

export const postUploadFailed = (error) => {
    return {
        type: actionTypes.POST_UPLOAD_FAILED,
        payload: { error }
    }
}

export const uploadPost = (photos, description) => {
    return dispatch => {
        dispatch(postUploadStart())
        const data = new FormData();
        if (photos) {
            for (var x = 0; x < photos.length; x++) {
                data.append('photos', photos[x])
            }
        }
        data.append('description', description);
        axios.post("createPost", data)
            .then(res => {
                dispatch(postUploadSuccess(res.data))
            })
            .then(() => {
                dispatch(fetchPosts())
            })
            .catch(err => { // then print response status
                dispatch(postUploadFailed('Upload Fail'))
            })
    }
}
