import { BANNERSUBMIT, POSTERSUBMIT } from './actionsTypes';

export const bannerSubmit = (bannerId, bannerImage, descriptionText, userImage) => {
    return {
        type: BANNERSUBMIT,
        payload: { bannerId: bannerId, bannerImageSrc: bannerImage, bannerDescription: descriptionText, userImage }
    }
}

export const posterSubmit = (posterId, posterImage, descriptionText, userImage) => {
    return {
        type: POSTERSUBMIT,
        payload: { posterId: posterId, posterImageSrc: posterImage, posterDescription: descriptionText, userImage }
    }
}