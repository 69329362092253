import React, { useState } from "react";
import { Link } from "react-router-dom";
import { login } from "../../store/actions/index";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onEmailChange = (value) => {
    setEmail(value);
    if (value.length > 0 && password.length > 0) setButtonDisabled(false);
    else setButtonDisabled(true);
  };

  const onPasswordChange = (value) => {
    setPassword(value);
    if (value.length > 0 && email.length > 0) setButtonDisabled(false);
    else setButtonDisabled(true);
  };

  const submitForm = (e) => {
    e.preventDefault();
    props.login(email, password);
  };

  return (
    <div
      style={{
        backgroundColor: "#fe0000",
        height: "100vh",
        position: "relative",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Bongkonect - konect your social business and professional community
        </title>
        <meta
          name="description"
          content="Bongkonect - konect your social business and professional community"
        />
        <meta
          name="keywords"
          content="Bangla, Bengali, Bangali, Bangla Culture, Bengali Globally, Biswa Bangla, Bengalee Association, Bengali Connection"
        />
      </Helmet>
      <div
        style={{
          textAlign: "center",
          top: "50%",
          position: "relative",
          margin: "0",
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
        }}
      >
        <div
          className="card "
          style={{
            width: "25rem",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "20px",
          }}
        >
          <div className="card-content">
            <h5 className="card-title">Sign In</h5>
            <br />
            <form onSubmit={(e) => submitForm(e)}>
              <div className="input-field">
                <i
                  className="material-icons prefix"
                  style={{ color: "#fe0000" }}
                >
                  email
                </i>
                <input
                  id="email"
                  type="email"
                  className="validate"
                  value={email}
                  onChange={(e) => onEmailChange(e.target.value)}
                  required
                />
                <label htmlFor="email">Email</label>
              </div>

              <div className="input-field">
                <i
                  className="material-icons prefix"
                  style={{ color: "#fe0000" }}
                >
                  lock
                </i>
                <input
                  id="password"
                  type="password"
                  className="validate"
                  value={password}
                  onChange={(e) => onPasswordChange(e.target.value)}
                  required
                />
                <label htmlFor="password">Password</label>
              </div>

              <br />

              <div style={{ display: "inline-block", width: "100%" }}>
                <Link className="left" to="/forgot">
                  Forgot password ?
                </Link>
                <button
                  type="submit"
                  disabled={buttonDisabled || props.loading}
                  className="btn waves-effect waves-light red right"
                >
                  {props.loading && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  Sign in
                </button>
              </div>
            </form>
            <br />
            <p>
              New to Bongkonect ? <Link to="/signup">Create an account</Link>
            </p>
            <br />
            {props.error === "Payment not completed" ? (
              <p style={{ color: "red" }}>
                {" "}
                * Payment not completed{" "}
                <Link
                  to={{
                    pathname: "/payment",
                    state: { order: props.order, type: "signup" },
                  }}
                >
                  Click here
                </Link>{" "}
                to complete payment.
              </p>
            ) : (
              <p style={{ color: "red" }}>{props.error}</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    loading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,
    order: state.auth.order,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login: (email, password) => dispatch(login(email, password, ownProps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
