import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Helmet } from "react-helmet";
import Header from "../../components/Nav/Header";
import SearchEngineImage from "../../Assets/images/searchEngine.svg";
import LeafRight from "../../Assets/images/leafRight.svg";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Modal } from "@material-ui/core";
import M from "materialize-css";
import FooterHome from "../../components/Footer/FooterHome";

const siteMap = [
  "Home",
  "Login",
  "Signup",
  "User Agreement",
  "Seller Agreement",
  "Privacy Policy",
  "Community Guidelines",
  "Konect",
  "Near Me",
  "LifeLine",
  "Scope",
  "Profile",
  "List Busines",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "#ffffff",
  borderRadius: 10,
  boxShadow: 40,
};

const Home = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    M.AutoInit();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Bongkonect - konect your social business and professional community
        </title>
        <meta
          name="description"
          content="Bongkonect - konect your social business and professional community."
        />
        <meta
          name="keywords"
          content="Bangla, Bengali, Bangali, Bangla Culture, Bengali Globally, Biswa Bangla, Bengalee Association, Bengali Connection, BANGLA SOCIAL MEDIA PLATFORM, BISWA BANGLA, BENGALI CONNECTION, BENGALEE ASSOCIATION"
        />
      </Helmet>
      <Header />
      {/* <NavNotSignedIn /> */}
      <div className="flex flex-col lg:flex-row h-screen w-full bg-white items-center content-center relative">
        {/* {IMAGE_URL+ 'images/logo-white-bg.png'} */}
        {/* <div className="absolute top-4 left-14 flex flex-row items-center w-full">
          <img
            src={Logo}
            className=" lg:h-16 lg:w-16 h-16 w-16"
          />
          <div className="ml-4 font-proxima text-32 text-darkRed">Bongkonect</div>
          
        </div> */}

        <div className="z-10 order-first lg:order-last w-full lg:w-2/4 mt-32 lg:mt-0 lg:h-full p-4 flex  flex-col justify-center items-center">
          <motion.img
            ref={ref}
            initial={{ x: 50, opacity: 0, scale: 0.7 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            transition={{ ease: "easeIn", duration: 0.7 }}
            // src={IMAGE_URL + "images/bongkonect_circle.png"}
            src={SearchEngineImage}
            className="lg:h-2/3 w-full"
          />
        </div>
        <div className="z-10 mt-10 lg:mt-0 text-32 px-10 lg:text-50 lg:w-2/4 lg:px-20 text-darkRed font-proxima">
          <motion.div
            ref={ref}
            initial={{ x: -50, opacity: 0, scale: 0.8 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            transition={{ ease: "easeIn", duration: 0.7 }}
            className="hidden lg:block"
          >
            {/* Bangla Global Local Networking & Search Engine */}
            Bongkonect
          </motion.div>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ ease: "easeIn", duration: 0.7 }}
            className="font-sans text-black text-22 lg:text-3xl text-center lg:text-left mt-2 lg:mr-28"
          >
            Welcome to the social, business and professional community
          </motion.div>
          {/* <Link to="/near-me">
            <strong x> Explore </strong>
          </Link> */}
          {/* <motion.button
            ref={ref}
            initial={{ y: 50, opacity: 0, scale: 0.8 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            transition={{ ease: "easeIn", duration: 0.7 }}
            className='pl-8 mt-10 pr-5 py-3 lg:py-4 mb-20 bg-darkRed rounded-full text-white font-regular text-18 lg:text-18 shadow-xl border-2 border-white hover:border-darkRed'>
            Get Started
            <span className='transform rotate-90'>
              <motion.path
                ref={ref}
                initial={{ x: -10, opacity: 1, scale: 1 }}
                animate={{ x: 0, opacity: 1, scale: 1 }}
                transition={{
                  type: "spring",
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "loop",
                  repeatDelay: 0.5,
                  duration: 0.2,
                }}>
                <ArrowForwardIosIcon className='ml-10 float-right' />
              </motion.path>
            </span>
          </motion.button> */}
        </div>
        <img
          src={LeafRight}
          alt=""
          className="absolute top-36 opacity-70 right-0 w-3/4 hidden md:block z-0"
        />
        <FooterHome />
      </div>
      {/* <div className="flex flex-col lg:h-screen w-full bg-bgWhite items-center relative">
        <img
          src={LeafLeft}
          alt="leafleft"
          className="absolute left-0 top-32 w-3/4 hidden md:block"
        />
         <div className="mt-10 lg:mt-20 text-32 px-5 lg:text-6xl lg:w-2/4 lg:px-10 text-center text-darkRed font-proxima">
          Our Services
        </div>
        <div className="w-full grid gride-cols-1 lg:grid-cols-3 px-8 lg:px-32 gap-10 lg:gap-20 my-20">
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.5 }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 1, y: 40 },
            }}
          >
            <ServicesCard title="Near Me" subText="" imgSrc={NearMe} />
          </motion.div>
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.25 }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 1, y: 40 },
            }}
          >
            <ServicesCard title="Life Line" subText="" imgSrc={LifeLine} />
          </motion.div>
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.5 }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 1, y: 40 },
            }}
          >
            <ServicesCard title="Scope" subText="" imgSrc={Scope} />
          </motion.div>
        </div>
        <div></div>
      </div> */}
      {/* <div className="row">
        <div className="col s0 m6 l6 xl6 hide-on-small-and-down">
          <img className="left" src={IMAGE_URL + "images/job_listing.png"} />
        </div>
        <div className="col s12 m6 l6 xl6">
          {isMobile ? null : (
            <div>
              <br />
              <br />
            </div>
          )}
          <p>Bangla Job Listing</p>
          <Link to="/life-line">
            <strong>Explore</strong>
          </Link>
          {isMobile ? (
            <div>
              <br />
            </div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col s12 m6 l6 xl6">
          {isMobile ? null : (
            <div>
              <br />
              <br />
            </div>
          )}
          <p>Bangla Entertainment</p>
          <Link to="/scope">
            <strong>Explore</strong>
          </Link>
          {isMobile ? (
            <div>
              <br />
            </div>
          ) : null}
        </div>
        <div className="col s0 m6 l6 xl6 hide-on-small-and-down">
          <img
            className="left"
            src={IMAGE_URL + "images/business_listing.jpg"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col s12 m6 l6 xl6">
          <Player playsInline src={IMAGE_URL + "videos/About_Us.mp4"}>
            <BigPlayButton position="center" />
          </Player>
        </div>
        <div className="col s0 m6 l6 xl6 hide-on-small-and-down">
          {isMobile ? null : (
            <div>
              <br />
              <br />
            </div>
          )}
          <p>Connecting Bangla to Bangali</p>
        </div>
      </div>
      <br />
      <br /> */}
      {/* <Footer /> */}

      {/*<div className="w-full py-14 border-t-1 border-coolGray-100 px-12 bg-white opacity-90 text-black grid lg:grid-cols-3 lg:justify-items-center gap-10">
         <div>
          <div className="text-18 font-regular text-black cursor-pointer hover:text-darkRed">Contact Us</div>
          <div className="text-18 font-regular text-black mt-3 cursor-pointer hover:text-darkRed">About US</div>
          <div className="flex flex-row gap-5 mt-6 text-black">
            <a href="/" target="_blank" without rel="noreferrer">
              <FacebookIcon className="transform scale-150 text-black hover:text-darkRed" />
            </a>
            <a href="/" target="_blank" without rel="noreferrer">
              <TwitterIcon className="transform scale-150 text-black hover:text-darkRed" />
            </a>
            <a href="/" target="_blank" without rel="noreferrer">
              <YouTubeIcon className="transform scale-150 text-black hover:text-darkRed" />
            </a>
            <a href="/" target="_blank" without rel="noreferrer">
              <InstagramIcon className="transform scale-150 text-black hover:text-darkRed" />
            </a>
            <a href="/" target="_blank" without rel="noreferrer">
              <TelegramIcon className="transform scale-150 text-black hover:text-darkRed" />
            </a>
          </div>
        </div>
        <div>
          <div className="text-20 font-nexabold text-darkRed">Our Services</div>
          <div className="font-regular text-16 flex flex-col gap-3">
            <div className="hover:text-darkRed cursor-pointer">
              <span>◉</span> Near me
            </div>
            <div className=" hover:text-darkRed cursor-pointer">
              <span>◉</span> Life Line
            </div>
            <div className="hover:text-darkRed cursor-pointer">
              <span>◉</span> Scope
            </div>
          </div>
        </div>
        <div>
          <div className="text-17 font-regular text-black cursor-pointer hover:text-darkRed">User Agreement</div>
          <div className="text-17 font-regular text-black mt-3 cursor-pointer hover:text-darkRed">
            Seller Agreement
          </div>
          <div className="text-17 font-regular text-black mt-3 cursor-pointer hover:text-darkRed">
            Privacy Policy
          </div>
          <div className="text-17 font-regular text-black mt-3 cursor-pointer hover:text-darkRed">
            Community Guidelines
          </div>
        </div>
      </div> */}

      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="lg:w-1/2 w-11/12 lg:m-0">
          <div className="relative p-7">
            <button
              className="absolute top-0 right-0 p-2"
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
            <div className="text-center text-3xl font-proxima text-darkRed pb-4">
              Site Map
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 text-center items-center justify-center content-center">
              {siteMap.map((item, idx) => (
                <div className="bg-darkRed rounded-xl shadow-md p-3 text-white font-sans">
                  {item}
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Home;
