import React, { useEffect } from "react";
import M from "materialize-css";
import { connect } from "react-redux";
import { deletePost } from "../../store/actions";
import Avatar from "react-avatar";
import { IMAGE_URL } from "../../axios";

const ListingCard = (props) => {
  const profilePic =
    props.business.user.profilePic && props.business.user.profilePic !== ""
      ? IMAGE_URL + props.business.user.profilePic
      : null;
  useEffect(() => {
    M.AutoInit();
  }, []);

  return (
    <div>
      <div className="card" style={{ width: "100%" }}>
        <div className="card-content">
          <div className="row">
            <div className="col s8">
              <h5>{props.business._id.businessName}</h5>
              <h6>{props.business.user.about}</h6>
              <br />

              <div className="valign-wrapper">
                <i
                  className="small material-icons"
                  style={{ fontSize: "1.5rem" }}
                >
                  call
                </i>
                &nbsp; &nbsp;
                <span>
                  {props.business.posts[0].countryCode}{" "}
                  {props.business.posts[0].mobile}
                </span>
              </div>

              <div className="valign-wrapper" style={{ marginTop: "10px" }}>
                <i className="material-icons" style={{ fontSize: "1.5rem" }}>
                  directions
                </i>
                &nbsp; &nbsp;
                <span>
                  {props.business.posts[0].city +
                    " | " +
                    props.business.posts[0].country}
                </span>
              </div>

              <div className="valign-wrapper" style={{ marginTop: "10px" }}>
                <i className="material-icons" style={{ fontSize: "1.5rem" }}>
                  email
                </i>
                &nbsp; &nbsp;
                <span>{props.business.user.email}</span>
              </div>
            </div>
            <div className="col s4">
              <br />
              <br />
              <Avatar
                className="right"
                name={
                  props.business.user.firstName +
                  " " +
                  props.business.user.lastName
                }
                round
                src={profilePic}
                size="100px"
                style={{ top: "50%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    loading: state.posts.loading,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    deletePost: (postId) => dispatch(deletePost(postId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingCard);
