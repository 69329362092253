import React from "react";
import MyNav from "../../components/Nav/Nav";
import CandidateProfile from "../../components/CandidateProfile/CandidateProfile";
import axios from "../../axios";
import "react-toastify/dist/ReactToastify.css";
import ContentLoader from "react-content-loader";
import queryString from "query-string";
import debounce from "lodash.debounce";
import NavNotSignedIn from "../../components/NavNotSignedIn/NavNotSignedIn";
import { connect } from "react-redux";

class SearchProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMore: true,
      isLoading: false,
      jobProfiles: [],
      page: 0,
      noProfiles: false,

      searchText: "",
      location: "",
    };

    // Binds our scroll event handler
    window.onscroll = debounce(() => {
      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (this.state.isLoading || !this.state.hasMore) return;

      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        this.searchProfile();
      }
    }, 100);
  }

  componentDidMount() {
    var parsed = queryString.parse(this.props.location.search);
    const searchText = parsed.query;
    const location = parsed.location;

    this.setState({ searchText: searchText, location: location }, () => {
      this.searchProfile();
    });
  }

  search = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, jobProfiles: [], page: 0 }, () => {
      this.searchProfile();
    });
  };

  searchProfile = () => {
    this.setState({ isLoading: true }, () => {
      let data = {
        searchText: this.state.searchText,
        location: this.state.location,
        page: this.state.page + 1,
      };

      console.log(data);
      axios
        .post("searchProfile", data)
        .then((response) => {
          console.log(response.data);
          this.setState({
            isLoading: false,
            hasMore: response.data.profiles.length > 0,
            noProfiles: response.data.profiles.length === 0,
            page: response.data.currentPage,
            jobProfiles: [...this.state.jobProfiles, ...response.data.profiles],
          });
        })
        .catch((err) => this.setState({ isLoading: false }));
    });
  };

  render() {
    const MyLoader = () => (
      <ContentLoader
        height={400}
        width={320}
        speed={2}
        primaryColor="#dfdcdc"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="320" height="400" />
      </ContentLoader>
    );

    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        {this.props.isAuthenticatedIndividual ||
        this.props.isAuthenticatedBusiness ? (
          <MyNav location={this.props.location} />
        ) : (
          <NavNotSignedIn location={this.props.location} />
        )}

        {this.state.isLoading ? (
          <div>
            <div className="progress">
              <div className="indeterminate"></div>
            </div>
          </div>
        ) : null}

        <br />
        <div className="container">
          <form className="row" onSubmit={(e) => this.search(e)}>
            <div className="col m2 l2 xl2 hide-on-small-only"></div>

            <div className="col s3" style={{ marginRight: "22px" }}>
              <i
                className="material-icons"
                style={{ position: "absolute", padding: "10px", color: "grey" }}
              >
                search
              </i>
              <input
                type="search"
                placeholder="Search Profile..."
                value={this.state.searchText}
                onChange={(e) => this.setState({ searchText: e.target.value })}
                style={{
                  backgroundColor: "white",
                  padding: "0px 20px 0px 40px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
              />
            </div>

            <div className="col s3" style={{ marginLeft: "20px" }}>
              <i
                className="material-icons"
                style={{ position: "absolute", padding: "10px", color: "grey" }}
              >
                location_on
              </i>
              <input
                type="search"
                placeholder="Location..."
                value={this.state.location}
                onChange={(e) => this.setState({ location: e.target.value })}
                style={{
                  backgroundColor: "white",
                  padding: "0px 20px 0px 40px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
              />
            </div>

            <div className="col s2">
              <button
                type="submit"
                className="btn"
                style={{
                  height: "45px",
                  marginLeft: "45px",
                  backgroundColor: "#fe0000",
                }}
              >
                <i
                  className="material-icons"
                  style={{ fontSize: "2em", paddingTop: "5px" }}
                >
                  search
                </i>
              </button>
            </div>

            <div className="col m2 l2 xl2 hide-on-small-only"></div>
          </form>
        </div>

        {this.state.noProfiles && this.state.jobProfiles.length === 0 ? (
          <div className="container">
            <div className="card">
              <div className="card-content">
                <h5>No Profiles Found.</h5>
              </div>
            </div>
          </div>
        ) : null}

        <div className="container">
          {this.state.isLoading && (
            <div className="row">
              <div className="col s12 m3 l3 xl3">
                <MyLoader />
              </div>
              <div className="col s12 m3 l3 xl3">
                <MyLoader />
              </div>
              <div className="col s12 m3 l3 xl3">
                <MyLoader />
              </div>
              <div className="col s12 m3 l3 xl3">
                <MyLoader />
              </div>
            </div>
          )}
        </div>

        <div className="container">
          <div className="row">
            {this.state.jobProfiles.map((profile, index) => {
              return (
                <div className="col s12 m4 l4 xl4" key={profile._id}>
                  <CandidateProfile profile={profile} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticatedIndividual:
      state.auth.token !== null && state.auth.isAuthenticatedIndividual,
    isAuthenticatedBusiness:
      state.auth.token !== null && state.auth.isAuthenticatedBusiness,
  };
};

export default connect(mapStateToProps)(SearchProfile);
