import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";
import "./ResetPassword.css";
import axios from "../../axios";

const ResetPassword = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isTokenValid, setTokenValid] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();

    if (password !== confirmPassword)
      setError("Password and Confirm Password didn't matched");
    else {
      setButtonDisabled(true);
      setError("");
      axios
        .put("updatePasswordViaEmail", { userId, password })
        .then((response) => {
          console.log(response.data);
          setButtonDisabled(false);
          if (response.data === "Password updated") {
            M.toast({ html: "Password updated successfully" });
          } else {
            setError(response.data);
          }
        })
        .catch((error) => {
          setButtonDisabled(false);
          console.log(error.data);
        });
    }
  };

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    M.AutoInit();

    axios
      .get("reset", {
        params: {
          resetPasswordToken: props.match.params.token,
        },
      })
      .then((response) => {
        if (response.data.message === "Password reset link is ok") {
          setUserId(response.data.userId);
          setTokenValid(true);
        } else {
          setTokenValid(false);
          // M.toast({ html: 'Password reset link is invalid or has expired' })
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const onPasswordChange = (value) => {
    setPassword(value);
    if (value.length > 0) setButtonDisabled(false);
    else setButtonDisabled(true);
  };

  return (
    <div
      style={{
        backgroundColor: "#fe0000",
        height: "100vh",
        position: "relative",
      }}
    >
      <div
        style={{
          textAlign: "center",
          top: "50%",
          position: "relative",
          margin: "0",
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
        }}
      >
        <div
          className="card "
          style={{
            width: "25rem",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "20px",
          }}
        >
          <div className="card-content">
            <h5 className="card-title">Reset password </h5>
            <br />
            <form onSubmit={(e) => resetPassword(e)}>
              <div className="input-field">
                <i className="material-icons prefix" style={{ color: "red" }}>
                  lock
                </i>
                <input
                  id="password"
                  type="password"
                  className="validate"
                  value={password}
                  onChange={(e) => onPasswordChange(e.target.value)}
                  required
                />
                <label htmlFor="password">New Password</label>
              </div>

              <div className="input-field">
                <i className="material-icons prefix" style={{ color: "red" }}>
                  lock
                </i>
                <input
                  id="password"
                  type="password"
                  className="validate"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <label htmlFor="password">Confirm Password</label>
              </div>

              <br />

              <div className="container">
                <div className="row">
                  <div className="col s12">
                    <button
                      type="submit"
                      disabled={buttonDisabled || !isTokenValid}
                      className="btn waves-effect waves-light red"
                    >
                      {buttonDisabled && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <br />
            <Link to="/login">Go back to Login</Link>
            <br />
            {error ? <p style={{ color: "red" }}> * {error}</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
