import React from 'react';

const Banner = (props) => {

    return(
        <div>
            {console.log(props.match.params)}
        </div>
    )
}

export default Banner;