import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bannerSubmit } from "../../store/actions/index";
import M from "materialize-css";
import "./Modal.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IMAGE_URL } from "../../axios";
import { isMobile } from "react-device-detect";

const ImageModal = (props) => {
  var banner_width = isMobile ? "350px" : "60rem";

  var customStyles = {
    width: banner_width,
    position: "absolute",
    zIndex: "5",
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-30%",
    transform: "translate(-50%, -50%)",
    maxHeight: "520px", // <-- This sets the height
    overlfow: "scroll", // <-- This tells the modal to scrol
  };

  const [descriptionText, setDescriptionText] = useState(
    props.description ? props.description : ""
  );
  const [bannerImage, setBannerImage] = useState(
    props.banner ? props.banner : IMAGE_URL + props.banners[0].banner
  );
  const [bannerColor, setBannerColor] = useState("#fff");
  const [bannerImageId, setBannerImageId] = useState(props.banners[0]._id);
  const [postButtonDisabled, setPostButtonDisabled] = useState(true);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userBannerSelected, setUserBannerSelected] = useState(false);
  const descriptionRefs = useRef();
  const bannerRefs = useRef();

  useEffect(() => {
    M.AutoInit();
    var elems = document.querySelectorAll(".modal");
    // var instances = M.Modal.init(elems, {
    //   dismissible: true
    // });
  }, []);

  const changeBannerImage = (src, id = "", textColor) => {
    setBannerImage(src);
    setBannerImageId(id);
    setBannerColor(textColor);
  };

  const onBannerFileUpload = (event) => {
    var file = event.target.files[0];
    setUserBannerSelected(false);
    if (checkMimeType(event)) {
      // if return true allow to setState

      var reader = new FileReader(); // CREATE AN NEW INSTANCE.

      reader.onload = function (e) {
        var img = new Image();
        img.src = e.target.result;

        img.onload = function () {
          var w = this.width;
          var h = this.height;

          if (w === 1920 && h === 200) {
            setUserPhoto(file);
            setUserBannerSelected(true);
            setBannerImage(URL.createObjectURL(file));
          } else {
            alert("Please upload banner of mentioned dimensions");
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload image");
    }
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = [];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/gif"];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err[x] = files[x].type + " is not a supported format\n";
      }
    }
    for (let z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };

  return (
    <React.Fragment>
      <div id="modal1" className="modal">
        <div className="modal-content">
          <h6>Select Banner</h6>
          <br />
          <div className="row" style={{ backgroundColor: "white" }}>
            {props.banners.map((photo) => {
              return (
                <div
                  className="col modal-close"
                  key={photo._id}
                  style={{ marginBottom: "10px", cursor: "pointer" }}
                >
                  <img
                    src={IMAGE_URL + photo.banner}
                    height="70"
                    width="70"
                    onClick={(e) =>
                      changeBannerImage(
                        e.target.src,
                        photo._id,
                        photo.textColor
                      )
                    }
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: "100",
          left: "0",
          top: "0",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="card" style={customStyles}>
          <div className="row" style={{ padding: "10px" }}>
            <div className="col">
              <span className="card-title">Create Banner</span>
            </div>
            <div className="col right">
              <i
                className="material-icons"
                style={{ cursor: "pointer" }}
                onClick={props.closeModal}
              >
                close
              </i>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              textAlign: "center",
              color: "white",
            }}
          >
            <img
              src={bannerImage}
              ref={bannerRefs}
              className="card-image"
              style={{ width: "100%", height: "300px" }}
              alt=""
            />
            <div
              style={{
                position: "absolute",
                width: "100%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <textarea
                value={descriptionText}
                ref={descriptionRefs}
                maxLength="100"
                placeholder="Write something here..."
                onChange={(e) => {
                  setDescriptionText(e.target.value);
                  e.target.value.length > 0
                    ? setPostButtonDisabled(false)
                    : setPostButtonDisabled(true);
                }}
                style={{
                  color: bannerColor,
                  borderStyle: "none",
                  outline: "none",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "bold",
                }}
              />
            </div>
          </div>

          <div className="card-action">
            <a
              className="waves-effect waves-light btn modal-trigger red"
              href="#modal1"
            >
              View Banners
            </a>

            <div
              className="file-field input-field right"
              style={{ margin: "0px" }}
            >
              <div
                style={{
                  backgroundColor: "#eeeeee",
                  borderRadius: "20px",
                  padding: "5px 20px 5px 20px",
                }}
              >
                <i
                  className="material-icons"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    color: "red",
                  }}
                >
                  photo_library
                </i>
                <span
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                  }}
                >
                  Upload your own
                </span>
                <input type="file" onChange={(e) => onBannerFileUpload(e)} />
              </div>
            </div>

            <br />
            <br />
            <button
              className="btn"
              disabled={postButtonDisabled && !userBannerSelected}
              onClick={() => {
                userPhoto
                  ? props.submitBanner("", "", descriptionText, userPhoto)
                  : props.submitBanner(
                      bannerImageId,
                      bannerImage,
                      descriptionText,
                      null
                    );
                props.closeModal();
              }}
              style={{ width: "100%" }}
            >
              Post
            </button>
            <br />
            <br />
            <span>* Upload banner of size 1920 x 200 pixels</span>
          </div>
        </div>
      </div>
      <ToastContainer style={{ marginBottom: "0px", marginTop: "10px" }} />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitBanner: (bannerId, bannerImage, descriptionText, userPhoto) =>
      dispatch(bannerSubmit(bannerId, bannerImage, descriptionText, userPhoto)),
  };
};

export default connect(null, mapDispatchToProps)(ImageModal);
