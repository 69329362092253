import React, { useState, useEffect, useRef } from "react";
import { IMAGE_URL } from "../../axios";
import M from "materialize-css";
import axios from "../../axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ImageCrop from "../ImageCrop/ImageCrop";

const CandidateProfile = (props) => {
  const [email, setEmail] = useState(props.profile.email);
  const [firstName, setFirstName] = useState(props.profile.firstName);
  const [lastName, setLastName] = useState(props.profile.lastName);
  const [skills, setSkills] = useState(props.profile.skills);
  const [experience, setExperience] = useState(props.profile.experience);
  const [location, setLocation] = useState(props.profile.location);
  const [maritalStatus, setMaritalStatus] = useState(
    props.profile.maritalStatus
  );
  const [emailVerified, setEmailVerified] = useState(
    props.profile.emailVerified
  );

  const [showCrop, setShowCrop] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(IMAGE_URL + props.profile.photo);

  const profilePicChooser = useRef();

  const onFileChanged = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setShowCrop(true);
    }
  };

  useEffect(() => {
    M.AutoInit();
  }, []);

  useEffect(() => {
    if (profilePic) setPreview(window.URL.createObjectURL(profilePic));
  }, [profilePic]);

  const completeProfilePayment = () => {
    const myData = {
      type: "profile",
      userId: props.user.userId,
      serviceId: props.profile._id,
      buyer_name: props.user.firstName + " " + props.user.lastName,
      email: props.user.email,
      phone: props.user.mobile,
    };

    return axios
      .post("payment", myData)
      .then((myresponse) => {
        const paymentLink = myresponse.data.payment_request.longurl;
        window.location.replace(paymentLink);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitEditProfile = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("profileId", props.profile._id);
    data.append("photo", profilePic);
    data.append("email", email);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("skills", skills);
    data.append("experience", experience);
    data.append("location", location.toLowerCase());
    data.append("maritalStatus", maritalStatus);

    axios
      .post("updateJobProfile", data)
      .then((response) => {
        setEmail(response.data.profile.email);
        setFirstName(response.data.profile.firstName);
        setLastName(response.data.profile.lastName);
        setSkills(response.data.profile.skills);
        setExperience(response.data.profile.experience);
        setLocation(response.data.profile.location);
        setMaritalStatus(response.data.profile.maritalStatus);
        setEmailVerified(response.data.profile.emailVerified);

        toast.success("Profile updated successfully");
        props?.refresh(new Date().getTime());
      })
      .catch((err) => console.log(err));
  };

  let emailVerifiedHtml = null;
  if (props.edit) {
    if (emailVerified)
      emailVerifiedHtml = <li style={{ color: "green" }}>Email verified</li>;
    else
      emailVerifiedHtml = <li style={{ color: "red" }}>Email not verified</li>;
  }

  let paymentCompletedHtml = null;
  if (props.edit) {
    if (props.profile.paymentCompleted)
      paymentCompletedHtml = <li style={{ color: "green" }}>Payment done</li>;
    else {
      const link = `/payment?type=profile&profileId=${props.profile._id}`;
      paymentCompletedHtml = (
        <li style={{ color: "red" }}>Payment not completed</li>
      );
    }
  }

  return (
    <div>
      <ImageCrop
        file={file}
        open={showCrop}
        setOpen={setShowCrop}
        updateFile={setProfilePic}
      />

      <div id="editProfileModal" className="modal">
        <div className="modal-content">
          <h5>Edit Profile</h5>
          <br />
          <form onSubmit={(e) => submitEditProfile(e)}>
            <div
              className="card-image"
              onClick={() => profilePicChooser.current.click()}
              style={{ cursor: "pointer" }}
            >
              <label className="active my-2" htmlFor="profile">
                Profile Pic
              </label>
              <img
                id="profile"
                className="responsive-img"
                src={preview}
                style={{ height: "230px", width: "230px", objectFit: "cover" }}
              />
              <input
                type="file"
                style={{ display: "none" }}
                ref={profilePicChooser}
                onChange={(e) => onFileChanged(e)}
              />
            </div>

            <div className="row mt-6">
              <div className="input-field col s6">
                <input
                  id="email"
                  type="email"
                  className="validate"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label className="active" htmlFor="email">
                  Email
                </label>
                <small className="helper-text">
                  A verification mail will be sent to this Email Id{" "}
                </small>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6">
                <input
                  id="firstName"
                  type="text"
                  className="validate"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <label className="active" htmlFor="firstName">
                  First Name
                </label>
              </div>
              <div className="input-field col s6">
                <input
                  id="lastName"
                  type="text"
                  className="validate"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <label className="active" htmlFor="lastName">
                  Last Name
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6">
                <input
                  id="skills"
                  type="text"
                  className="validate"
                  maxLength="20"
                  placeholder="Java, Php ..."
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  required
                />
                <label className="active" className="active" htmlFor="skills">
                  Skills
                </label>
                <small className="helper-text right">
                  {skills.length + "/20"}
                </small>
              </div>
              <div className="input-field col s6">
                <select
                  className="browser-default"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                >
                  <option value="" disabled>
                    Experience
                  </option>
                  {new Array(31).fill().map((number, i) => {
                    return (
                      <option value={i + "yrs"} key={i}>
                        {i}yrs
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6">
                <input
                  id="location"
                  type="text"
                  className="validate"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required
                />
                <label className="active" htmlFor="location">
                  Location
                </label>
              </div>
              <div className="input-field col s6">
                <select
                  value={maritalStatus}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                >
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                </select>
                <label>Marital Status</label>
              </div>
            </div>

            <button
              type="submit"
              className="modal-close waves-effect waves-light btn"
            >
              Update
            </button>
          </form>
        </div>
      </div>

      <div className="card">
        <div className="card-image">
          <img
            className="responsive-img"
            src={IMAGE_URL + props.profile.photo}
            style={{ height: "230px", objectFit: "cover" }}
          />
        </div>
        <ul style={{ marginLeft: "10px" }}>
          <li style={{ overflowWrap: "break-word" }}>
            <h6 style={{ fontWeight: "bold" }}>{lastName + " " + firstName}</h6>
          </li>
          <li style={{ overflowWrap: "break-word" }}>
            {location.charAt(0).toUpperCase() +
              location.slice(1) +
              " | " +
              maritalStatus}
          </li>
          <li style={{ overflowWrap: "break-word" }}>
            {skills.replace(",", " |") + " | " + experience}{" "}
          </li>
          <li style={{ overflowWrap: "break-word" }}>{email}</li>
          {emailVerifiedHtml}
          {paymentCompletedHtml}
          <br />
          <li>
            {props.edit && (
              <a className="modal-trigger" href="#editProfileModal">
                Edit
              </a>
            )}
          </li>
          <br />
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(CandidateProfile);
