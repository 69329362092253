import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_URL } from '../../axios';
import axios from '../../axios';
import './Carousel.css';

const Carousel = (props) => {

    const [banners, setBanners] = useState([]);

    useEffect(() => { 
    axios.get('getBannerBusiness')
    .then(response => { setBanners(response.data.posts) })
    .catch(err => console.log(err));
    }, []);

    const settings = {
        adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        arrows: false,
        autoplaySpeed: 3000
    };   

    return (
        <div>
            <Slider {...settings}>

                {
                  banners.map((banner, index) => {
                        return (
                            banner.banner?  
                            <div className="image" key={'carousel-' + new Date().getTime()}>
                                <img src={IMAGE_URL +  banner.banner.banner} style={{ height: '200px', width: '100%' }} alt="" />
                                <div className="mycontent" style={{width: '80%'}}>
                                    <h2 style={{ color: banner.banner.textColor, overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}>{banner.description}</h2>
                                    <h5 style={{ color: banner.banner.textColor, overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}>{banner.city + " | " + banner.country + " | " + banner.mobile}</h5>
                                </div>
                            </div>
                            :
                            <div className="image" key={'carousel-' + new Date().getTime()}>
                                <img src={IMAGE_URL + banner.userImage} style={{ height: '200px', width: '100%' }} alt="" />
                            </div>
                        )
                    }) 
                    
                    
                }

            </Slider>
        </div>
    )
    
}

export default Carousel;