import React, { useEffect } from "react";
import M from "materialize-css";
import { connect } from "react-redux";
import "./Poster.css";
import { deletePost } from "../../store/actions";
import { IMAGE_URL } from "../../axios";

const Poster = (props) => {
  useEffect(() => {
    M.AutoInit();
  }, []);

  return props.poster.poster ? (
    <div>
      <div
        className={"card " + props.float}
        style={{ width: "300px", height: "450px" }}
      >
        <div className="card-image">
          <img
            src={IMAGE_URL + props.poster.poster.poster}
            alt=""
            style={{ width: "300px", height: "450px" }}
          />
        </div>
        <div className="postercontenttop" style={{ width: "300px" }}>
          <p
            style={{
              color: props.poster.poster.textColor,
              fontSize: "1.8em",
              fontWeight: "bold",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              hyphens: "auto",
            }}
          >
            {props.poster.description}
          </p>
          <p
            style={{
              color: props.poster.poster.textColor,
              fontSize: "1.5em",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              hyphens: "auto",
            }}
          >
            {props.poster.city + " | " + props.poster.country}
          </p>
          <p
            style={{
              color: props.poster.poster.textColor,
              fontSize: "1.5em",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              hyphens: "auto",
            }}
          >
            {props.poster.mobile}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div
        className={"card " + props.float}
        style={{ width: "300px", height: "450px" }}
      >
        <div className="card-image">
          <img
            src={IMAGE_URL + props.poster.userImage}
            alt=""
            style={{ width: "300px", height: "450px" }}
          />
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    loading: state.posts.loading,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    deletePost: (postId) => dispatch(deletePost(postId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Poster);
