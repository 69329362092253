import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";

const Footer = (props) => {
  useEffect(() => {
    M.AutoInit();
  }, []);

  return (
    <div>
      <div
        className=""
        style={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          backgroundColor: "white",
          color: "#fe0000",
          textAlign: "center",
        }}
      >
        <div className="divider"></div>
        <br />
        <div className="container">
          <div className="row">
            <div className="col s12" style={{ textAlign: "center" }}>
              <Link to="/" style={{ color: "#fe0000" }}>
                Bongkonect © {new Date().getFullYear()}
              </Link>{" "}
              &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
              {/* <a
                className="modal-trigger"
                href="#sitemapModal"
                style={{ color: "#fe0000" }}
              >
                Site Map
              </a>{" "}
              &nbsp;&nbsp;|&nbsp;&nbsp;{" "} */}
              <a
                className="modal-trigger"
                href="#termsModal"
                style={{ color: "#fe0000" }}
              >
                Terms
              </a>{" "}
              &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
              <a
                className="modal-trigger"
                href="#contactModal"
                style={{ color: "#fe0000" }}
              >
                Contact
              </a>{" "}
              &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
              <a
                className="modal-trigger"
                href="#aboutModal"
                style={{ color: "#fe0000" }}
              >
                About
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="sitemapModal" className="modal">
        <div className="modal-content">
          <h5>Site Map</h5>
          <p>Home</p>
          <p>Login</p>
          <p>Signup</p>
          <p>User Agreement</p>
          <p>Seller Agreement</p>
          <p>Privacy Policy</p>
          <p>Community Guidelines</p>
          <p>Konect</p>
          <p>Near Me</p>
          <p>LifeLine</p>
          <p>Scope</p>
          <p>Profile</p>
          <p>List Busines</p>
        </div>
      </div>

      <div id="aboutModal" className="modal">
        <div className="modal-content">
          <h5>About Us</h5>
          <p>
            Join Bongkonect, one of the largest mother tongues based social,
            business and professional networking app designed to cater to a
            diverse range of users across different backgrounds, languages, and
            geographies. Users can create profile, list business, upload picture
            resume, upload jobs, connect with others, share content and build
            networks with like-minded people locally and globally. Your search
            for services, opportunities, profiles all from one app, Bongkonect.
            Bongkonect makes networking easier by encouraging professionals use
            their mother tongue or any language they are fluent and comfortable
            with. You can search and connect with professionals locally and
            globally. Users can upload their professional profile snapshots in
            the form of a picture resume and keep posting and publicising their
            updates by using Bongkonect’s networking features creating more
            options of getting noticed by employers and recruiters. So, upload
            your best picture and mention your strengths as clearly as possible.
            You can follow and converse with industry leaders, share your
            professional experiences and set opportunity alerts. Vacancy posting
            here is easy, attractive, networking friendly and time-saving,
            creating higher possibilities of getting notified by job seekers by
            social networking and displaying your organisation or product &
            services. Build your brand. More importantly searching profile is
            made interesting and handy, be it for professional or personal
            career. Business listing is a prominent feature in Bongkonect app.
            We made business listing easier by helping business owners of
            diverse backgrounds register their business details in just a few
            simple steps hassle free with features of both free and paid
            advertising and scheduling. Business owners are given the freedom to
            list their business by themselves and take complete charge to create
            a first impression to their target audience and connect with their
            prospects more conveniently. Business owners can list their business
            and promote in the language they are comfortable with, creatively.
            All that a business owner need to register and promote business is
            in one app on Bongkonect. Users can upload images, short videos,
            animated GIFS, write articles, responsibly and create followers. To
            always keep the social networking vibrant, all posts are with a
            defined post-life. All the paid or premium features are kept
            transparent on screen with likes, views and follower features.
            Bongkonect app encourages only real profiles and we honour your
            rights to privacy. Please read the “Terms of Use” while signing up.
            Bongkonect aims create a more inclusive and diverse networking
            environment, promoting cross-cultural communication and
            collaboration. So why wait anymore, let’s make the most of your
            Bongkonect app social, business or professional profile and network
            responsibly in the language you are confident. Welcome to
            Bongkonect.
          </p>
        </div>
      </div>

      <div id="contactModal" className="modal">
        <div className="modal-content">
          <h5>Contact Us</h5>
          <p>Support Email - support@bongkonect.com</p>
        </div>
      </div>

      <div id="termsModal" className="modal">
        <div className="modal-content">
          <h4 style={{ textAlign: "center" }}>Privacy Policy</h4>
          <div className="container">
            <div className="row">
              <p>
                This Privacy Policy applies to M/S BongKonect, having it's
                registered office at Bangalore. karnataka ,India.
              </p>
              <p>
                We recognise the importance of maintaining your privacy. We
                value your privacy and appreciate your trust in us. This Policy
                describes how we treat user information we collect on
                http://www.bongkonect.com and other offline sources. This
                Privacy Policy applies to current and former visitors to our
                website and to our online visitors. By visiting and/or using our
                website, you agree to this Privacy Policy.
              </p>
              <p>
                Bongkonect.com is a domain registered with ICANN and also an
                Intellectual property owed by M/S Bongkonect, an Indian entity,
                having its registered office at Bangalore. In the state of
                karnataka , India
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>Information we collect</h6>
            </div>

            <div className="row">
              <p>
                Contact information. We might collect your name, email, mobile
                number, phone number, street, city, state, pincode, country and
                IP address.
              </p>
              <p>
                Information you post. We collect information you post in a
                public space on our website or on a third‐ party social media
                site belonging to bongkonect.com
              </p>
              <p>
                Demographic information. We may collect demographic information
                about you, Posts you like, posts you intend to participate in,
                service that you buy, or any other information provided by you
                during the use of our website. We might collect this as a part
                of a survey also.
              </p>
              <p>
                Other information. If you use our website, we may collect
                information about your IP address and the browser you’re using.
                We might look at what site you came from, duration of time spent
                on our website, pages accessed or what site you visit when you
                leave us. We might also collect the type of mobile device you
                are using, or the version of the operating system your computer
                or device is running.
              </p>
              <p>We collect information in different ways.</p>
              <p>
                We collect information directly from you. We collect information
                directly from you when you peep into any specific Service
                offered for an offer or buy that service. We also collect
                information if you post a comment on our websites or intent to
                ask the seller a question through phone or email.
              </p>
              <p>
                We collect information from you passively. We use tracking tools
                like Google Analytics, Google Webmaster, browser cookies and web
                beacons for collecting information about your usage of our
                website.
              </p>
              <p>
                We get information about you from third parties. For example, if
                you use an integrated social media feature on our websites. The
                third‐party social media site will give us certain information
                about you complying with the directives as sanctioned by
                European Union. This could include your name and email address.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                Use of your personal information
              </h6>
            </div>

            <div className="row">
              <p>
                We use information to respond to your requests or questions. We
                might use your information to confirm your registration for an
                event or contest.
              </p>
              <p>
                We use information to improve our products and services. We
                might use your information to customize your experience with us.
                This could include displaying content based upon your
                preferences.
              </p>
              <p>
                We use information to look at site trends and customer
                interests. We may use your information to make our website and
                products better. We may combine information we get from you with
                information about you we get from third parties.
              </p>
              <p>
                We use information for security purposes. We may use information
                to protect our company, our customers, or our websites.
              </p>
              <p>
                We do not use information for marketing purposes. We might send
                you information about special promotions or offers. We might
                also tell you about new features or products. These might be our
                own offers or products, or third‐party offers or products we
                think you might find interesting. Or, for example, if you
                subscribe from us we’ll enroll you in our newsletter.
              </p>
              <p>
                We use information to send you transactional communications. We
                might send you emails or SMS about your account.
              </p>
              <p>We use information as otherwise permitted by law.</p>
            </div>
          </div>

          <h4 style={{ textAlign: "center" }}>User Agreement</h4>
          <div className="container">
            <div className="row">
              <p>
                By visiting or accessing www.bongkonect.com you are agreeing to
                be bound by the following terms and conditions. We may change
                these terms and conditions time to time. Your continued use of
                Bongkonect means that you accept any new or modified terms and
                conditions that we come up with. Please re‐visit the `Terms of
                Use' link at our site from time to time to stay abreast of any
                changes that we may introduce or else if you subscribe to our
                mailing list then you shall be notified to time about any
                changes Terms and Conditions.
              </p>
            </div>

            <div className="row">
              <p>
                The term "Bongkonect' is used through this entire Terms of Use
                document to refer to the website, its Parental body M/S
                Bongkonect; and the employees and associates of the company.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>1) Signing Up</h6>
            </div>

            <div className="row">
              <p>
                By Signing Up, you certify that all information you provide, now
                or in the future, is accurate. Bongkont reserves the right, in
                its sole discretion, to deny you access to this Web APP or any
                portion thereof without notice for the following reasons <br />{" "}
                (a) immediately by Bongkonect for any unauthorized access or use
                by you <br /> (b) immediately by Bongkonect if you assign or
                transfer (or attempt the same) any rights granted to you under
                this Agreement. <br /> (c) immediately, if you violate any of
                the other terms and conditions of this User Agreement.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>2) LICENSE</h6>
            </div>

            <div className="row">
              <p>
                Bongkonect, hereby grants you a limited, non‐exclusive,
                non‐assignable and non‐transferable license to access
                Bongkonect, provided and expressly conditioned upon your
                agreement that all such access and use shall be governed by all
                of the terms and conditions set forth in this USER AGREEMENT and
                that a user “may not rent, lease, lend, sell, redistribute, or
                sublicense the licensed application,” nor may a user “copy,
                decompile, reverse engineer, disassemble” the Website.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                3) COPYRIGHT & NO RETRANSMISSION OF INFORMATION
              </h6>
            </div>

            <div className="row">
              <p>
                Bongkonect as well as the design and information contained in
                this site is the valuable, exclusive property of Bongkonect &
                also its parental body M/S Bongkonect , and nothing in this
                Agreement shall be construed as transferring or assigning any
                such ownership rights to you or any other person or entity. All
                stock call information on Bongkonect is the proprietary,
                confidential property of Bongkonectand cannot be repeated for
                any reason outside the Bongkonect. You agree not to repeat or
                rebroadcast in any way any of the stock call information made on
                Bongkonect for any reason whatsoever. You agree that if you do
                repeat or re‐post any of Bongkonect listings by any mean, you
                will be liable for actual and punitive damages as determined by
                Bongkonect and additional damages to be determined by court of
                Law.
              </p>

              <p>
                You may not resell, redistribute, broadcast or transfer the
                information or use the information in a searchable,
                machine‐readable database unless separately and specifically
                authorized in writing by Bongkonect prior to such use. You may
                not license, lease, distribute, transfer, copy, reproduce,
                publicly display, publish, adapt, store or time‐share
                Bongkonect, any part thereof, or any of the information received
                or accessed therefrom to or through any other person or entity
                unless separately and specifically authorized in writing by
                Bongkonect prior to such use. In addition, you may not remove,
                alter or obscure any copyright, legal or proprietary notices in
                or on any portions of Bongkonect without prior written
                authorization Except as set forth herein, any other use of the
                information contained in this site requires the prior written
                consent of Bongkonect and may require a separate fee.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>4) DELAYS IN SERVICES</h6>
            </div>

            <div className="row">
              <p>
                Neither Bongkonect(including its and their directors, employees,
                affiliates, agents, representatives or subcontractors) shall be
                liable for any loss or liability resulting, directly or
                indirectly, from delays or interruptions due to electronic or
                mechanical equipment failures, telephone interconnect problems,
                defects, weather, strikes, walkouts, fire, acts of God, riots,
                armed conflicts, acts of war, or other like causes.Bongkonect
                shall have no responsibility to provide you access to Bongkonect
                while interruption of website is due to any such cause shall
                continue.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>5) LIABILITY DISCLAIMER</h6>
            </div>

            <div className="row">
              <p>
                YOU EXPRESSLY AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE
                RISK.
              </p>

              <p>
                THE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND
                SERVICES PUBLISHED ON THIS WEB SITE MAY INCLUDE INACCURACIES OR
                TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                CONTENTS HEREIN .Bongkonect AND/OR ITS RESPECTIVE SUPPLIERS MAY
                MAKE IMPROVEMENTS AND/OR CHANGES IN THIS WEBSITE AT ANY TIME.
                THIS WEBSITE MAY BE TEMPORARILY UNAVAILABLE FROM TIME TO TIME
                DUE TO REQUIRED MAINTENANCE, TELECOMMUNICATIONS INTERRUPTIONS,
                OR OTHER DISRUPTIONS. Bongkonect(AND ITS OWNERS, SUPPLIERS,
                CONSULTANTS, ADVERTISERS, AFFILIATES, PARTNERS, EMPLOYEES OR ANY
                OTHER ASSOCIATED ENTITIES, ALL COLLECTIVELY REFERRED TO AS
                ASSOCIATED ENTITIES HEREAFTER) SHALL NOT BE LIABLE TO USER OR
                MEMBER OR ANY THIRD PARTY SHOULD Bongkonect EXERCISE ITS RIGHT
                TO MODIFY OR DISCONTINUE ANY OR ALL OF THE CONTENTS,
                INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES PUBLISHED
                ON THIS WEBSITE.
              </p>

              <p>
                Bongkonect AND/OR ITS RESPECTIVE ASSOCIATED ENTITIES MAKE NO
                REPRESENTATIONS ABOUT THE SUITABILITY OF THE CONTENTS,
                INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES CONTAINED
                ON THIS WEBSITE FOR ANY PURPOSE. ALL SUCH CONTENTS, INFORMATION,
                SOFTWARE, PRODUCTS, FEATURES AND SERVICES ARE PROVIDED "AS IS"
                WITHOUT WARRANTY OF ANY KIND. Bongkonect AND/OR ITS ASSOCIATED
                ENTITIES HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
                REGARD TO THESE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS,
                FEATURES AND SERVICES, INCLUDING ALL IMPLIED WARRANTIES AND
                CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE, NON‐INFRINGEMENT, AND AVAILABILITY.
              </p>

              <p>
                IN NO EVENT SHALL Bongkonect AND/OR ITS ASSOCIATED ENTITIES BE
                LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL
                OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED
                WITH THE USE OF THIS WEBSITE OR WITH THE DELAY OR INABILITY TO
                USE THIS WEBSITE, OR FOR ANY CONTENTS, INFORMATION, SOFTWARE,
                PRODUCTS, FEATURES AND SERVICES OBTAINED THROUGH THIS WEBSITE,
                OR OTHERWISE ARISING OUT OF THE USE OF THIS WEBSITE, WHETHER
                BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF
                Bongkonect OR ANY OF ITS ASSOCIATED ENTITIES HAS BEEN ADVISED OF
                THE POSSIBILITY OF DAMAGES.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>6) INFORMATION DISCLAIMER</h6>
            </div>

            <div className="row">
              <p>
                You acknowledge that the information provided through Bongkonect
                is compiled from sources, which are beyond the control of
                Bongkonect though such information is recognized by the parties
                to be generally reliable, the parties acknowledge that
                inaccuracies may occur and Bongkonect does not warrant the
                accuracy or suitability of the information. For this reason, as
                well as the possibility of human and mechanical errors and other
                factors, YOU ACKNOWLEDGE THAT Bongkonect IS PROVIDED TO YOU ON
                AN "AS IS, WITH ALL FAULTS" BASIS. Bongkonect EXPRESSLY
                DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS, ORAL,
                IMPLIED, STATUTORY OR OTHERWISE, OF ANY KIND TO THE USERS AND/OR
                ANY THIRD PARTY, INCLUDING ANY IMPLIED WARRANTIES OF ACCURACY,
                TIMELINESS, COMPLETENESS, MERCHANTABILITY AND FITNESS FOR A
                PARTICULAR PURPOSE, AS WELL AS ANY WARRANTIES ARISING BY VIRTUE
                OF CUSTOM OF TRADE OR COURSE OF DEALING AND ANY IMPLIED
                WARRANTIES OF TITLE OR NON‐INFRINGEMENT. IN ADDITION,Bongkonect,
                IN PROVIDING THE INFORMATION MAKES NO ENDORSEMENT OF ANY
                PARTICULAR SECURITY, MARKET PARTICIPANT, OR BROKERAGE.
                FURTHER,Bongkonect DOES NOT REPRESENT OR WARRANT THAT IT WILL
                MEET YOUR REQUIREMENTS OR IS SUITABLE FOR YOUR NEEDS.
              </p>

              <p>
                Under this User Agreement, you assume all risk of errors and/or
                omissions in Bongkonect, including the transmission or
                translation of information. You assume full responsibility for
                implementing sufficient procedures and checks to satisfy your
                requirements for the accuracy and suitability of Bongkonect,
                including the information, and for maintaining any means, which
                you may require for the reconstruction of lost data or
                subsequent manipulations or analysis of the information under
                the User Agreement. YOU AGREE THAT Bongkonect(INCLUDING ITS AND
                THEIR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, GROUP
                COMPANIES AGENTS, REPRESENTATIVES OR SUBCONTRACTORS) SHALL NOT
                IN ANY EVENT BE LIABLE FOR ANY SPECIAL, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE
                Bongkonect FOR ANY PURPOSE WHATSOEVER.Bongkonect AND ITS
                AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS SHALL HAVE
                NO LIABILITY IN TORT, CONTRACT, OR OTHERWISE TO USER AND/OR ANY
                THIRD PARTY.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>7) INDEMNIFICATION</h6>
            </div>

            <div className="row">
              <p>
                YOU SHALL INDEMNIFY, DEFEND AND HOLD HARMLESS
                Bongkonect(INCLUDING ITS AND THEIR OFFICERS, DIRECTORS,
                EMPLOYEES, AFFILIATES, GROUP COMPANIES, AGENTS, REPRESENTATIVES
                OR SUBCONTRACTORS) FROM ANY AND ALL CLAIMS AND LOSSES IMPOSED
                ON, INCURRED BY OR ASSERTED AS A RESULT OF OR RELATED TO: (a)
                your access and use of Bongkonect (b) any non‐compliance by user
                with the terms and conditions hereof; or (c) any third party
                actions related to users receipt and use of the information,
                whether authorized or unauthorized. Any clause declared invalid
                shall be deemed severable and not affect the validity or
                enforceability of the remainder. These terms may only be amended
                in a writing signed by
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>8) ATTORNEY'S FEES</h6>
            </div>

            <div className="row">
              <p>
                If Bongkonect takes action (by itself or through its associate
                companies) to enforce any of the provisions of this User
                Agreement, including collection of any amounts due hereunder,
                M/S Bongkonect shall be entitled to recover from you (and you
                agree to pay), in addition to all sums to which it is entitled
                or any other relief, at law or in equity, reasonable and
                necessary attorney's fees and any costs of any litigation.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>9) ENTIRE AGREEMENT</h6>
            </div>

            <div className="row">
              <p>
                This User Agreement constitutes the entire agreement between the
                parties, and no other agreement, written or oral, exists between
                you and Bongkonect. This agreement represents the entire
                understanding between the parties with respect to its subject
                matter and supersedes any previous communication or agreements
                that may exist.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>10) JURISDICTION</h6>
            </div>

            <div className="row">
              <p>
                The terms of this agreement are exclusively based on and subject
                to Indian law. You hereby consent to the exclusive jurisdiction
                and venue of courts in India, all disputes arising out of or
                relating to the use of this site is unauthorized in any
                jurisdiction that does not give effect to all provisions of
                these terms and conditions, including without limitation this
                paragraph.
              </p>
            </div>
          </div>

          <h4 style={{ textAlign: "center" }}>Seller Agreement</h4>
          <div className="container">
            <div className="row">
              <p>
                PLEASE READ THE ENTIRE AGREEMENT.
                <br />
                YOU MAY PRINT THIS PAGE FOR YOUR RECORDS. <br />
                THIS IS A LEGAL AGREEMENT BETWEEN YOU AND MERCHANT,(Bongkonect){" "}
                <br />
                BY SUBMITTING THE ONLINE APPLICATION YOU ARE AGREEING THAT YOU
                HAVE READ AND UNDERSTAND THE TERMS AND CONDITIONS OF THIS
                AGREEMENT AND THAT YOU AGREE TO BE LEGALLY RESPONSIBLE FOR EACH
                AND EVERY TERM AND CONDITION.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>1) Overview</h6>
            </div>

            <div className="row">
              <p>
                This Agreement contains the complete terms and conditions that
                apply to you becoming a Seller in Bongkonect's Seler Program.The
                purpose of this Agreement is to alow HTML linking between your
                website and the Bongkonect website. Please note that throughout
                this Agreement, "we," "us," and "our" refer to Bongkonect, and
                "you, ""your" and "yours" refer to the seler.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>2) PURPOSE</h6>
            </div>

            <div className="row">
              <p>
                This Agreement is made and is efective by and between M/S
                Bongkonect, a company,legaly owned trademark, copyright,
                software products asociated with Bongkonect.com or
                bongkonect.in. & You(Seler), Who is looking for a technology
                enabled platform to offer for sell of various goods or rendering
                services in his own capacity. This Agreement shal be efective
                from 25th December,2019 and remains in efect until terminated in
                acordance with the terms of this Agreement.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>3) AGREEMENT TERMS</h6>
            </div>

            <div className="row">
              <p>
                Seller agrees to provide various products or services to general
                users of Bongkonect.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>4) Seller Obligations</h6>
            </div>

            <div className="row">
              <p>
                4.1. To begin the enrolment proces, you will complete and submit
                the online application at the Bongkonect's server.The fact that
                wea uto-approve applications doesnot imply that we may not
                re-evaluate your application at a later time. We may reject your
                application at our solediscretion. We may cancel your
                application if we determine that your post on our site is
                unsuitable for our Program, including if it: <br />
                4.1.1. Promotes sexualy explicit materials. <br />
                4.1.2. Promotes violence. <br />
                4.1.3. Promotes discrimination based on race, sex, religion,
                nationality, disability, sexual orientation, or age. <br />
                4.1.4. Promotes ilegal activity. <br />
                4.1.5. Incorporates any materials which infringe or asist others
                to infringe on any copyright, trademark or other intelectual
                property rights or to violate the law. <br />
                4.1.6. Includes "Merchant" or variations or mispelings thereof
                in its domain name. <br />
                4.1.7. Is otherwise in any way unlawful, harmful, threatening,
                defamatory, obscene, harasing, or racialy, ethnicaly or
                otherwise objectionable to us in our solediscretion. <br />
                4.1.8. Contains software downloads that potentialy enabled
                versions of commision from other selers in our program. <br />
                4.1.9. You may not create or design your website or any other
                website that you operate, explicitly or implied in a manner
                which resembles our website nor design your website in a manner
                which leads customers to be lieve you are Bongkonect or any
                other seler’s busines. <br />
                <br />
                4.2. As a member of Bongkonect's Seler Program, you will have
                access to SelerAcount Manager. Here you will be able to review
                our Program’s details and previously-published Seler newsleters,
                download HTML code (that provides for links to webpages with in
                the Bongkonect website) and banner creatives, browse and get
                tracking codes for our coupons and deals. In order for us to
                acurately keep track of al guest visits from yoursite to ours,
                you must use the HTML code that we provide for each banner, text
                link, orother Seler link we provide you with.
                <br />
                4.3. Bongkonect reserves the right, at anytime, to review your
                placement and approve the use of Your Links and require that you
                change the placement or use to comply with the guidelines
                provided to you.
                <br />
                4.4. The maintenance and the updating of your own site (if any)
                will be your responsibility. We may monitor your activities as
                we feel necesary to make sure that it is up-to-date and to
                notify you of any changes that we feel should enhance your
                performance.
                <br />
                4.5. It is entirely your responsibility to folow al applicable
                intelectual property andother laws that pertain to site. You
                must have expres permision to use any person's copyrighted
                material, whether it be a writing, an image, or any other
                copyrightable work. We wil not be responsible (and you wil be
                solely responsible) if you use another person's copyrighted
                material or other intelectual property inviolation of the law or
                anyt hird party rights.
                <br />
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                5) Bongkonect Rights and Obligations
              </h6>
            </div>

            <div className="row">
              <p>
                5.1. We have the right to monitor your activities at our site at
                any time to determine if you are folowing the terms and
                conditions of this Agreement. We may notify you of any changes
                to your behavior that we feel should be made, or to make sure
                that your links to our website are appropriate and to notify
                further you of any changes that we feel should be made. If you
                donot make the changes to your activities that we feel are
                necesary, we reserve the right to terminate your participation
                in the Bongkonect Seler Program. <br />
                5.2. Bongkonect reserves the right to terminate this Agreement
                and your participation in the Bongkonect Seler Program
                immediately and without notice to you should you commit fraud in
                your use of the Bongkonect Seler Program or should you abuse
                this program ina nyway. If such fraud or abuse is detected,
                Bongkonect shal not be liable to you for any refund for such
                fraudulent activities. <br />
                5.3. This Agreement wil begin upon our aceptance of your Seler
                application, and wil continue unles terminated here under.{" "}
                <br />
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>6) Termination</h6>
            </div>

            <div className="row">
              <p>
                Either you or we may end this Agreement AT ANYTIME, with or
                without cause, by giving the other party written notice .
                Written notice can be in the form of mail, email or fax. In
                addition, this Agreement wil terminate immediately upon any
                breach of this Agreement by you.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>7) Modification</h6>
            </div>

            <div className="row">
              <p>
                We may modify any of the terms and conditions in this Agreement
                at any time at our sole discretion. In such event, you wil be
                notified by Email. Modifications may include, but are not
                limited to, changes in the payment procedures and Bongkonect's
                Seller Program rules. If any modification is unaceptable to you,
                your only option is to end this Agreement. Your continued
                participation in Bongkonect's Seler Program folowing the posting
                of a change notice or new Agreement on our site wil indicate
                your agreement to the changes.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>8) Payment</h6>
            </div>

            <div className="row">
              <p>
                You agree to pay Bongkonect a fixed membership fee for your
                unlimited uses of Bongkonect which may what so ever change from
                time to time. Refunds wil not be made if you cancel your
                membership plan. Bongkonect uses a third party to handle al of
                the tracking and payment. The third party is the payment
                gateways such as Paytm or any other payment methods. Kindly
                review the gateway’s payment terms and conditions.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                9) Access to Seler Account Interface
              </h6>
            </div>

            <div className="row">
              <p>
                You will create a pasword so that you may enter bongkonect'
                secure Seler acount interface.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>10) Promotion Restrictions</h6>
            </div>

            <div className="row">
              <p>
                10.1. You are free to promote your own products or services but
                naturaly any promotion that mentions Bongkonect could be
                perceived by the public or the pres as a joint efort. You should
                know that certain forms of advertising are always prohibited by
                Bongkonect. For example, advertising commonly refered to as
                "spamming" is unaceptable to us and could cause damage to our
                name. Other generaly prohibited forms of advertising include the
                use of unsolicited commercial email(UCE), postings to
                non-commercial news groups and cros-posting to multiple news
                groups at once. In addition, you may not advertise in anyway
                that efectively conceals or misrepresents your identity, your
                domain name, or your return email addres. You may use mailings
                to customers to promote Bongkonect so long as the recipient is
                already a customer or subscriber of your services or website,
                and recipients have the option to remove themselves from future
                mailings. Also, you may post to newsgroups to promote Bongkonect
                so long asthe newsgroup specificaly welcomes commercial mesages.
                At al times, you must clearly represent your self and your
                websites as independent from Bongkonect. If it comes to our
                atention that you are spamming, we wil consider that cause for
                immediate termination of this Agreement and your participation
                in th eBongkonect Seler Program. Any pending balances owed to
                you wil not be paid if your account is terminated due to such
                unaceptable advertising or solicitation. <br />
                10.2. Selers that among other keywords or exclusively bid in
                their Pay-Per-Click campaignson keywords such as Bongkonect,
                www.Bongkonect, and/or any mispelings or similar alterations of
                these–be it separately or in combination with other keywords–and
                do not direct the trafic from such campaigns to their own
                website prior to re-directingit to ours, wil be considered
                trademark violators, and will be banned from Merchant’s Seller
                Program . We will do everything possible to contact the Seler
                prior to the ban. However, were serve the right to expel any
                trademark violator from our Seler program without prior notice,
                and on the first ocurence of such PPC bidding behavior.
                <br />
                10.3. Selers are not prohibited from keying in prospect’s
                information into the lead form as long as the prospects’
                information is real and true, and the searevalid leads (i.e.
                sincerely interested in Merchant’s service).
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>11) Grant of Licences</h6>
            </div>

            <div className="row">
              <p>
                11.1. We grant to you a non-exclusive, non-transferable,
                revocable right to access our site through HTML links solely in
                acordance with the terms of this Agreement and solely in
                connection with such links, to use our logos, trade names, trade
                marks, and similar identifying material (colectively, the
                "Licensed Materials") that we provide to you or authorized for
                such purpose. You are only entitled to use the Licensed
                Materials to the extent that you are a member in good standing
                of Bongkonect's Seler Program. You agree that al uses of the
                Licensed Materials wil be on behalf of Bongkonect and the good
                wil asociated there with wil be atached to the sole benefit of
                Bongkonect.
                <br />
                11.2. Each party agrees not to use the other's proprietary
                materials in any manner that is disparaging, misleading, obscene
                or that otherwise portrays the party in a negative light. Each
                party reserves al of its respective rights in the proprietary
                materials covered by this license. Other than the license
                granted in this Agreement, each party retains alright, title,
                and interest to its respective rights and no right,title,or
                interest is transfered to the other.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>12) Disclaimer</h6>
            </div>

            <div className="row">
              <p>
                Bongkonect MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS OR
                WARRANTIES REGARDING Bongkonect SERVICE AND WEBSITE OR THE
                PRODUCTS OR SERVICES PROVIDED THERE IN, ANY IMPLIED WARRANTIES
                OF Bongkonect ABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT ARE EXPRESSLY DISCLAIMEDAND EXCLUDED . IN
                ADDITION , WE MAKE NO REPRESENTATION THAT THE OPERATION OF OUR
                SITE WILL BEU NINTERRUPTED OR ERROR FREE, AND WE WILL NOT BE
                LIABLE FOR THE CONSEQUENCES OF ANY INTERRUPTIONS OR ERRORS.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                13) Representations and Waranties
              </h6>
            </div>

            <div className="row">
              <p>
                You represent and warant that: <br />
                13.1. This Agreement has been duly and validly executed and
                delivered by you and constitutes your legal, valid, and binding
                obligation, enforce able against you ina cordance with its
                terms. <br />
                13.2. You have the ful right, power, and authority to enter into
                and bound by the terms and conditions of this Agreement and to
                perform your obligations under this Agreement, without the
                approval or consent of any other party. <br />
                11.3. You have suficient right, title, and interest in your
                ofered goods or services.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                14) Limitations of Liability
              </h6>
            </div>

            <div className="row">
              <p>
                W E WILL NOT BE LIABLE TO YOU WITH RESPECT TO ANY SUBJECT MATTER
                OF THIS AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, TORT, STRICT
                LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT,
                INCIDENTAL, CONSEQUENTIAL,SPECIAL OR EXEMPLARY DAMAGES
                (INCLUDING, WITHOUT LIMITATION,LOSS OF REVENUE OR GOOD WILL OR
                ANTICIPATED PROFITS ORL OST BUSINESS), EVEN IF WE HAVE BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FURTHER , NOT
                WITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THIS
                AGREEMENT, IN NO EVENT SHALL Bongkonect'S CUMULATIVR LIABILITY
                TO YOU ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER
                BASED IN CONTRACT, NEGLIGENCE, STRICT LIABILITY, TO OTHER LEGAL
                OR EQUITABLE THEORY, EXCEED THE TOTAL COMMISSION FEES PAID TO
                YOU UNDER THIS AGREEMENT.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>15) Indemnification</h6>
            </div>

            <div className="row">
              <p>
                You here by agree to indemnify and hold harmles Bongkonect, and
                its subsidiaries and other selers, and their directors, oficers,
                employees, agents, shareholders, partners, members, and other
                owners, against any and al claims, actions, demands,
                liabilities, loses, damages, judgments, setlements, costs, and
                expenses (including reasonable atorneys' fees) (any or al of the
                foregoing here in after refered to as"Loses") insofaras such
                Loses (or actions in respect there of) a rise out of or a
                rebased on (i) any claim that our use of the Seller trademarks
                in fringes on any trademark, tradename, servicemark, copyright,
                license, intelectual property, or other proprietary right of any
                third party, (i) any mis representation of are presentation or
                warant yor breach of acovenantand agreement made by you herein,
                or (i) any claim related to your site, including, without
                limitation, content there in not atributable to us.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>16) Confidentiality</h6>
            </div>

            <div className="row">
              <p>
                All confidential information, including, but not limited to, any
                busines, technical, financial, and customer information,
                disclosed by one party to the other during negotiations or the
                efective term of this Agreement which is marked "Confidential,"
                wil remain the sole property of the disclosing party, and each
                party wil keep in confidence and not use or disclose such
                proprietary information of the other party without expres write
                and permision of the disclosing party.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>17) Miscelaneous</h6>
            </div>

            <div className="row">
              <p>
                17.1. You agree that you are an independent contractor, and
                nothing in this Agreement will create any partnership, joint
                venture, agency, franchise, sales representative, or employment
                relationship between you and Bongkonect. You wil have no
                authority to make or acept any ofers or represent ations on our
                behalf. You wil not make any statement, whether on Your Site or
                any other of Your Site or otherwise, that reasonably would
                contradict anything in this Section. <br />
                17.2. Neither party may asign its rights or obligations under
                thisA greement to any party, except to a party who obtains all
                or substantialy all of the busines or asets of a third party.
                <br />
                17.3. This Agreement shal be governed by and interpreted in
                acordance with the laws of India without regard to the conflicts
                of laws and principles thereof.
                <br />
                17.4. You may not amend or waive any provision of this Agreement
                unles in writing and signed bybothparties.
                <br />
                17.5. This Agreement represents the entire agreement between us
                and you, and shal supersede al prior agreements and
                communications of the parties, or al or writen.
                <br />
                17.6. The headings and titles contained in this Agreement are
                included for convenienceonly, and shal not limit or otherwise
                afect the terms of this Agreement.
                <br />
                17.7. If any provision of this Agreement is held to be invalid
                or unenforceable,that provision shal be eliminated or limited to
                the minimum extent necesary such that the intent of the parties
                is efectuated, and there main der of this agreement shal have
                ful force and efect.
                <br />
              </p>
            </div>
          </div>

          <h4 style={{ textAlign: "center" }}>Community Guidelines</h4>
          <div className="container">
            <div className="row">
              <p>
                We want Bongkonect to continue to be an authentic and safe place
                for inspiration and expression. Help us foster this community.
                Bongkonect is a reflection of our diverse community of cultures,
                ages, and beliefs. We’ve spent a lot of time thinking about the
                different points of view that create a safe and open environment
                for everyone.
              </p>
              <p>
                We created the Community Guidelines so you can help us foster
                and protect this amazing community . By using Bongkonect &
                sharing your content with our other community members, you agree
                to the guidelines and our Terms of Use. We’re commited to these
                guidelines and we hope you are too. Overstepping thes
                eboundaries may result in deleted content, disabled accounts, or
                other restrictions.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                Share only Video Recordings and videos that you’ve taken or have
                the right to share.
              </h6>
            </div>

            <div className="row">
              <p>
                As always, you own the content you post on Bongkonect. Remember
                to post authentic content, and don’t post anything you’ve copied
                or collected from the Internet that you don’t have the right to
                post or Copyright in the content is owned by someone, or any
                such copyrighted material which is beyond the scope of fair use.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                Do Not Post Video Recordings and videos that are non-appropriate
                for a diverse audience.
              </h6>
            </div>

            <div className="row">
              <p>
                This includes Video Recordings, videos, and some
                digitaly-created content that sound sexual intercourse, women
                screaming, other sexual orientation. It also includes some Video
                Recordings of scaring and women activity.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                Foster meaning ful and genuine interactions.
              </h6>
            </div>

            <div className="row">
              <p>
                Help us stay spam-free by not artificialy colecting likes,
                followers, or shares, posting repetitive comments or content, or
                repeatedly contacting people for commercial purposes without
                their consent.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>Follow the law.</h6>
            </div>

            <div className="row">
              <p>
                Bongkonect is not a place to support or praise terorism,
                organized crime, or hate groups, Political Discusion or
                Discourse, Ofering sexual services, buying or seling fire arms
                and illegal or prescription drugs (even if it’s legal in your
                region) is also not allowed.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                Respect other members of the Bongkonect community.
              </h6>
            </div>

            <div className="row">
              <p>
                We want to foster a positive, diverse community. We remove
                content that contains credible threats or hate speech, content
                that targets private individuals to degrade or sham e them ,
                personal information meant to blackmail or haras someone, and
                repeated unwanted messages. We do generally allow stronger
                conversation around people who are featured in the news or have
                a large public audience due to their profesion or chosen
                activities.
              </p>
              <p>
                It's never OK to encourage violence or atack any one based on
                their race, ethnicity, national origin, sex, gender, gender
                identity, sexual orientation, religious af filiation,
                disabilities, or diseases. When hate speech is being shared to
                chalenge it or to raise awarenes, we may allow it. In those
                instances, we ask that you express your intent clearly.
              </p>
            </div>

            <div className="row">
              <h6 style={{ fontWeight: "bold" }}>
                Help us keep the community strong:
              </h6>
            </div>

            <div className="row">
              <p>
                Each of us is an important part of the Bongkonect community . If
                you see something that you think may violate our guidelines,
                please help us by using our built-in reporting/EmailSupport
                option. We have a global team that reviews these reports and
                works as quickly as possible to remove content that doesn’t meet
                our guidelines. Even if you or someone you know doesn’t have an
                Bongkonect account, you can still file a report. When you
                complete the report, try to provide as much information as
                possible,such as links, usernames, and descriptions of the
                content, so we can find and review it quickly. We may remove
                entire posts if either the imagery or associated captions
                violate our guidelines. For more information, check out our Help
                Center and Terms of Use.
              </p>
              <p>
                Thank you for helping us create one of the best communities in
                the world, The Bongkonect Team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
