import React from "react";
import Nav from "../../components/Nav/Nav";
import ListingCard from "../../components/ListingCard/ListingCard";
import { connect } from "react-redux";
import { fetchPosterBusinesses } from "../../store/actions/index";
import Poster from "../../components/Poster/Poster";
import Carousel from "../../components/Carousel/Carousel";
import debounce from "lodash.debounce";
import axios from "../../axios";
import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet";
import NavNotSignedIn from "../../components/NavNotSignedIn/NavNotSignedIn";

class BusinessListing extends React.Component {
  leftPostersInterval;
  rightPostersInterval;

  constructor(props) {
    super(props);

    // Sets up our initial state
    this.state = {
      error: false,
      hasMore: true,
      isLoading: false,
      businesses: [],
      leftPosters: [],
      rightPosters: [],
      cities: [],
      categories: [],
      page: 0,
      city: "",
      category: "",
    };

    // Binds our scroll event handler
    window.onscroll = debounce(() => {
      const {
        loadBusinesses,
        state: { error, isLoading, hasMore },
      } = this;

      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (error || isLoading || !hasMore) return;

      // Checks that the page has scrolled to the bottom
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadBusinesses();
      }
    }, 100);
  }

  searchBusinesses = () => {
    this.setState({ isLoading: true, businesses: [], page: 0 }, () => {
      const data = {
        page: this.state.page + 1,
        category: this.state.category,
        city: this.state.city,
      };
      axios
        .post("getBusinesses", data)
        .then((response) => {
          this.setState({
            hasMore: this.state.businesses.length < response.data.count,
            isLoading: false,
            page: response.data.currentPage,
            businesses: [...this.state.businesses, ...response.data.posts],
          });
        })
        .catch((err) => {
          this.setState({
            error: err.message,
            isLoading: false,
          });
        });
    });
  };

  loadBusinesses = () => {
    this.setState({ isLoading: true }, () => {
      const data = {
        page: this.state.page + 1,
        category: this.state.category,
        city: this.state.city,
      };
      axios
        .post("getBusinesses", data)
        .then((response) => {
          this.setState({
            hasMore: response.data.posts.length > 0,
            isLoading: false,
            page: response.data.currentPage,
            businesses: [...this.state.businesses, ...response.data.posts],
          });
        })
        .catch((err) => {
          this.setState({
            error: err.message,
            isLoading: false,
          });
        });
    });
  };

  loadLeftPosters = () => {
    axios
      .get("getPosterBusiness", { params: { size: 8 } })
      .then((response) => {
        this.setState({
          leftPosters: response.data.posts,
        });
      })
      .catch((err) => {});
  };

  loadRightPosters = () => {
    axios
      .get("getPosterBusiness", { params: { size: 8 } })
      .then((response) => {
        this.setState({
          rightPosters: response.data.posts,
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    axios
      .get("getCategories")
      .then((response) =>
        this.setState({ categories: response.data.categories })
      )
      .catch((err) => console.log(err.data));

    axios
      .get("getCities")
      .then((response) => {
        this.setState({ cities: response.data });
      })
      .catch((err) => console.log(err.data));
    this.loadBusinesses();
    // this.loadLeftPosters();
    // this.loadRightPosters();

    // this.leftPostersInterval = setInterval(
    //     () => this.loadLeftPosters(),
    //     4000
    // )

    // this.rightPostersInterval = setInterval(
    //     () => this.loadRightPosters(),
    //     4000
    // )
  }

  componentWillUnmount() {
    clearInterval(this.leftPostersInterval);
    clearInterval(this.rightPostersInterval);
  }

  render() {
    const MyLoader = () => (
      <>
        <ContentLoader
          height={160}
          width={600}
          speed={3}
          primaryColor="#ecebeb"
          secondaryColor="#f3f3f3"
        >
          <rect x="5" y="5" rx="5" ry="5" width="490" height="100" />
          <circle cx="550" cy="55" r="50" />
          <rect x="5" y="5" rx="5" ry="5" width="490" height="100" />
          <circle cx="550" cy="55" r="50" />
        </ContentLoader>
        <ContentLoader
          height={160}
          width={600}
          speed={3}
          primaryColor="#ecebeb"
          secondaryColor="#f3f3f3"
        >
          <rect x="5" y="5" rx="5" ry="5" width="490" height="100" />
          <circle cx="550" cy="55" r="50" />
          <rect x="5" y="5" rx="5" ry="5" width="490" height="100" />
          <circle cx="550" cy="55" r="50" />
        </ContentLoader>
      </>
    );

    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Bongkonect - konect your social business and professional community
          </title>
          <meta
            name="description"
            content="Bongkonect - konect your social business and professional community"
          />
          <meta
            name="keywords"
            content="Bengali doctor, Bengali nurse, Bengali maid, Bengali cook, Bengali book,bengali news, Bengali food, bangla khabar"
          />
        </Helmet>
        {this.props.isAuthenticatedIndividual ||
        this.props.isAuthenticatedBusiness ? (
          <Nav location={this.props.location} />
        ) : (
          <NavNotSignedIn location={this.props.location} />
        )}

        <br />
        <div
          className="row hide-on-small-only"
          style={{ marginLeft: "30px", marginRight: "30px" }}
        >
          {/* <Carousel /> */}
        </div>

        <div className="content">
          <div className="row">
            <div className="col s3 hide-on-med-and-down">
              {/* {
                                this.state.leftPosters.map((poster) => {

                                    return (
                                        <Poster key={'leftposter-' + Math.random()} poster={poster} float='right' />
                                    )
                                })
                            } */}
            </div>

            <div className="col s12 l6 xl6">
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col s5">
                  <select
                    style={{
                      backgroundColor: "white",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "grey",
                      width: "100%",
                    }}
                    className="selectBox browser-default"
                    value={this.state.category}
                    onChange={(e) =>
                      this.setState({ category: e.target.value })
                    }
                  >
                    <option value="">Category</option>
                    {this.state.categories.map((category, index) => {
                      return (
                        <option value={category.category} key={category._id}>
                          {category.category}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col s5" style={{ marginLeft: "-15px" }}>
                  <select
                    style={{
                      backgroundColor: "white",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "grey",
                      width: "100%",
                    }}
                    className="selectBox browser-default"
                    value={this.state.city}
                    onChange={(e) => this.setState({ city: e.target.value })}
                  >
                    <option value="">City</option>
                    {this.state.cities.map((city) => {
                      return (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col s2" style={{ marginLeft: "-15px" }}>
                  <button
                    className="btn"
                    type="submit"
                    style={{ height: "45px", backgroundColor: "#fe0000" }}
                    onClick={(e) => this.searchBusinesses()}
                  >
                    <i
                      className="material-icons"
                      style={{ fontSize: "2em", paddingTop: "5px" }}
                    >
                      search
                    </i>
                  </button>
                </div>
              </div>

              {this.state.businesses.map((business) => {
                return (
                  <ListingCard
                    key={business.posts[0]._id}
                    business={business}
                  />
                );
              })}
              {this.state.isLoading && (
                <div>
                  <MyLoader />
                  <MyLoader />
                </div>
              )}
              {!this.state.hasMore && <div>No more results!</div>}
            </div>

            <div className="col s3 hide-on-med-and-down">
              {/* {
                                this.state.rightPosters.map((poster) => {
                                    return (
                                        <Poster key={'rightposter-' + Math.random()} poster={poster} float='left' />
                                    )
                                })
                            } */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posterBusinesses: state.businesses.posterBusinesses,
    loading: state.businesses.loading,
    isAuthenticatedIndividual:
      state.auth.token !== null && state.auth.isAuthenticatedIndividual,
    isAuthenticatedBusiness:
      state.auth.token !== null && state.auth.isAuthenticatedBusiness,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPosterBusinesses: (posterSize) =>
      dispatch(fetchPosterBusinesses(posterSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessListing);
