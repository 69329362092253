import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  token: null,
  userId: null,
  user: null,
  error: "",
  loading: false,
  isAuthenticatedIndividual: false,
  isAuthenticatedBusiness: false,
  showPayment: false,
  order: null,
  signupCompleted: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.loginData.token,
        userId: action.payload.loginData.userId,
        user: action.payload.loginData.user,
        loading: false,
      };

    case actionTypes.AUTHENTICATE_INDIVIDUAL:
      return {
        ...state,
        token: action.data.token,
        userId: action.data.userId,
        user: action.data.user,
        isAuthenticatedIndividual: true,
        loading: false,
      };

    case actionTypes.AUTHENTICATE_BUSINESS:
      return {
        ...state,
        token: action.data.token,
        userId: action.data.userId,
        user: action.data.user,
        isAuthenticatedBusiness: true,
        loading: false,
      };

    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        error: action.payload.error,
        order: action.payload.order,
        user: action.payload.user,
        loading: false,
      };

    case actionTypes.SIGNUP_START:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        token: action.payload.data.token,
        userId: action.payload.data.userId,
        user: action.payload.data.user,
        loading: false,
        order: action.payload.data.order,
        showPayment:
          action.payload.data.user.userType === "business" ? true : false,
        signupCompleted: true,
      };

    case actionTypes.SIGNUP_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        showPayment: false,
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        userId: null,
        error: "",
        loading: false,
        isAuthenticatedIndividual: false,
        isAuthenticatedBusiness: false,
      };

    case actionTypes.UPDATE_PROFILE_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
      };

    case actionTypes.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.UPDATE_PROFILEPIC_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.UPDATE_PROFILEPIC_SUCCESS:
      const newUser = { ...state.user, profilePic: action.profilePic };

      return {
        ...state,
        user: newUser,
        loading: false,
      };

    case actionTypes.UPDATE_PROFILEPIC_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.UPDATE_PROFILEBACKGROUND_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.UPDATE_PROFILEBACKGROUND_SUCCESS:
      const tempUser = {
        ...state.user,
        profileBackground: action.profileBackground,
      };

      return {
        ...state,
        user: tempUser,
        loading: false,
      };

    case actionTypes.UPDATE_PROFILEPBACKGROUND_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
