import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import bannerPosterReducer from './store/reducers/banner-poster';
import authReducer from './store/reducers/auth';
import createPostReducer from './store/reducers/create-post';
import postsReducer from './store/reducers/posts';
import businessesReducer from './store/reducers/businesses';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const rootReducer = combineReducers({
    auth: authReducer,
    post: createPostReducer,
    posts: postsReducer,
    bannerPoster: bannerPosterReducer,
    businesses: businessesReducer
});

const logger = store => {

    return next => {
        return action => {
            console.log('Middleware dispatching - ', action);
            const result = next(action);
            console.log("Middleware next state - ", store.getState());
            return result;
        }
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
