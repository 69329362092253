import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios, { IMAGE_URL } from "../../axios";
import { Redirect } from "react-router-dom";
import Nav from "../../components/Nav/Nav";
import NavNotSignedIn from "../../components/NavNotSignedIn/NavNotSignedIn";

const RazorPayPayment = (props) => {
  const [profilePaymentCompleted, setProfilePaymentCompleted] = useState(false);
  const [signupPaymentCompleted, setSignupPaymentCompleted] = useState(false);
  const [pamentModalClosed, setPaymentModalClosed] = useState(false);
  const [paymentText, setPaymentText] = useState("");

  var options = {
    key: "rzp_live_Ju54JRk3uhi010", //"rzp_test_cEKCH8cPtAtDXP", // Enter the Key ID generated from the Dashboard
    amount: props.location.state.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise or INR 500.
    currency: "INR",
    name: "Bongkonect",
    description: "Bengali social media, classified and networking platform.",
    image: IMAGE_URL + "images/logo-white-bg.png",
    order_id: props.location.state.order.id, //This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
    handler: function (response) {
      switch (props.location.state.type) {
        case "profile":
          setPaymentText(
            "Launch Offer !!! Upload your profile at just Rupees 0/- one-time. Thank you."
          );
          axios
            .get("postProfilePaymentComplete", {
              params: {
                email: props.user.email,
                profileId: props.location.state.profile._id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
            })
            .then((res) => setProfilePaymentCompleted(true))
            .catch((err) => console.log(err));

          break;

        case "job":
          setPaymentText(
            "Launch Offer !!! Upload your Vacancy poster for just Rupees 99/-. Thank you."
          );
          axios
            .get("postJobPaymentComplete", {
              params: {
                email: props.user.email,
                jobId: props.location.state.job._id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
            })
            .then((res) => setProfilePaymentCompleted(true))
            .catch((err) => console.log(err));

          break;

        case "signup":
          setPaymentText(
            "Launch Offer !!! Get your business listed at just Rupees 599/- annually. Thank you."
          );
          axios
            .get("signupPaymentComplete", {
              params: {
                email: props.user.email,
                userId: props.user._id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
            })
            .then((res) => setSignupPaymentCompleted(true))
            .catch((err) => console.log(err));

          break;

        case "business":
          setPaymentText(
            "Launch Offer !!! Get your business listed at just Rupees 599/- annually. Thank you."
          );
          axios
            .get("listBusinessPaymentComplete", {
              params: {
                email: props.user.email,
                businessBannerId: props.location.state.businesBannerId,
                businessPosterId: props.location.state.businesPosterId,
                businessId: props.location.state.businesId,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
            })
            .then((res) => setProfilePaymentCompleted(true))
            .catch((err) => console.log(err));

          break;

        case "video":
          setPaymentText(
            "Launch Offer !!! Upload your video advertisement for Rupees 3999/-. Thank you."
          );
          axios
            .get("postVideoPaymentComplete", {
              params: {
                email: props.user.email,
                videoId: props.location.state.videoId,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
            })
            .then((res) => setProfilePaymentCompleted(true))
            .catch((err) => console.log(err));

          break;

        case "poster":
          setPaymentText(
            "Launch Offer !!! Upload your movie/music premiere for Rupees 1999/-. Thank you."
          );
          axios
            .get("postPosterPaymentComplete", {
              params: {
                email: props.user.email,
                posterId: props.location.state.posterId,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              },
            })
            .then((res) => setProfilePaymentCompleted(true))
            .catch((err) => console.log(err));

          break;
      }
    },
    prefill: {
      name: props.user.firstName + " " + props.user.lastName,
      email: props.user.email,
      contact: props.user.mobile,
    },
    theme: {
      color: "#F37254",
    },
    modal: {
      ondismiss: function () {
        setPaymentModalClosed(true);
      },
    },
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    switch (props.location.state.type) {
      case "profile":
        setPaymentText(
          "Launch Offer !!! Upload your profile at just Rupees 1/- one-time. Thank you."
        );
        break;

      case "job":
        setPaymentText(
          "Launch Offer !!! Upload your Vacancy poster for just Rupees 99/-. Thank you."
        );
        break;

      case "signup":
        setPaymentText(
          "Launch Offer !!! Get your business listed at just Rupees 599/- annually. Thank you."
        );
        break;

      case "business":
        setPaymentText(
          "Launch Offer !!! Get your business listed at just Rupees 599/- annually. Thank you."
        );
        break;

      case "video":
        setPaymentText(
          "Launch Offer !!! Upload your video advertisement for Rupees 3999/-. Thank you."
        );
        break;

      case "poster":
        setPaymentText(
          "Launch Offer !!! Upload your movie/music premiere for Rupees 1999/-. Thank you."
        );
        break;
    }
  }, []);

  const openPaymentDialog = () => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fe0000",
          height: "100vh",
          position: "relative",
        }}
      >
        {props.isAuthenticated ? (
          <Nav location={props.location} />
        ) : (
          <NavNotSignedIn location={props.location} />
        )}

        {profilePaymentCompleted && <Redirect to="/profile" />}
        {signupPaymentCompleted || (pamentModalClosed && <Redirect to="/" />)}
        {pamentModalClosed && <Redirect to="/" />}

        <div
          style={{
            top: "45%",
            position: "relative",
            margin: "0",
            msTransform: "translateY(-50%)",
            transform: "translateY(-50%)",
          }}
        >
          <div className="container">
            <div className="card">
              <div className="card-content">
                <div className="container">
                  <div className="row">
                    <div className="col s12">
                      <p style={{ textAlign: "center", fontSize: "1.2em" }}>
                        {paymentText}
                      </p>
                      <br />
                      <br />

                      <div
                        onClick={() => openPaymentDialog()}
                        style={{
                          background: "red",
                          height: "50px",
                          textAlign: "center",
                          color: "white",
                          fontSize: "1.5em",
                          fontWeight: "bold",
                          paddingTop: "8px",
                          cursor: "pointer",
                        }}
                      >
                        Pay Now!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated:
      state.auth.isAuthenticatedIndividual ||
      state.auth.isAuthenticatedBusiness,
  };
};

export default connect(mapStateToProps)(RazorPayPayment);
