import React from "react";
import MyNav from "../../components/Nav/Nav";
import Post from "../../components/Post/Post";
import CreatePost from "../../components/CreatePost/CreatePost";
import { connect } from "react-redux";
import Poster from "../../components/Poster/Poster";
import { Helmet } from "react-helmet";
import axios from "../../axios";
import { isMobile } from "react-device-detect";

class Posts extends React.Component {
  leftPostersInterval;
  rightPostersInterval;

  state = {
    posts: [],
    leftPosters: [],
    rightPosters: [],
    loading: false,
  };

  componentDidMount() {
    this.fetchPosts();
    // if (!isMobile) {
    //     this.loadLeftPosters();
    //     this.loadRightPosters();

    //     this.leftPostersInterval = setInterval(
    //         () => this.loadLeftPosters(),
    //         10000
    //     )

    //     this.rightPostersInterval = setInterval(
    //         () => this.loadRightPosters(),
    //         10000
    //     )
    // }
  }

  componentWillUnmount() {
    if (!isMobile) {
      clearInterval(this.leftPostersInterval);
      clearInterval(this.rightPostersInterval);
    }
  }

  loadLeftPosters = () => {
    axios
      .get("getPosterBusiness", { params: { size: 8 } })
      .then((response) => {
        this.setState({
          leftPosters: response.data.posts,
        });
      })
      .catch((err) => {});
  };

  loadRightPosters = () => {
    axios
      .get("getPosterBusiness", { params: { size: 8 } })
      .then((response) => {
        this.setState({
          rightPosters: response.data.posts,
        });
      })
      .catch((err) => {});
  };

  fetchPosts = () => {
    this.setState({ loading: true, posts: [] }, () => {
      axios
        .get("getPosts")
        .then((response) => {
          this.setState({ posts: response.data.posts, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    });
  };

  render() {
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Bongkonect - konect your social business and professional community
          </title>
          <meta
            name="description"
            content="Bongkonect - konect your social business and professional community"
          />
          <meta
            name="keywords"
            content="Bengali saree, Bengali misti, bangali cinema, bangla chalochitro, bangla gaan, bangla online class, bangla tuition"
          />
        </Helmet>

        <MyNav location={this.props.location} />
        <br />
        <div className="content">
          <div className="row">
            <div className="col s3 hide-on-med-and-down">
              {/* {
                                this.state.leftPosters.map((poster) => {

                                    return (
                                        <Poster key={'leftposter-' + Math.random()} poster={poster} float='right' />
                                    )
                                })
                            } */}
            </div>

            <div className="col s12 l6 xl6">
              <CreatePost fetchPosts={this.fetchPosts} />

              <br />
              <React.Fragment>
                {this.state.posts.map((post) => {
                  return (
                    <Post
                      post={post}
                      key={post.post._id}
                      fetchPosts={this.fetchPosts}
                    />
                  );
                })}
              </React.Fragment>
            </div>

            <div className="col s3 hide-on-med-and-down">
              {/* {
                                this.state.rightPosters.map((poster) => {
                                    return (
                                        <Poster key={'rightposter-' + Math.random()} poster={poster} float='left' />
                                    )
                                })
                            } */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(Posts);
