import * as actionTypes from "./actionsTypes";
import axios from "../../axios";

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

export const loginSuccess = (loginData) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: { loginData },
  };
};

export const loginFailed = (error, order = null, user = null) => {
  return {
    type: actionTypes.LOGIN_FAILED,
    payload: { error, order, user },
  };
};

export const authenticateIndividual = (data) => {
  return {
    type: actionTypes.AUTHENTICATE_INDIVIDUAL,
    data,
  };
};

export const authenticateBusiness = (data) => {
  return {
    type: actionTypes.AUTHENTICATE_BUSINESS,
    data,
  };
};

export const signupStart = () => {
  return {
    type: actionTypes.SIGNUP_START,
  };
};

export const signupSuccess = (data) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    payload: { data },
  };
};

export const signupFailed = (error) => {
  return {
    type: actionTypes.SIGNUP_FAILED,
    payload: { error },
  };
};

export const updateProfileStart = () => {
  return {
    type: actionTypes.UPDATE_PROFILE_START,
  };
};

export const updateProfileSuccess = (user) => {
  return {
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    user,
  };
};

export const updateProfileFailed = () => {
  return {
    type: actionTypes.UPDATE_PROFILE_FAILED,
  };
};

export const updateProfilepicStart = () => {
  return {
    type: actionTypes.UPDATE_PROFILEPIC_START,
  };
};

export const updateProfilepicSuccess = (profilePic) => {
  return {
    type: actionTypes.UPDATE_PROFILEPIC_SUCCESS,
    profilePic,
  };
};

export const updateProfilepicFailed = () => {
  return {
    type: actionTypes.UPDATE_PROFILEPIC_FAILED,
  };
};

export const updateProfilebackgroundStart = () => {
  return {
    type: actionTypes.UPDATE_PROFILEBACKGROUND_START,
  };
};

export const updateProfilebackgroundSuccess = (profileBackground) => {
  return {
    type: actionTypes.UPDATE_PROFILEBACKGROUND_SUCCESS,
    profileBackground,
  };
};

export const updateProfilebackgroundFailed = () => {
  return {
    type: actionTypes.UPDATE_PROFILEPBACKGROUND_FAILED,
  };
};

export const updateProfile = (
  firstName,
  lastName,
  about,
  mobile,
  city,
  pincode
) => {
  return (dispatch) => {
    dispatch(updateProfileStart());
    const data = { firstName, lastName, about, mobile, city, pincode };
    axios
      .post("updateProfile", data)
      .then((response) => dispatch(updateProfileSuccess(response.data.user)))
      .catch((err) => console.log(err));
  };
};

export const updateProfilePic = (profilePic) => {
  return (dispatch) => {
    dispatch(updateProfilepicStart());
    const data = new FormData();
    data.append("profilePic", profilePic);

    axios
      .post("updateProfilePic", data)
      .then((response) => dispatch(updateProfilepicSuccess(response.data)))
      .catch((err) => dispatch(updateProfilepicFailed()));
  };
};

export const updateProfileBackground = (profileBackground) => {
  return (dispatch) => {
    dispatch(updateProfilebackgroundStart());

    axios
      .post("updateProfileBackground", { profileBackground })
      .then((response) =>
        dispatch(updateProfilebackgroundSuccess(response.data))
      )
      .catch((err) => dispatch(updateProfilebackgroundFailed()));
  };
};

export const login = (email, password, ownProps) => {
  return (dispatch) => {
    dispatch(loginStart());
    const data = { email: email, password: password };

    axios
      .post("login", data)
      .then((response) => {
        sessionStorage.setItem("userId", response.data.userId);
        if (response.data.user.userType === "individual") {
          if (response.data.user.emailVerified) {
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("userId", response.data.userId);
            dispatch(loginSuccess(response.data));
            dispatch(
              authenticateIndividual({
                token: response.data.token,
                userId: response.data.userId,
                user: response.data.user,
              })
            );
            //ownProps.history.push('/main');
          } else {
            dispatch(
              loginFailed(
                "Email not verified. A verification link has been sent to your email. Please check your mail."
              )
            );
          }
        } else {
          if (response.data.user.paymentCompleted) {
            if (response.data.user.emailVerified) {
              sessionStorage.setItem("token", response.data.token);
              sessionStorage.setItem("userId", response.data.userId);
              sessionStorage.setItem("user", response.data.user);
              dispatch(loginSuccess(response.data));
              dispatch(
                authenticateBusiness({
                  token: response.data.token,
                  userId: response.data.userId,
                  user: response.data.user,
                })
              );
              //ownProps.history.push('/main');
            } else {
              dispatch(
                loginFailed(
                  "Email not verified. A verification link has been sent to your email. Please check your mail."
                )
              );
            }
          } else {
            dispatch(
              loginFailed(
                `Payment not completed`,
                response.data.order,
                response.data.user
              )
            );
          }
        }
      })
      .catch(function (error) {
        dispatch(loginFailed("Invalid Email or Password"));
      });
  };
};

export const signupIndividual = (
  ownProps,
  userType,
  firstName,
  lastName,
  about,
  profilePic,
  email,
  mobile,
  countryCode,
  password,
  country,
  pincode,
  city
) => {
  return (dispatch) => {
    dispatch(signupStart());
    let data = new FormData();
    data.append("userType", userType);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("about", about);
    data.append("profilePic", profilePic);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("countryCode", countryCode);
    data.append("password", password);
    data.append("country", country);
    data.append("pincode", pincode);
    data.append("city", city);

    axios
      .post("signupIndividual", data)
      .then((response) => {
        alert(
          "A verification link has been sent to your email. Please check your mail to activate your account."
        );
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("userId", response.data.userId);
        dispatch(signupSuccess(response.data));
        ownProps.history.push("/login");
      })
      .catch(function (error) {
        dispatch(signupFailed("Someone's already using this email"));
      });
  };
};

export const signupBusiness = (
  ownProps,
  userType,
  firstName,
  lastName,
  about,
  profilePic,
  email,
  mobile,
  countryCode,
  password,
  country,
  pincode,
  city,
  category,
  businessName,
  businessMobile,
  postType,
  imageId,
  description,
  userImage,
  descriptionBanner,
  descriptionPoster,
  userImageBanner,
  userImagePoster,
  bannerImageId,
  posterImageId
) => {
  return (dispatch) => {
    dispatch(signupStart());

    const cityUppercase = city.charAt(0).toUpperCase() + city.slice(1);

    let data = new FormData();
    data.append("userType", userType);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("about", about);
    data.append("profilePic", profilePic);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("countryCode", countryCode);
    data.append("password", password);
    data.append("country", country);
    data.append("pincode", pincode);
    data.append("city", cityUppercase);
    data.append("category", category);
    data.append("businessName", businessName);
    data.append("businessMobile", businessMobile);
    data.append("postType", postType);
    data.append("description", description);
    data.append("imageId", imageId);
    data.append("userImage", userImage);

    data.append("descriptionBanner", descriptionBanner);
    data.append("descriptionPoster", descriptionPoster);
    data.append("userImageBanner", userImageBanner);
    data.append("userImagePoster", userImagePoster);
    data.append("bannerImageId", bannerImageId);
    data.append("posterImageId", posterImageId);

    axios
      .post("signupBusiness", data)
      .then((response) => {
        alert(
          "A verification link has been sent to your email. Please check your mail to activate your account."
        );
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("userId", response.data.userId);
        dispatch(signupSuccess(response.data));
        //ownProps.history.push('/payment?type=signup');
      })
      .catch(function (error) {
        dispatch(signupFailed("Someone's already using this email"));
      });
  };
};

export const performLogout = () => {
  sessionStorage.removeItem("token");
  return {
    type: actionTypes.LOGOUT,
  };
};

export const logout = (ownProps) => {
  return (dispatch) => {
    dispatch(performLogout());
  };
};

export const checkAuthState = (ownProps) => {
  return (dispatch) => {
    const token = sessionStorage.getItem("token");
    const userId = sessionStorage.getItem("userId");
    if (!token || !userId) {
      dispatch(logout(ownProps));
    } else {
      axios
        .get("getUserDetails")
        .then((response) => {
          if (response.data.user) {
            if (
              response.data.user.emailVerified &&
              response.data.user.userType === "individual"
            )
              dispatch(
                authenticateIndividual({ ...response.data, token, userId })
              );
            else if (
              response.data.user.emailVerified &&
              response.data.user.paymentCompleted &&
              response.data.user.userType === "business"
            )
              dispatch(
                authenticateBusiness({ ...response.data, token, userId })
              );
            else dispatch(logout(ownProps));
          } else {
            dispatch(logout(ownProps));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
};
