import React, { useState, useEffect } from "react";
import { IMAGE_URL } from "../../axios";
import M from "materialize-css";
import "react-image-gallery/styles/css/image-gallery.css";
import axios from "../../axios";
import "./Post.css";
import { connect } from "react-redux";
import Linkify from "react-linkify";
import Avatar from "react-avatar";
import { Redirect } from "react-router-dom";
import { Player, BigPlayButton } from "video-react";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import ImageGallery from "react-image-gallery";
import {
  LinkedinShareButton,
  TwitterShareButton,
  TumblrShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterIcon,
  LinkedinIcon,
  TumblrIcon,
  PinterestIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { SITE_BASE_URL } from "../../axios";

const Post = (props) => {
  const profilePic =
    props.post.user.profilePic && props.post.user.profilePic !== ""
      ? IMAGE_URL + props.post.user.profilePic
      : null;
  const [isLiked, setLiked] = useState(props.post.post.likedByMe);
  const [isSatisfied, setSatisfied] = useState(props.post.post.satisfied);
  const [isUnsatisfied, setUnsatisfied] = useState(props.post.post.unsatisfied);
  const [isFavorite, setFavorite] = useState(props.post.post.myfavorite);
  const [isShareVisible, setShareVisible] = useState(false);
  const [likeRedirect, setLikeRedirect] = useState(false);
  const [satisfiedRedirect, setSatisfiedRedirect] = useState(false);
  const [unsatisfiedRedirect, setUnsatisfiedRedirect] = useState(false);
  const [favoriteRedirect, setFavoriteRedirect] = useState(false);
  const [media, setMedia] = useState([]);
  const likeIconColor = isLiked ? "skyblue" : "white";
  const satisfiedColor = isSatisfied ? "#f5c33a" : "white";
  const unsatisfiedColor = isUnsatisfied ? "#f5c33a" : "white";
  const favoriteColor = isFavorite ? "red" : "white";
  const shareIconColor = isShareVisible ? "black" : "white";

  const shareUrl = `${SITE_BASE_URL}post/${props.post.post._id}`;
  const title = "Bongkonect";

  const currDate = new Date().getTime();
  const postDate = new Date(props.post.post.createdOn).getTime();
  var msec = currDate - postDate;
  var mins = Math.abs(Math.round(msec / 60000));
  var hrs = Math.floor(mins / 60);
  let timeString = "";
  if (mins <= 60) timeString = mins + " minutes ago";
  else timeString = hrs + " hours ago";

  useEffect(() => {
    M.AutoInit();
    let post_media = [];
    props.post.post.photos.map((content) => {
      let option = {};
      if (content.mediaType === "video/mp4") {
        option = {
          original: "",
          renderItem: () => {
            return (
              <div className="card-image">
                {isMobile ? (
                  <video width="300" controls>
                    <source src={IMAGE_URL + content.name} type="video/mp4" />
                  </video>
                ) : (
                  <video width="580" controls>
                    <source src={IMAGE_URL + content.name} type="video/mp4" />
                  </video>
                )}
              </div>
            );
          },
        };
      } else {
        option = { original: `${IMAGE_URL + content.name}` };
      }
      post_media.push(option);
    });
    setMedia(post_media);
  }, []);

  const deletePost = () => {
    const postId = props.post.post._id;
    axios
      .post("deletePost", { postId })
      .then((response) => props.fetchPosts())
      .catch((error) => console.log(error));
  };

  const onLikeClick = () => {
    if (props.isAuthenticated) {
      setLikeRedirect(false);
      if (isLiked && props.post.post.likeCount > 0) {
        setLiked(false);
        props.post.post.likeCount = props.post.post.likeCount - 1;
        axios
          .post("unlikePost", { postId: props.post.post._id })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error.data));
      } else {
        setLiked(true);
        if (isSatisfied) {
          setSatisfied(false);
          props.post.post.satisfiedCount = props.post.post.satisfiedCount - 1;
        }
        if (isFavorite) {
          setFavorite(false);
          props.post.post.favoriteCount = props.post.post.favoriteCount - 1;
        }
        if (isUnsatisfied) {
          setUnsatisfied(false);
          props.post.post.unsatisfiedCount =
            props.post.post.unsatisfiedCount - 1;
        }
        props.post.post.likeCount = props.post.post.likeCount + 1;
        axios
          .post("likePost", { postId: props.post.post._id })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error.data));
      }
    } else {
      setLikeRedirect(true);
    }
  };

  const onfavoriteClick = () => {
    if (props.isAuthenticated) {
      setFavoriteRedirect(false);
      if (isFavorite && props.post.post.favoriteCount > 0) {
        setFavorite(false);
        props.post.post.favoriteCount = props.post.post.favoriteCount - 1;
        axios
          .post("removefavorite", { postId: props.post.post._id })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error.data));
      } else {
        setFavorite(true);
        if (isLiked) {
          setLiked(false);
          props.post.post.likeCount = props.post.post.likeCount - 1;
        }
        if (isSatisfied) {
          setSatisfied(false);
          props.post.post.satisfiedCount = props.post.post.satisfiedCount - 1;
        }
        if (isUnsatisfied) {
          setUnsatisfied(false);
          props.post.post.unsatisfiedCount =
            props.post.post.unsatisfiedCount - 1;
        }
        props.post.post.favoriteCount = props.post.post.favoriteCount + 1;
        axios
          .post("favoritePost", { postId: props.post.post._id })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error.data));
      }
    } else {
      setFavoriteRedirect(true);
    }
  };

  const onsatisfiedClick = () => {
    if (props.isAuthenticated) {
      setSatisfiedRedirect(false);
      if (isSatisfied && props.post.post.satisfiedCount > 0) {
        setSatisfied(false);
        props.post.post.satisfiedCount = props.post.post.satisfiedCount - 1;
        axios
          .post("removesatisfy", { postId: props.post.post._id })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error.data));
      } else {
        setSatisfied(true);
        if (isLiked) {
          setLiked(false);
          props.post.post.likeCount = props.post.post.likeCount - 1;
        }
        if (isFavorite) {
          setFavorite(false);
          props.post.post.favoriteCount = props.post.post.favoriteCount - 1;
        }
        if (isUnsatisfied) {
          setUnsatisfied(false);
          props.post.post.unsatisfiedCount =
            props.post.post.unsatisfiedCount - 1;
        }
        props.post.post.satisfiedCount = props.post.post.satisfiedCount + 1;
        axios
          .post("satisfiedPost", { postId: props.post.post._id })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error.data));
      }
    } else {
      setSatisfiedRedirect(true);
    }
  };

  const onunsatisfiedClick = () => {
    if (props.isAuthenticated) {
      setUnsatisfiedRedirect(false);
      if (isUnsatisfied && props.post.post.unsatisfiedCount > 0) {
        setUnsatisfied(false);
        props.post.post.unsatisfiedCount = props.post.post.unsatisfiedCount - 1;
        axios
          .post("removeunsatisfy", { postId: props.post.post._id })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error.data));
      } else {
        setUnsatisfied(true);
        if (isLiked) {
          setLiked(false);
          props.post.post.likeCount = props.post.post.likeCount - 1;
        }
        if (isFavorite) {
          setFavorite(false);
          props.post.post.favoriteCount = props.post.post.favoriteCount - 1;
        }
        if (isSatisfied) {
          setSatisfied(false);
          props.post.post.satisfiedCount = props.post.post.satisfiedCount - 1;
        }
        props.post.post.unsatisfiedCount = props.post.post.unsatisfiedCount + 1;
        axios
          .post("unsatisfiedPost", { postId: props.post.post._id })
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error.data));
      }
    } else {
      setUnsatisfiedRedirect(true);
    }
  };

  const onShareClick = () => {
    if (isShareVisible) {
      setShareVisible(false);
    } else {
      setShareVisible(true);
    }
  };

  return (
    <div>
      {likeRedirect ||
      favoriteRedirect ||
      satisfiedRedirect ||
      unsatisfiedRedirect ? (
        <Redirect to="/login" />
      ) : null}
      <div className="card" style={{ width: "100%" }}>
        <div className="card-content">
          <div className="row">
            <Avatar
              name={props.post.user.firstName + " " + props.post.user.lastName}
              round
              src={profilePic}
              size="50px"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            />
            {props.userId === props.post.post.userId ? (
              <i
                className="material-icons right"
                onClick={(e) => deletePost(e)}
                style={{ cursor: "pointer", color: "black" }}
              >
                delete_forever
              </i>
            ) : null}

            <div
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: "20px",
              }}
            >
              <h6 style={{ fontWeight: "bold" }}>
                {props.post.user.firstName + " " + props.post.user.lastName}
              </h6>
              {props.post.user.businesses &&
                props.post.user.businesses.length > 0 && (
                  <div>
                    <strong style={{ fontSize: "10px", color: "grey" }}>
                      Business registered
                    </strong>
                    <br />
                  </div>
                )}

              {props.post.user.about !== "" ? (
                <strong style={{ fontSize: "10px", color: "grey" }}>
                  {props.post.user.about +
                    " | " +
                    props.post.user.city +
                    " | " +
                    props.post.user.country}
                </strong>
              ) : (
                <strong style={{ fontSize: "10px", color: "grey" }}>
                  {props.post.user.city + " | " + props.post.user.country}
                </strong>
              )}
              <br />
              <strong style={{ fontSize: "10px", color: "grey" }}>
                {timeString}
              </strong>
            </div>
          </div>

          <div className="row">
            <Linkify>
              <p
                style={{
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                  margin: "25px",
                }}
              >
                {props.post.post.description}
              </p>
            </Linkify>
          </div>

          <div className="">
            {props.post.post.photos.length != 0 ? (
              props.post.post.photos.length === 1 ? (
                <div className="row" key={props.post.post.photos[0].name}>
                  <div className="col s12">
                    <div className="card-image">
                      {props.post.post.photos[0].mediaType == "video/mp4" ? (
                        <Player
                          playsInline
                          src={IMAGE_URL + props.post.post.photos[0].name}
                        >
                          <BigPlayButton position="center" />
                        </Player>
                      ) : (
                        <img
                          className="materialboxed responsive-img"
                          src={IMAGE_URL + props.post.post.photos[0].name}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row" key="slider">
                  <div className="col s12">
                    <ImageGallery
                      items={media}
                      infinite={false}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showThumbnails={false}
                      showBullets={true}
                      showNav={false}
                    />
                  </div>
                </div>
              )
            ) : (
              <></>
            )}
          </div>

          <div className="card-action">
            <div className="row">
              <div className="col s2">
                <div
                  style={{ cursor: "pointer", display: "inline-block" }}
                  onClick={(e) => onLikeClick(e)}
                >
                  <sub
                    className={
                      props.post.post.likeCount !== 0 && !isMobile
                        ? null
                        : "hide"
                    }
                  >
                    {props.post.post.likeCount}
                  </sub>{" "}
                  <button
                    className="btn-floating waves-effect waves-light"
                    disabled
                  >
                    <i
                      className="material-icons"
                      style={{ color: likeIconColor }}
                    >
                      thumb_up
                    </i>
                  </button>
                </div>
              </div>
              <div className="col s2">
                <div
                  style={{ cursor: "pointer", display: "inline-block" }}
                  onClick={(e) => onfavoriteClick(e)}
                >
                  <sub
                    className={
                      props.post.post.favoriteCount !== 0 && !isMobile
                        ? null
                        : "hide"
                    }
                  >
                    {props.post.post.favoriteCount}
                  </sub>{" "}
                  <button
                    className="btn-floating waves-effect waves-light"
                    disabled
                  >
                    <FontAwesomeIcon
                      style={{ color: favoriteColor, fontSize: "18px" }}
                      icon={faHeart}
                    />
                  </button>
                </div>
              </div>
              <div className="col s2">
                <div
                  style={{ cursor: "pointer", display: "inline-block" }}
                  onClick={(e) => onsatisfiedClick(e)}
                >
                  <sub
                    className={
                      props.post.post.satisfiedCount !== 0 && !isMobile
                        ? null
                        : "hide"
                    }
                  >
                    {props.post.post.satisfiedCount}
                  </sub>{" "}
                  <button
                    className="btn-floating waves-effect waves-light"
                    disabled
                  >
                    <i
                      className="material-icons"
                      style={{ color: satisfiedColor }}
                    >
                      sentiment_very_satisfied
                    </i>
                  </button>
                </div>
              </div>
              <div className="col s2">
                <div
                  style={{ cursor: "pointer", display: "inline-block" }}
                  onClick={(e) => onunsatisfiedClick(e)}
                >
                  <sub
                    className={
                      props.post.post.unsatisfiedCount !== 0 && !isMobile
                        ? null
                        : "hide"
                    }
                  >
                    {props.post.post.unsatisfiedCount}
                  </sub>{" "}
                  <button
                    className="btn-floating waves-effect waves-light"
                    disabled
                  >
                    <i
                      className="material-icons"
                      style={{ color: unsatisfiedColor }}
                    >
                      sentiment_very_dissatisfied
                    </i>
                  </button>
                </div>
              </div>
              <div className="col s2">
                <div
                  style={{ cursor: "pointer", display: "inline-block" }}
                  onClick={(e) => onShareClick(e)}
                >
                  <button
                    className="btn-floating waves-effect waves-light"
                    disabled
                  >
                    <i
                      className="material-icons"
                      style={{ color: shareIconColor }}
                    >
                      share
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="row"
              className={!isShareVisible && isMobile ? null : "hide"}
              style={{ marginTop: "-20px" }}
            >
              <div className="col s2">
                {props.post.post.likeCount !== 0 ? (
                  <div style={{ display: "inline-block", marginLeft: "4px" }}>
                    <strong style={{}}>{props.post.post.likeCount}</strong>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="col s2">
                {props.post.post.favoriteCount !== 0 ? (
                  <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    <strong style={{}}>{props.post.post.favoriteCount}</strong>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="col s2">
                {props.post.post.satisfiedCount !== 0 ? (
                  <div style={{ display: "inline-block", marginLeft: "13px" }}>
                    <strong style={{}}>{props.post.post.satisfiedCount}</strong>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="col s2">
                {props.post.post.unsatisfiedCount !== 0 ? (
                  <div style={{ display: "inline-block", marginLeft: "15px" }}>
                    <strong style={{}}>
                      {props.post.post.unsatisfiedCount}
                    </strong>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div
              className={isShareVisible ? null : "hide"}
              style={{ textAlign: "center" }}
            >
              {/* <div className="Demo__some-network">
                <FacebookShareButton
                  url={shareUrl}
                  quote={title}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </div> */}

              <div className="Demo__some-network">
                <TwitterShareButton
                  url={shareUrl}
                  title={title}
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>

              <div className="Demo__some-network">
                <TumblrShareButton
                  url={shareUrl}
                  title={title}
                  className="Demo__some-network__share-button"
                >
                  <TumblrIcon size={32} round />
                </TumblrShareButton>
              </div>

              {props.post.post.photos.length != 0 &&
              props.post.post.photos[0].mediaType != "video/mp4" ? (
                <div className="Demo__some-network">
                  <PinterestShareButton
                    url={shareUrl}
                    media={IMAGE_URL + props.post.post.photos[0].name}
                    title={title}
                    className="Demo__some-network__share-button"
                  >
                    <PinterestIcon size={32} round />
                  </PinterestShareButton>
                </div>
              ) : (
                <></>
              )}

              <div className="Demo__some-network">
                <WhatsappShareButton
                  url={shareUrl}
                  title={title}
                  separator=":: "
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>

              <div className="Demo__some-network">
                <LinkedinShareButton
                  url={shareUrl}
                  windowWidth={750}
                  windowHeight={600}
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>

              <div className="Demo__some-network">
                <EmailShareButton
                  url={shareUrl}
                  subject={title}
                  body="body"
                  className="Demo__some-network__share-button"
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    loading: state.posts.loading,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(Post);
