import React, { useEffect, useState } from "react";
import MyNav from "../../components/Nav/Nav";
import Post from "../../components/Post/Post";
import { connect } from "react-redux";
import { fetchPosts } from "../../store/actions/index";
import axios from "../../axios";
import { Helmet } from "react-helmet";
import { SITE_BASE_URL, IMAGE_URL } from "../../axios";

const SinglePost = (props) => {
  const postId = props.match.params.id;
  const shareUrl = `${SITE_BASE_URL}post/${postId}`;
  const title = "Bongkonect";
  const description =
    "Bongkonect - konect your social business and professional community";
  const image =
    "https://bongkonect-bucket.s3.ap-south-1.amazonaws.com/logo-red-bg.png";
  const [post, setPost] = useState(null);

  //IMAGE_URL + post?.post?.photos[0]?.name

  useEffect(() => {
    axios
      .post("getSinglePost", { postId })
      .then((response) => {
        if (response.data === "Link expired") {
        } else {
          setPost(response.data);
        }
      })
      .catch((err) => console.log(err.data));
  }, []);

  if (!post) return null;
  else
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        {/* <Helmet>
          <title data-react-helmet="true">{title}</title>
          <meta name="title" data-react-helmet="true" content={title} />
          <meta
            name="description"
            data-react-helmet="true"
            content={post?.post?.description || description}
          />

          <meta
            name="image"
            data-react-helmet="true"
            content={IMAGE_URL + post?.post?.photos[0]?.name || image}
          />

          <meta property="og:type" data-react-helmet="true" content="website" />
          <meta property="og:url" data-react-helmet="true" content={shareUrl} />
          <meta property="og:title" data-react-helmet="true" content={title} />
          <meta
            property="og:description"
            data-react-helmet="true"
            content={post?.post?.description || description}
          />
          <meta
            property="og:image"
            data-react-helmet="true"
            content={IMAGE_URL + post?.post?.photos[0]?.name || image}
          />

          <meta
            property="og:image:secure_url"
            data-react-helmet="true"
            content={IMAGE_URL + post?.post?.photos[0]?.name || image}
          />

          <meta
            property="og:image:width"
            data-react-helmet="true"
            content="1200"
          />

          <meta
            property="og:image:width"
            data-react-helmet="true"
            content="630"
          />

          <meta
            property="twitter:card"
            data-react-helmet="true"
            content="summary_large_image"
          />
          <meta
            property="twitter:url"
            data-react-helmet="true"
            content={shareUrl}
          />
          <meta
            property="twitter:title"
            data-react-helmet="true"
            content={title}
          />
          <meta
            property="twitter:description"
            data-react-helmet="true"
            content={post?.post?.description || description}
          />
          <meta
            property="twitter:image"
            data-react-helmet="true"
            content={IMAGE_URL + post?.post?.photos[0]?.name || image}
          />
        </Helmet> */}
        <MyNav location={props.location} />
        <br />
        <br />
        <div className="content">
          <div className="row">
            <div className="col s3 hide-on-med-and-down"></div>

            <div className="col s12 l6 xl6">
              {post ? <Post post={post} /> : null}
            </div>

            <div className="col s3 hide-on-med-and-down"></div>
          </div>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => {
  return {
    posts: state.posts.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPosts: () => dispatch(fetchPosts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SinglePost);
