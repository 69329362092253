import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    bannerId: "",
    bannerImageSrc: "",
    bannerDescription: "",
    bannerSelected: false,
    posterId: "",
    posterImageSrc: "",
    posterDescription: "",
    posterSelected: false,
    userImageBanner: null,
    userImagePoster: null
}

const bannerPosterReducer = (state = initialState, action) => {

    if (action.type === actionTypes.BANNERSUBMIT) {
        return {
            ...state,
            bannerId: action.payload.bannerId,
            bannerImageSrc: action.payload.bannerImageSrc,
            bannerDescription: action.payload.bannerDescription,
            bannerSelected: true,
            userImageBanner: action.payload.userImage
        }
    }

    if (action.type === actionTypes.POSTERSUBMIT) {
        return {
            ...state,
            posterId: action.payload.posterId,
            posterImageSrc: action.payload.posterImageSrc,
            posterDescription: action.payload.posterDescription,
            posterSelected: true,
            userImagePoster: action.payload.userImage
        }
    }

    return state;
}

export default bannerPosterReducer;