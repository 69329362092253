import React, { useState, useEffect, useRef } from "react";
import MyNav from "../../components/Nav/Nav";
import axios from "../../axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ImageCrop from "../ImageCrop/ImageCrop";

const PostProfileForm = (props) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [skills, setSkills] = useState("");
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [profilePic, setProfilePic] = useState(null);
  const [termsChecked, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileColor, setProfileColor] = useState("grey");

  const [imageErrors, setImageErrors] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const [order, setOrder] = useState({});
  const [profile, setProfile] = useState({});

  const [showCrop, setShowCrop] = useState(false);
  const [file, setFile] = useState(null);

  const onFileChanged = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setShowCrop(true);
    }
  };

  const submitPostProfile = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append("photo", profilePic);
    data.append("email", email);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("skills", skills);
    data.append("experience", experience);
    data.append("location", location.toLowerCase());
    data.append("maritalStatus", maritalStatus);

    axios
      .post("postProfile", data)
      .then((response) => {
        setLoading(false);
        if (response.data.profileExists) {
          toast.error("A profile with this email already exists", {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setOrder(response.data.order);
          setProfile(response.data.profile);
          setShowPayment(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setShowPayment(false);
      });
  };

  const onFileChange = (e) => {
    setImageErrors("");

    if (e.target.files.length > 0) {
      var fileName, fileExtension;

      // FILE NAME AND EXTENSION.
      fileName = e.target.files[0].name;
      fileExtension = fileName.replace(/^.*\./, "");

      // CHECK IF ITS AN IMAGE FILE.
      // TO GET THE IMAGE WIDTH AND HEIGHT, WE'LL USE fileReader().
      if (
        fileExtension === "png" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg"
      ) {
        setFile(e.target.files[0]);
        setShowCrop(true);
        setProfileColor("green");
        // setProfilePic(e.target.files[0]); // GET IMAGE INFO USING fileReader().
      } else {
        // IF THE FILE IS NOT AN IMAGE.
        setImageErrors("Please select a valid image file");
        setProfilePic(null);
        setProfileColor("grey");
      }
    }
  };

  // function readImageFile(file) {
  //     var reader = new FileReader(); // CREATE AN NEW INSTANCE.

  //     reader.onload = function (e) {
  //         var img = new Image();
  //         img.src = e.target.result;

  //         img.onload = function () {
  //             var w = this.width;
  //             var h = this.height;

  //             if (w !== 300 && h !== 330) {
  //                 setImageErrors('Please select an image of mentioned dimensions')
  //                 setProfilePic(null);
  //                 setProfileColor('grey');
  //             }
  //         }
  //     };
  //     reader.readAsDataURL(file);
  // }

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <MyNav location={props.location} />
      <ImageCrop
        file={file}
        open={showCrop}
        setOpen={setShowCrop}
        updateFile={setProfilePic}
      />
      {loading ? (
        <div>
          <div className="progress">
            <div className="indeterminate"></div>
          </div>
        </div>
      ) : null}

      {showPayment && (
        <Redirect
          to={{
            pathname: "/payment",
            state: { order, profile, type: "profile" },
          }}
        />
      )}

      <div className="container">
        <div className="card">
          <div className="card-content">
            <h5>Post Profile</h5>
            <form
              encType="multipart/form-data"
              onSubmit={(e) => submitPostProfile(e)}
            >
              <div className="row">
                <div className="input-field col s6">
                  <input
                    id="email"
                    type="email"
                    className="validate"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                  <small className="helper-text">
                    A verification mail will be sent to this Email Id{" "}
                  </small>
                </div>
                <div className="col s6 file-field input-field">
                  <div
                    style={{
                      backgroundColor: "#eeeeee",
                      borderRadius: "20px",
                      padding: "5px 20px 5px 20px",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        color: profileColor,
                      }}
                    >
                      photo_library
                    </i>
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                      }}
                    >
                      Profile Pic
                    </span>
                    <input
                      type="file"
                      onChange={(e) => {
                        onFileChange(e);
                      }}
                      required
                    />
                    {profilePic && (
                      <i
                        className="material-icons right"
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          color: "green",
                        }}
                      >
                        check
                      </i>
                    )}
                  </div>
                  <br />
                  <small className="helper-text" style={{ marginLeft: "20px" }}>
                    Select an image having width 300px and height 330px{" "}
                  </small>
                  <small
                    className="helper-text"
                    style={{ marginLeft: "20px", color: "red" }}
                  >
                    {" "}
                    {imageErrors !== "" ? "* " + imageErrors : null}{" "}
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s6">
                  <input
                    id="firstName"
                    type="text"
                    className="validate"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <label htmlFor="firstName">First Name</label>
                </div>
                <div className="input-field col s6">
                  <input
                    id="lastName"
                    type="text"
                    className="validate"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                  <label htmlFor="lastName">Last Name</label>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s6">
                  <input
                    id="skills"
                    type="text"
                    className="validate"
                    maxLength="50"
                    placeholder="Java, Php ... / MBA etc"
                    value={skills}
                    onChange={(e) => setSkills(e.target.value)}
                    required
                  />
                  <label className="active" htmlFor="skills">
                    Skills/Qualification
                  </label>
                  <small className="helper-text right">
                    {skills.length + "/50"}
                  </small>
                </div>
                <div className="input-field col s6">
                  <select
                    className="browser-default"
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                  >
                    <option value="" disabled>
                      Experience
                    </option>
                    {new Array(31).fill().map((number, i) => {
                      return (
                        <option value={i + "yrs"} key={i}>
                          {i}yrs
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s6">
                  <input
                    id="location"
                    type="text"
                    className="validate"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    required
                  />
                  <label htmlFor="location">Location</label>
                </div>
                <div className="input-field col s6">
                  <select
                    value={maritalStatus}
                    onChange={(e) => setMaritalStatus(e.target.value)}
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                  </select>
                  <label>Marital Status</label>
                </div>
              </div>
              <div className="input-field">
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={termsChecked}
                      onChange={(e) => setTerms(e.target.checked)}
                      required
                    />
                    <span>
                      By clicking Register, you agree to the{" "}
                      <a
                        className="modal-trigger"
                        href="#postProfileGuidelinesModal"
                      >
                        Terms and Conditions
                      </a>{" "}
                      set out by this site.
                    </span>
                  </label>
                </p>
              </div>
              <button
                disabled={loading || profilePic === null}
                type="submit"
                style={{ backgroundColor: "#fe0000" }}
                className="waves-effect waves-light btn"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      <div id="postProfileGuidelinesModal" className="modal">
        <div className="modal-content">
          <h4 style={{ textAlign: "center" }}>
            Terms and Conditions for Job Sekers
          </h4>
          <div className="">
            <div className="row">
              <p>
                1.Introduction. Please read this page carefuly. It contains the
                terms and conditions (the "Terms and Conditions") governing your
                access to and use of the Bongkonect Web Sites and the Services
                (as each are defined below) provided by Bongkonect, or one of
                its divisions (colectively, "Bongkonect"). If you do not accept
                these Terms and Conditions or you do not meet or comply with
                their provisions, you may not use the Bongkonect Web Sites or
                Services which is specificaly customised for Job Sekers. These
                Terms and Conditions are efective as of December 21, 2019.
              </p>
            </div>

            <div className="row">
              <p>
                2.Binding Agreement. These Terms and Conditions (as they may be
                amended from time to time by Bongkonect), together with your
                Advertising and Candidate Search Service Agrement (if
                applicable), form a binding agreement (the "Agreement") between
                you and Bongkonect. Your access to or use of the Bongkonect WebS
                ites or Services indicates your aceptance of these Terms and
                Conditions. You are agreeing to use the Sites at your own risk.
              </p>
            </div>

            <div className="row">
              <p>
                3.Certain Definitions. The folowing definitions apply to this
                Agrement:
              </p>
            </div>

            <div className="row">
              <p>
                3.1 "Bongkonect Materials" includes any materials,
                methodologies, implementation plans or other intelectual
                property used during the provision of Services.
              </p>
            </div>

            <div className="row">
              <p>
                3.2"Bongkonect WebSite "orthe" Site "(colectively, the
                "Bongkonect Websites "orthe "Sites" ) means any website under
                Bongkonect 'scontrol, whether partial orotherwise and includes
                such Sites 'Content, Bongkonect Materials and Services (as
                applicable in each context).
              </p>
            </div>

            <div className="row">
              <p>
                3.3 "Content" means Bongkonect's web pages, webforms,
                programming (including software code used on the Sites and in
                the Services, including (i) tools, kits, and object libraries,
                (i) all third-partyoropen source code embedded therein, and (i)
                any upgrades, updates, releases, fixes, enhancements or
                modifications to the fore going), graphics, images, design
                (color combinations and page layout), text, information, data,
                resumess to redinvarious commercial database so peratedand
                licensed by Bongkonect( including, among others,its"Resume
                Database," "Salary Calculator Database," "Talent Stream Supply &
                Demand Database" and the Recruitment Edge Database), data
                submited via the Sites by Users and other content madeavailable
                through the Sites by Bongkonect.
              </p>
            </div>

            <div className="row">
              <p>
                3.4 "Document" refers to any posting to a Site, whether job or
                resume.
              </p>
            </div>

            <div className="row">
              <p>
                3.5 "Employer" means a person or entity that is accessing a Site
                to post a job or utilizing the Services for any reason related
                to the purpose of seeking candidates for employment.
              </p>
            </div>

            <div className="row">
              <p>
                3.6 Employer Materials" includes any brochures, emails, sample
                job postings, web site content, career fair material, audio,
                videos, photographs, logos, trademarks, service marks, domain
                names, documents or other materials provided by Employer, if
                any, for use in connection with the Services.
              </p>
            </div>

            <div className="row">
              <p>
                3.7 "Job Seeker" means a User who is accessing a Site to search
                for a job or in any other capacity except as an Employer.
              </p>
            </div>

            <div className="row">
              <p>
                3.8 "Services" means any services provided by Bongkonect or its
                agents described herein and specified more fully in the website.
              </p>
            </div>

            <div className="row">
              <p>
                3.9 "User" refers to any individual or entity that uses any
                aspect of the Sites.
              </p>
            </div>

            <div className="row">
              <p>
                3.10 "You" or "you" means the person who (or the entity on
                behalf of whom you are acting) that is agreeing to these Terms
                and Conditions.
              </p>
            </div>

            <div className="row">
              <p>
                4. Intellectual Property Rights and Acceptable Use of the Sites
                and Services.
              </p>
            </div>

            <div className="row">
              <p>
                4.1 General Use Rules. The Sites are intended for individuals
                seeking employment and for employers or recruiters seeking
                candidates for employment. You may use the Sites only for lawful
                purposes within the stated context of Bongkonect's intended and
                acceptable use of the Sites. Bongkonect is the sole interpreter
                of the Sites' intended and acceptable use.
              </p>
            </div>

            <div className="row">
              <p>4.2 Bongkonect Intelectual Property Rights.</p>
            </div>

            <div className="row">
              <p>
                The Sites, the Bongkonect Materials and all right, title and
                interest in and to the Sites and Bongkonect Materials are the
                sole property of Bongkonect or its licensors, and are protected
                by copyright, trademark and other laws. Except for the limited
                licenses expressly granted to you in these Terms and Conditions,
                Bongkonect reserves for itself and its licensors all other
                rights, title and interest. Without limitation on the foregoing,
                you may not reproduce, modify, display, sell, or distribute the
                Content or Bongkonect Materials, or use them in any other way
                for public or commercial purpose. Not with standing anything to
                the contrary contained herein, this prohibition includes: (a)
                copying or adapting the HTML code used to generate web pages on
                the Sites; (b) using or attempting to use engines, manual or
                automated software, tools, devices, agents, scripts, robots or
                other means, devices, mechanisms or processes (including, but
                not limited to, browsers, spiders, robots, avatars or
                intelligent agents) to navigate, search, access, "scrape,"
                "crawl," or "spider" any web pages or any Services provided on
                the Sites other than the search engine and search agents
                available from Bongkonect on such Bongkonect Sites and other
                than generally available third party web browsers (e.g.,
                Internet Explorer, Firefox, Safari); and (c) aggregating,
                copying or duplicating in any manner any of the Content or
                information available from any of the Bongkonect Sites, without
                the express written consent of Bongkonect. The use of the
                Content on any other web site or in a networked computer
                environment for any purpose is strictly prohibited. The
                Bongkonect Materials are not considered to be works for hire and
                you may duplicate such Bongkonect Materials only for the
                purposes outlined in the Service Activation Agreement.
                "Bongkonect," "Personified", the Bongkonect design logo and
                certain other names or logos are service marks or trademarks of
                Bongkonect, and all related product and service names, design
                marks and slogans are the service marks or trademarks of
                Bongkonect. In addition, the "look" and "feel" of the Sites
                (including color combinations, button shapes, layout, design and
                all other graphical elements) are also protected by Bongkonect's
                trademarks, service marks and copyrights. Any code that
                Bongkonect creates to generate or display the Content or the
                pages making up the Sites is also protected by Bongkonect's
                copyright. You must retain all copyright, trademark, service
                mark and other proprietary notices contained on the Content or
                Bongkonect Materials on any authorized copy you make of the
                Content or Bongkonect Materials. All other product and service
                marks contained on the Sites are the trademarks of their
                respective owners.
              </p>
            </div>

            <div className="row">
              <p>
                4.3 License to Use by Users who are Job Sekers. Bongkonect here
                by grants you a limited, terminable, non-exclusive right to
                access and use the Sites only for your personal use seeking
                employment opportunities for yourself. This authorizes you to
                view and download a single copy of the material on the Sites
                solely for your personal, non commercial use. You agree that you
                are solely responsible for the content of any Document you post
                to a Site and any consequences arising from such posting. Your
                use of the Sites is a privilege. Bongkonect reserves the right
                to suspend or terminate that privilege for any reason at any
                time, in its sole discretion.
              </p>
            </div>

            <div className="row">
              <p>
                8.Links to Other Sites. Bongkonect contains links to third party
                web sites. These links are provided solely as a convenience to
                you and not as an endorsement by Bongkonect of the contents on
                such third-party web sites. Bongkonect is not responsible for
                the content of linked third-party sites and does not make any
                representations regarding the content or accuracy of materials
                on such third-party web sites. If you decide to access linked
                third-party web sites, you do so at your own risk.
              </p>
            </div>

            <div className="row">
              <p>
                9. Amendments to this Agreement and Changes to Sites. Bongkonect
                may revise these Terms and Conditions at any time by updating
                this page. Changes will be binding on you on the date they are
                posted on the Sites (or as otherwise stated in the any notice of
                such changes). Any use of a Site will be considered acceptance
                by you of the then-current Terms and Conditions (including any
                exhibits thereto) contained on such Site. If at any time you
                find the Terms and Conditions unacceptable, you may not use the
                applicable Site any longer. Any new or different terms supplied
                by you are specifically rejected by Bongkonect unless Bongkonect
                agrees to them in a signed writing specifically including those
                new or different terms. Bongkonect may change the Sites at any
                time
              </p>
            </div>

            <div className="row">
              <p>
                10. Indemnity. You agree to defend, indemnify, and hold harmless
                Bongkonect (and its subsidiaries, affiliates, officers,
                directors, employees and agents) from and against any third
                party claims, actions or demands (including, without limitation,
                costs, damages and reasonable legal and accounting fees)
                alleging or resulting from or in connection with your use of the
                Sites, any Document posted by you, your use of the Services or
                your breach of this Agreement. Bongkonect shall use reasonable
                efforts to provide you prompt notice of any such claim, suit, or
                proceeding and may assist you, at your expense, in defending any
                such claim, suit or proceeding.
              </p>
            </div>

            <div className="row">
              <p>11.User Information and Privacy Policy.</p>
            </div>

            <div className="row">
              <p>
                11.1 When you register on any of the Bongkonect Sites or in
                connection with the Services, you will be asked to create an
                account and provide Bongkonect with certain information,
                including but not limited to, a valid email address ("User
                Information"). You acknowledge and agree that you have no
                ownership rights in your account.
              </p>
            </div>

            <div className="row">
              <p>
                11.2 All User Information will be used in accordance with the
                terms of Bongkonect's Privacy Policy. Please note, as set forth
                in the Privacy Policy, that Bongkonect may collect certain User
                Information and may contact Users periodically in accordance
                with the terms of the Privacy Policy. In addition, Bongkonect
                reserves the right to comply, in its sole discretion, with legal
                requirements, requests from law enforcement agencies or requests
                from government entities, even to the extent that suchcompliance
                may require disclosure of certain User Information. In addition,
                third parties may retain cached co
              </p>
            </div>

            <div className="row">
              <p>
                12.Rules regarding Posting, Conduct and Security. The following
                rules apply to your use of the Sites and/or the Services. The
                list of rules is for illustration only and is not a complete
                list of all posting, conduct and security rules.
              </p>
            </div>

            <div className="row">
              <p>13.Posting Rules:</p>
            </div>

            <div className="row">
              <p>
                (a) You may not post any Document to a Site that contains: (i)
                URLs or links to web sites other than to recruitment related
                pages on your company web site (to advertise your company or web
                site, see our Advertising Info page.); (ii) copyrighted material
                (unless you own the copyright or have the owner's permission to
                post the copyrighted material); (iii) trade secrets (unless you
                own them or have the owner's permission to post them); (iv)
                material that infringes on or misappropriates any other
                intellectual property rights, or violates the privacy or
                publicity rights of others; (v) irrelevant keywords or white
                text keywords (including any words embedded in the Document and
                hidden from the User); (vi) anything that is discriminatory,
                sexually explicit, obscene, libelous, defamatory, threatening,
                harassing, abusive, or hateful; or (vii) anything that is
                embarrassing or offensive to another person or entity. (viii)
                Resume postings must contain the accurate resume of a living
                individual seeking employment on a full- time, part-time, or
                contractual basis on his or her own behalf.
              </p>
            </div>

            <div className="row">
              <p>
                14.Bongkonect may terminate, suspend, update, alter or
                supplement,at its sole discretions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PostProfileForm);
