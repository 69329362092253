import axios from "axios";
export const IMAGE_URL =
  "https://bongkonect-bucket.s3.ap-south-1.amazonaws.com/";
export const SITE_BASE_URL = "https://bongkonect.com/";

const instance = axios.create({
  baseURL: "https://bongkonect.com/api/",
});

instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

export default instance;
