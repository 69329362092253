import React from 'react';
import NavNotSignedIn from '../../NavNotSignedIn/NavNotSignedIn';
import Footer from '../Footer';

const PrivacyPolicy = (props) => {

    return (
        <div>
            <NavNotSignedIn />
            <h4 style={{ textAlign: 'center' }}>Privacy Policy</h4>
            <div className="container">
                <div className="row">
                    <p>
                        This Privacy Policy applies to M/S BongKonect, having it's registered office at Bangalore. karnataka ,India.
                    </p>
                    <p>
                        We recognise the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on http://www.bongkonect.com and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online visitors. By visiting and/or using our website, you agree to this Privacy Policy.
                    </p>
                    <p>
                        Bongkonect.com is a domain registered with ICANN and also an Intellectual property owed by M/S Bongkonect, an Indian entity, having its registered office at
                        Bangalore.
                        In the state of karnataka , India
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        Information we collect
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Contact information. We might collect your name, email, mobile number, phone number, street, city, state, pincode, country and IP address.
                    </p>
                    <p>
                        Information you post. We collect information you post in a public space on our website or on a third‐ party social media site belonging to bongkonect.com
                    </p>
                    <p>
                        Demographic information. We may collect demographic information about you, Posts you like, posts you intend to participate in, service that you buy, or any other information provided by you during the use of our website. We might collect this as a part of a survey also.
                    </p>
                    <p>
                        Other information. If you use our website, we may collect information about your IP address and the browser you’re using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the operating system your computer or device is running.
                    </p>
                    <p>
                        We collect information in different ways.
                    </p>
                    <p>
                        We collect information directly from you. We collect information directly from you when you peep into any specific Service offered for an offer or buy that service. We also collect information if you post a
                        comment on our websites or intent to ask the seller a question through phone or email.
                    </p>
                    <p>
                        We collect information from you passively. We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website.
                    </p>
                    <p>
                        We get information about you from third parties. For example, if you use an integrated social media feature on our websites. The third‐party social media site will give us certain information about you complying with the directives as sanctioned by European Union. This could include your name and email address.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        Use of your personal information
                    </h6>
                </div>

                <div className="row">
                    <p>
                        We use information to respond to your requests or questions. We might use your information to confirm your registration for an event or contest.
                    </p>
                    <p>
                        We use information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based upon your preferences.
                    </p>
                    <p>
                        We use information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.
                    </p>
                    <p>
                        We use information for security purposes. We may use information to protect our company, our customers, or our websites.
                    </p>
                    <p>
                        We do not use information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third‐party offers or products we think you might find interesting. Or, for example, if you subscribe from us we’ll enroll you in our newsletter.
                    </p>
                    <p>
                        We use information to send you transactional communications. We might send you emails or SMS about your account.
                    </p>
                    <p>We use information as otherwise permitted by law.</p>
                </div>

            </div>

        </div>
    )
}

export default PrivacyPolicy;