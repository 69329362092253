import React, { useEffect, useState, useRef } from "react";
import { IMAGE_URL } from "../../axios";
import { connect } from "react-redux";
import M from "materialize-css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  updateProfile,
  updateProfilePic,
  updateProfileBackground,
} from "../../store/actions/index";
import axios from "../../axios";
import Avatar from "react-avatar";
import MyNav from "../../components/Nav/Nav";
import CandidateProfile from "../../components/CandidateProfile/CandidateProfile";
import JobPoster from "../../components/JobPoster/JobPoster";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ImageCrop from "../../components/ImageCrop/ImageCrop";

const Profile = (props) => {
  const profilePic =
    props.user.profilePic && props.user.profilePic !== ""
      ? IMAGE_URL + props.user.profilePic
      : null;
  const profileBackgroundPic =
    props.user.profileBackground && props.user.profileBackground !== ""
      ? IMAGE_URL + props.user.profileBackground
      : IMAGE_URL + "images/profile-background.png";

  const [profileBackgrounds, setProfileBackgrounds] = useState([]);

  const [myProfiles, setMyProfiles] = useState([]);
  const [myJobs, setMyJobs] = useState([]);
  const [businesses, setBusinesses] = useState([]);

  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);
  const [about, setAbout] = useState(props.user.about);
  const [mobile, setMobile] = useState(props.user.mobile);
  const [pincode, setPincode] = useState(props.user.pincode);
  const [city, setCity] = useState(props.user.city);

  const [userQuery, setUserQuery] = useState("");

  const [showCrop, setShowCrop] = useState(false);
  const [file, setFile] = useState(null);

  const [refresh, setRefresh] = useState("");

  useEffect(() => {
    M.AutoInit();

    axios
      .get("getProfileBackgrounds")
      .then((response) =>
        setProfileBackgrounds(response.data.profileBackgrounds)
      )
      .catch((err) => console.log(err.data));

    if (props.user.userType === "business") {
      axios
        .get("getUserBusinesses")
        .then((response) => setBusinesses(response.data))
        .catch((err) => console.log(err.data));
    }

    axios
      .get("getUserProfiles")
      .then((response) => setMyProfiles(response.data.profiles))
      .catch((err) => console.log(err.data));

    axios
      .get("getUserJobs")
      .then((response) => setMyJobs(response.data.jobs))
      .catch((err) => console.log(err.data));
  }, [refresh]);

  const profilePicChooser = useRef();

  const onFileChanged = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setShowCrop(true);
    }
  };

  const submitForm = (event) => {
    event.preventDefault();

    props.updateProfile(firstName, lastName, about, mobile, city, pincode);
  };

  const submitUserQuery = (e) => {
    e.preventDefault();
    axios
      .post("createQuery", { query: userQuery })
      .then((response) => alert("Query Saved !"))
      .catch((err) => console.log(err));
  };

  const onMobileChange = (value) => {
    if (value.length > 15) return;
    setMobile(value);
  };

  const onPincodeChange = (value) => {
    if (value.length > 10) return;
    setPincode(value);
  };

  return (
    <div>
      {props.error ? toast.error(props.error) : null}

      <MyNav location={props.location} />

      <ImageCrop
        file={file}
        open={showCrop}
        setOpen={setShowCrop}
        updateFile={props.updateProfilePic}
      />

      <div className="container">
        <div id="profileBackgroundModal" className="modal">
          <div className="modal-content">
            <div className="row" style={{ backgroundColor: "white" }}>
              {profileBackgrounds.map((photo) => {
                return (
                  <div
                    className="col modal-close"
                    key={photo._id}
                    style={{ marginBottom: "10px", cursor: "pointer" }}
                    onClick={() =>
                      props.updateProfileBackground(photo.profileBackground)
                    }
                  >
                    <img
                      src={IMAGE_URL + photo.profileBackground}
                      height="100"
                      width="100"
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div id="myQueryModal" className="modal">
          <div className="modal-content">
            <div className="row">
              <form className="col s12" onSubmit={(e) => submitUserQuery(e)}>
                <div className="row">
                  <div className="input-field col s12">
                    <textarea
                      id="textarea1"
                      className="materialize-textarea"
                      value={userQuery}
                      onChange={(e) => setUserQuery(e.target.value)}
                      maxLength="50"
                      required
                    ></textarea>
                    <label htmlFor="textarea1">Your Query</label>
                  </div>
                </div>
                <button
                  className="waves-effect waves-light btn modal-close"
                  disabled={userQuery.length === 0}
                  type="submit"
                  style={{ backgroundColor: "#fe0000" }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="card" style={{ width: "100%" }}>
          <div className="card-image responsive-img">
            <a className="modal-trigger" href="#profileBackgroundModal">
              <img src={profileBackgroundPic} alt="" />
            </a>
          </div>

          <div className="card-content">
            <div className="row valign-wrapper">
              {isMobile ? (
                <div
                  className="col s5 l3 xl3"
                  style={{
                    zIndex: "5",
                    position: "absolute",
                    marginTop: "-20px",
                    cursor: "pointer",
                  }}
                  onClick={() => profilePicChooser.current.click()}
                >
                  <Avatar
                    name={props.user.firstName + " " + props.user.lastName}
                    src={profilePic}
                    size="80px"
                  />
                  <input
                    type="file"
                    disabled={props.userLoading}
                    style={{ display: "none" }}
                    ref={profilePicChooser}
                    onChange={(e) => onFileChanged(e)}
                  />
                </div>
              ) : (
                <div
                  className="col s5 l3 xl3"
                  style={{
                    zIndex: "5",
                    position: "absolute",
                    marginTop: "-40px",
                    cursor: "pointer",
                  }}
                  onClick={() => profilePicChooser.current.click()}
                >
                  <Avatar
                    name={props.user.firstName + " " + props.user.lastName}
                    src={profilePic}
                    size="150px"
                  />
                  <input
                    type="file"
                    disabled={props.userLoading}
                    style={{ display: "none" }}
                    ref={profilePicChooser}
                    onChange={(e) => onFileChanged(e)}
                  />
                </div>
              )}
            </div>
            <br />
            <div
              className="row"
              style={
                (isMobile ? null : { textAlign: "center" },
                { marginTop: "10px" })
              }
            >
              <div className="col s10 m2 l2 xl2">
                <span style={{ fontWeight: "600", fontSize: "1.5em" }}>
                  {props.user.lastName + " " + props.user.firstName}
                </span>
                <br />
                {props.user.about != "" ? (
                  <>
                    <span style={{ fontWeight: "600", fontSize: "1em" }}>
                      {props.user.about}
                    </span>{" "}
                    <br />{" "}
                  </>
                ) : (
                  <></>
                )}
                <span style={{ fontWeight: "600", fontSize: "1em" }}>
                  {props.user.city + ", " + props.user.country}
                </span>
              </div>

              <div className="col s1"></div>
              <div className="col s3 hide-on-small-only">
                <a
                  className="btn right modal-trigger"
                  href="#editUserProfileModal"
                  style={{ backgroundColor: "#fe0000" }}
                >
                  <i className="material-icons left">edit</i>Edit Profile
                </a>
              </div>
              <div className="col s2 hide-on-small-only">
                <Link to="/list-business">
                  <button
                    className="waves-effect waves-light btn"
                    style={{ backgroundColor: "#fe0000" }}
                  >
                    List Business
                  </button>
                </Link>
              </div>
              <div className="col s2 hide-on-small-only">
                <button
                  className="waves-effect waves-light btn modal-trigger"
                  data-target="myQueryModal"
                  style={{ backgroundColor: "#fe0000" }}
                >
                  My Query
                </button>
              </div>
            </div>
          </div>
        </div>

        {businesses.length > 0 && (
          <div className="card">
            <div className="card-content">
              <div className="card-title row">
                <h5 className="col s12 m10 l10 xl10">Previously Listed Ads</h5>
              </div>

              {businesses?.map((business) => {
                let image = "";
                if (business?.business?.postType === "banner") {
                  image = business?.banner?.banner;
                }
                if (business?.business?.postType === "poster") {
                  image = business?.poster?.poster;
                }
                if (
                  business?.business?.userImage &&
                  business?.business?.userImage !== ""
                ) {
                  image = business?.business?.userImage;
                }

                return (
                  <div>
                    <div className="row">
                      <div className="col s3 hide-on-small-only">
                        {/* <img
                          src={IMAGE_URL + image}
                          height="100"
                          width="100"
                          alt=""
                        /> */}
                      </div>

                      <div
                        className="col s12 m6 l6 xl6"
                        style={{ textAlign: "center" }}
                      >
                        <h5>{business.business.businessName}</h5>
                        <h6>{business.business.category}</h6>
                        <h6>
                          {business.business.city}, {business.business.country}
                        </h6>
                        <p>{business.business.description}</p>
                        {business.business.paymentCompleted ? (
                          <p style={{ color: "green" }}>Payment Completed</p>
                        ) : (
                          <p style={{ color: "red" }}>
                            Payment not completed.{" "}
                            <Link
                              to={`/payment?type=business&businessId=${business.business._id}`}
                            >
                              Click Here
                            </Link>{" "}
                            to complete payment.
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="divider"></div>

                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {myProfiles.length > 0 && (
          <div className="card">
            <div className="card-content">
              <div className="card-title row">
                <h5 className="col s10">Listed Profiles</h5>
              </div>

              <div className="row">
                {myProfiles.map((profile, index) => {
                  return (
                    <div className="col s12 m3 l3 xl3" key={profile._id}>
                      <CandidateProfile
                        profile={profile}
                        edit
                        refresh={setRefresh}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {myJobs.length > 0 && (
          <div className="card">
            <div className="card-content">
              <div className="card-title row">
                <h5 className="col s10">Listed Jobs</h5>
              </div>

              <div className="row">
                {myJobs.map((job, index) => {
                  return (
                    <div className="col s12 m3 l3 xl3" key={job._id}>
                      <JobPoster job={job} edit />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer style={{ marginBottom: "0px", marginTop: "10px" }} />

      <div id="editUserProfileModal" className="modal">
        <div className="modal-content">
          <h5>Edit Profile</h5>
          <div className="row">
            <form onSubmit={(e) => submitForm(e)}>
              <div className="row">
                <div className="col s6 input-field">
                  <input
                    type="text"
                    id="lastName"
                    className="validate"
                    maxLength="20"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                  <label className="active" htmlFor="lastName">
                    Last Name
                  </label>
                </div>
                <div className="col s6 input-field">
                  <input
                    type="text"
                    id="firstName"
                    className="validate"
                    maxLength="20"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <label className="active" htmlFor="firstName">
                    First Name
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col s6 input-field">
                  <input
                    type="text"
                    id="about"
                    className="validate"
                    maxLength="30"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                  />
                  <label className="active" htmlFor="about">
                    Headline
                  </label>
                </div>
                <div className="col s6 input-field">
                  <input
                    type="number"
                    id="mobile"
                    className="validate"
                    value={mobile}
                    onChange={(e) => onMobileChange(e.target.value)}
                    required
                  />
                  <label className="active" htmlFor="mobile">
                    Mobile No.
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col s6 input-field">
                  <input
                    type="text"
                    className="validate"
                    id="city"
                    maxLength="25"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                  <label className="active" htmlFor="city">
                    City
                  </label>
                </div>

                <div className="col s6 input-field">
                  <input
                    type="text"
                    className="validate"
                    id="pincode"
                    value={pincode}
                    onChange={(e) => onPincodeChange(e.target.value)}
                    required
                  />
                  <label className="active" htmlFor="pincode">
                    Pincode
                  </label>
                </div>
              </div>

              <button
                type="submit"
                className="waves-effect waves-light btn modal-close"
              >
                Update Profile
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userLoading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (firstName, lastName, about, mobile, city, pincode) =>
      dispatch(
        updateProfile(firstName, lastName, about, mobile, city, pincode)
      ),
    updateProfilePic: (profilePic) => dispatch(updateProfilePic(profilePic)),
    updateProfileBackground: (profileBackground) =>
      dispatch(updateProfileBackground(profileBackground)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
