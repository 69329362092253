import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import M from "materialize-css";
import axios from "../../axios";
import { NavLink, Link } from "react-router-dom";
import {
  fetchPosterBusinesses,
  searchBusiness,
} from "../../store/actions/index";
import "./ListingNav.css";

const ListingNav = (props) => {
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    M.AutoInit();
  }, []);

  useEffect(() => {
    axios
      .get("getCategories")
      .then((response) => setCategories(response.data.categories))
      .catch((err) => console.log(err.data));

    axios
      .get("getCities")
      .then((response) => setCities(response.data))
      .catch((err) => console.log(err.data));
  }, []);

  const getNavLinkClass = (path) => {
    return props.location.pathname === path ? "active" : "";
  };

  const onSearchCategoryChange = (value) => {
    setSearchCategory(value);
  };

  const onSearchCityChange = (value) => {
    setSearchCity(value);
  };

  const onSearchTextChange = (value) => {
    setSearchText(value);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    props.searchBusinesses(searchCategory, searchCity);
  };

  return (
    <div>
      <ul id="dropdown1" className="dropdown-content">
        <li>
          <NavLink to="/logout">Logout</NavLink>
        </li>
      </ul>

      <div className="navbar-fixed">
        <nav>
          <div className="nav-wrapper">
            <a href="#" className="brand-logo">
              <img
                src={axios.IMAGE_URL + "images/logo.png"}
                style={{ width: "150px", marginLeft: "50px" }}
                alt="logo"
              />
            </a>
            <a href="#" data-target="mobile-demo" className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </a>

            <div className="row">
              <div className="col s10">
                <form onSubmit={(e) => onFormSubmit(e)}>
                  <div className="row valign-wrapper">
                    <div className="col s4 valign-wrapper">
                      <div className="selectWrapper">
                        <select
                          className="selectBox browser-default"
                          value={searchCategory}
                          onChange={(e) =>
                            onSearchCategoryChange(e.target.value)
                          }
                        >
                          <option value="">Category</option>
                          {categories.map((category, index) => {
                            return (
                              <option value={category.category} key={index}>
                                {category.category}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col s4 valign-wrapper">
                      <div className="selectWrapper">
                        <select
                          className="selectBox browser-default"
                          value={searchCity}
                          onChange={(e) => onSearchCityChange(e.target.value)}
                        >
                          <option value="">City</option>
                          {cities.map((city) => {
                            return (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col s4 ">
                      <button className="btn" type="submit">
                        Search
                      </button>
                      {/* <input type="search" placeholder="Search..." value={searchText} onChange={(e) => onSearchTextChange(e.target.value)} style={{ width: '100%', background: 'white', outline: 'none', padding: '0px 20px 0px 20px', borderRadius: '10px' }} /> */}
                    </div>
                  </div>
                </form>
              </div>
              <div className="col s2">
                <ul className="right hide-on-med-and-down" style={{}}>
                  <li className={getNavLinkClass("/")}>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className={getNavLinkClass("/video")}>
                    <NavLink to="/video">Video</NavLink>
                  </li>
                  <li className={getNavLinkClass("/jobs")}>
                    <NavLink to="/jobs">Post your job</NavLink>
                  </li>
                  <li className={getNavLinkClass("/profile")}>
                    <NavLink to="/profile">My Profile</NavLink>
                  </li>
                  {props.isAuthenticated ? (
                    <li>
                      <NavLink to="/logout">Logout</NavLink>
                    </li>
                  ) : (
                    <li>
                      <NavLink to="/login">Login / Sign Up</NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <ul className="sidenav" id="mobile-demo">
        <li>
          <a href="sass.html">Home</a>
        </li>
        <li>
          <a href="badges.html">Video</a>
        </li>
        <li>
          <a href="collapsible.html">Post your job</a>
        </li>
        <li>
          <a href="mobile.html">My Profile</a>
        </li>
        <li>
          <NavLink to="/logout">Logout</NavLink>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchBusinesses: (category, city) =>
      dispatch(searchBusiness(category, city)),
    fetchPosterBusinesses: () => dispatch(fetchPosterBusinesses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingNav);
