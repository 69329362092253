import React, { useEffect } from "react";
import { IMAGE_URL } from "../../axios";
import M from "materialize-css";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { connect } from "react-redux";

const JobPoster = (props) => {
  useEffect(() => {
    M.AutoInit();
  }, []);

  const completeJobPayment = () => {
    const myData = {
      type: "job",
      userId: props.user.userId,
      serviceId: props.job._id,
      buyer_name: props.user.firstName + " " + props.user.lastName,
      email: props.user.email,
      phone: props.user.mobile,
    };

    return axios
      .post("payment", myData)
      .then((myresponse) => {
        const paymentLink = myresponse.data.payment_request.longurl;
        window.location.replace(paymentLink);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let emailVerifiedHtml = null;
  if (props.edit) {
    if (props.job.emailVerified)
      emailVerifiedHtml = <li style={{ color: "green" }}>Email verified</li>;
    else
      emailVerifiedHtml = <li style={{ color: "red" }}>Email not verified</li>;
  }

  let paymentCompletedHtml = null;
  if (props.edit) {
    if (props.job.paymentCompleted)
      paymentCompletedHtml = <li style={{ color: "green" }}>Payment done</li>;
    else {
      const link = `/payment?type=job&jobId=${props.job._id}`;
      paymentCompletedHtml = (
        <li style={{ color: "red" }}>Payment not completed</li>
      );
    }
  }

  return (
    <div>
      <div className="card">
        <div className="card-image">
          <img className="materialboxed" src={IMAGE_URL + props.job.poster} />
        </div>
        {props.edit && (
          <div className="card-content">
            {emailVerifiedHtml}
            {paymentCompletedHtml}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (props) => {
  return {
    user: props.auth.user,
  };
};

export default connect(mapStateToProps)(JobPoster);
