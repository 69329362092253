import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    loading: false,
    businesses: [],
    searchBusinesses: [],
    posterBusinesses: []
}

const businessesReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_BUSINESSES_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.FETCH_BUSINESSES_SUCCESS:
            return {
                ...state,
                loading: false,
                businesses: action.businesses
            }

        case actionTypes.FETCH_BUSINESSES_FAILED:
            return {
                ...state,
                loading: false
            }

        case actionTypes.FETCH_POSTER_BUSINESSES_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.FETCH_POSTER_BUSINESSES_SUCCESS:
            return {
                ...state,
                loading: false,
                posterBusinesses: action.posterBusinesses
            }

        case actionTypes.FETCH_POSTER_BUSINESSES_FAILED:
            return {
                ...state,
                loading: false
            }

        case actionTypes.SEARCH_BUSINESSES_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.SEARCH_BUSINESSES_SUCCESS:
            return {
                ...state,
                loading: false,
                businesses: action.businesses
            }

        case actionTypes.SEARCH_BUSINESSES_FAILED:
            return {
                ...state,
                loading: false
            }

        default:
            return state
    }
}

export default businessesReducer;