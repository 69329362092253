import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import "../node_modules/video-react/dist/video-react.css";
import HomeNew from "./containers/HomeNew/HomeNew";
import Login from "./containers/Login/Login";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { checkAuthState } from "./store/actions/index";
import Signup from "./containers/Signup/Signup";
import Posts from "./containers/Posts/Posts";
import Logout from "./components/Logout/Logout";
import Banner from "./components/Banner/Banner";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import SinglePost from "./components/SinglePost/SinglePost";
import BusinessListing from "./containers/BusinessListing/BusinessListing";
import Search from "./containers/Search/Search";
import Video from "./containers/Video/Video";
import Jobs from "./containers/Jobs/Jobs";
import SearchProfile from "./containers/SearchProfile/SearchProfile";
import Profile from "./containers/Profile/Profile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchJob from "./containers/SearchJob/SearchJob";
import PostProfileForm from "./components/PostProfileForm/PostProfileForm";
import PostJobForm from "./components/PostJobForm/PostJobForm";
import UserAgreement from "./components/Footer/UserAgreement/UserAgreement";
import SellerAgreement from "./components/Footer/SellerAgreement/SellerAgreement";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy/PrivacyPolicy";
import CommunityGuidelines from "./components/Footer/CommunityGuidelines/CommunityGuidelines";
import ListBusiness from "./containers/ListBusiness/ListBusiness";
import RazorPayPayment from "./components/RazorPayPayment/RazorPayPayment";
import { Helmet } from "react-helmet";

class App extends React.Component {
  componentDidMount() {
    this.props.tryAutoLogin();
    toast.configure();
  }

  render() {
    var routes = (
      <Switch>
        <Route path="/near-me" exact component={BusinessListing} />
        <Route path="/life-line" exact component={Jobs} />
        <Route path="/scope" exact component={Video} />
        <Route path="/search-profile" exact component={SearchProfile} />
        <Route path="/search-job" exact component={SearchJob} />
        <Route path="/list-business" exact component={ListBusiness} />

        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/payment" component={RazorPayPayment} />
        <Route path="/user-agreement" component={UserAgreement} />
        <Route path="/seller-agreement" component={SellerAgreement} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/community-guidelines" component={CommunityGuidelines} />
        <Route path="/reset/:token" exact component={ResetPassword} />
        <Route path="/" exact component={HomeNew} />
        <Route path="/post/:id" exact component={SinglePost} />
        <Redirect to="/" />
      </Switch>
    );

    if (
      this.props.isAuthenticatedIndividual ||
      this.props.isAuthenticatedBusiness
    ) {
      routes = (
        <Switch>
          <Route path="/near-me" exact component={BusinessListing} />
          <Route path="/life-line" exact component={Jobs} />
          <Route path="/scope" exact component={Video} />
          <Route path="/search-profile" exact component={SearchProfile} />
          <Route path="/search-job" exact component={SearchJob} />

          <Route path="/logout" component={Logout} />
          <Route path="/search" exact component={Search} />
          <Route path="/list-business" exact component={ListBusiness} />

          <Route path="/profile" exact component={Profile} />
          <Route path="/post-profile" exact component={PostProfileForm} />
          <Route path="/post-job" exact component={PostJobForm} />
          <Route path="/payment" component={RazorPayPayment} />
          <Route path="/" exact component={Posts} />
          <Route path="/banner/:id" exact component={Banner} />
          <Route path="/post/:id" exact component={SinglePost} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return <div>{routes}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticatedIndividual:
      state.auth.token !== null && state.auth.isAuthenticatedIndividual,
    isAuthenticatedBusiness:
      state.auth.token !== null && state.auth.isAuthenticatedBusiness,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    tryAutoLogin: () => dispatch(checkAuthState(ownProps)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
