import React from 'react';
import ListingNav from '../../components/ListingNav/ListingNav';
import ListingCard from '../../components/ListingCard/ListingCard';
import { connect } from 'react-redux';
import { fetchPosterBusinesses, searchBusiness } from '../../store/actions/index';
import Poster from '../../components/Poster/Poster';
import Carousel from '../../components/Carousel/Carousel';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../components/UI/Loader/Loader';
import queryString from 'query-string';

class Search extends React.Component {

    componentDidMount() {
        const value = queryString.parse(this.props.location.search);
        const city = value.city;
        const category = value.cat;
        this.props.searchBusinesses(category, city)
        this.props.fetchPosterBusinesses()
    }


    render() {

        var items = [];

        this.props.businesses.map((business) => {
            items.push(
                <ListingCard key={'business-' + new Date().getTime()} business={business} />
            )
        });

        var posterItemsLeft = [];

        this.props.posterBusinesses.map((poster) => {
            posterItemsLeft.push(
                <Poster float="right" key={'poster-' + new Date().getTime()} poster={poster} />
            )
        });

        var posterItemsRight = [];

        this.props.posterBusinesses.map((poster) => {
            posterItemsRight.push(
                <Poster key={'poster-' + new Date().getTime()} poster={poster} />
            )
        });

        return (
            <div style={{ backgroundColor: '#f5f5f5' }}>
                <ListingNav location={this.props.location} />
                <br />
                <div style={{ marginLeft: '40px', marginRight: '40px' }}><Carousel /></div>
                <br />
                <div className='content'>
                    <div className="row">
                        <div className="col s3 hide-on-med-and-down">

                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.props.fetchPosterBusinesses}
                                hasMore={true}
                                loader={<Loader />}>

                                <div>
                                    {posterItemsLeft}
                                </div>
                            </InfiniteScroll>
                        </div>

                        <div className="col s12 l6 xl6">
                            {console.log(items)}
                            {items}
                        </div>

                        <div className="col s3 hide-on-med-and-down">

                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.props.fetchPosterBusinesses}
                                hasMore={true}
                                loader={<Loader />}>

                                <div>
                                    {posterItemsRight}
                                </div>
                            </InfiniteScroll>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        businesses: state.businesses.searchBusinesses,
        posterBusinesses: state.businesses.posterBusinesses
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchBusinesses: (category, city) => dispatch(searchBusiness(category, city)),
        fetchPosterBusinesses: () => dispatch(fetchPosterBusinesses())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);