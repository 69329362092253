import * as actionTypes from './actionsTypes';
import axios from '../../axios';

export const fetchPostsStart = () => {
    return {
        type: actionTypes.FETCH_POSTS_START
    }
}

export const fetchPostsSuccess = (posts) => {
    return {
        type: actionTypes.FETCH_POSTS_SUCCESS,
        payload: { posts }
    }
}

export const fetchPostsFailed = () => {
    return {
        type: actionTypes.FETCH_POSTS_FAILED
    }
}

export const deletePostStart = () => {
    return {
        type: actionTypes.DELETE_POST_START
    }
}

export const deletePostSuccess = (postId) => {
    return {
        type: actionTypes.DELETE_POST_SUCCESS,
        postId
    }
}

export const deletePostFailed = () => {
    return {
        type: actionTypes.DELETE_POST_FAILED
    }
}

export const fetchPosts = () => {
    return dispatch => {
        dispatch(fetchPostsStart());
        axios.get('getPosts')
            .then(response => {
                dispatch(fetchPostsSuccess(response.data))
            })
            .catch(error => {
                dispatch(fetchPostsFailed())
            })
    }
}

export const deletePost = (postId) => {
    return dispatch => {
        dispatch(deletePostStart())
        axios.post('deletePost', {postId})
            .then(response => { dispatch(deletePostSuccess(postId))})
            .catch(error => dispatch(deletePostFailed(error.data)))
    }
}
