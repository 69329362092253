import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import M from "materialize-css";
import { posterSubmit } from "../../store/actions/index";
import "./Modal.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IMAGE_URL } from "../../axios";

const PosterModal = (props) => {
  var customStyles = {
    width: "350px",
    position: "absolute",
    zIndex: "5",
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-30%",
    marginTop: "100px",
    transform: "translate(-50%, -50%)",
    overlfow: "scroll", // <-- This tells the modal to scrol
  };

  const [descriptionText, setDescriptionText] = useState(
    props.description ? props.description : ""
  );
  const [posterImage, setPosterImage] = useState(
    props.poster ? props.poster : IMAGE_URL + props.posters[0].poster
  );
  const [posterColor, setPosterColor] = useState(props.posters[0].textColor);
  const [posterImageId, setPosterImageId] = useState(props.posters[0]._id);
  const [postButtonDisabled, setPostButtonDisabled] = useState(true);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userPosterSelected, setUserPosterSelected] = useState(false);
  const descriptionRefs = React.createRef();
  const posterRefs = React.createRef();

  useEffect(() => {
    M.AutoInit();
    var elems = document.querySelectorAll(".modal");
    // var instances = M.Modal.init(elems, {
    //     dismissible: true
    // });
  }, []);

  const changePosterImage = (src, id = "", textColor) => {
    setPosterImage(src);
    setPosterImageId(id);
    setPosterColor(textColor);
  };

  const onPosterFileUpload = (event) => {
    var file = event.target.files[0];
    setUserPosterSelected(false);
    if (checkMimeType(event)) {
      // if return true allow to setState

      var reader = new FileReader(); // CREATE AN NEW INSTANCE.

      reader.onload = function (e) {
        var img = new Image();
        img.src = e.target.result;

        img.onload = function () {
          var w = this.width;
          var h = this.height;

          if (w === 300 && h === 450) {
            setUserPhoto(file);
            setUserPosterSelected(true);
            setPosterImage(URL.createObjectURL(file));
          } else {
            alert("Please upload poster of mentioned dimensions");
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = [];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/gif"];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err[x] = files[x].type + " is not a supported format\n";
      }
    }
    for (let z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };

  return (
    <React.Fragment>
      <div id="modal1" className="modal">
        <div className="modal-content">
          <h6>Select Poster</h6>
          <br />
          <div className="row" style={{ backgroundColor: "white" }}>
            {props.posters.map((photo) => {
              return (
                <div
                  className="col modal-close"
                  key={photo._id}
                  style={{ marginBottom: "10px", cursor: "pointer" }}
                >
                  <img
                    src={IMAGE_URL + photo.poster}
                    height="70"
                    width="70"
                    onClick={(e) =>
                      changePosterImage(
                        e.target.src,
                        photo._id,
                        photo.textColor
                      )
                    }
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: "100",
          left: "0",
          top: "0",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="card" style={customStyles}>
          <div className="row" style={{ padding: "10px" }}>
            <div className="col">
              <span className="card-title">Create Poster</span>
            </div>
            <div className="col right">
              <i
                className="material-icons"
                style={{ cursor: "pointer" }}
                onClick={props.closeModal}
              >
                close
              </i>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              textAlign: "center",
              color: "white",
            }}
          >
            <div className="card-image">
              <img
                src={posterImage}
                ref={posterRefs}
                style={{ width: "100%", height: "450px" }}
                alt=""
              />
            </div>

            <div
              style={{
                position: "absolute",
                width: "100%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <textarea
                value={descriptionText}
                ref={descriptionRefs}
                maxLength="50"
                placeholder="Write something here..."
                onChange={(e) => {
                  setDescriptionText(e.target.value);
                  e.target.value.length > 0
                    ? setPostButtonDisabled(false)
                    : setPostButtonDisabled(true);
                }}
                style={{
                  color: posterColor,
                  borderStyle: "none",
                  outline: "none",
                  width: "100%",
                  height: "50%",
                  textAlign: "center",
                  fontSize: "1.8em",
                  fontWeight: "bold",
                }}
              />
            </div>
          </div>

          <div className="card-action">
            <a
              className="waves-effect waves-light btn modal-trigger red"
              href="#modal1"
            >
              Posters
            </a>

            <div
              className="file-field input-field right"
              style={{ margin: "0px" }}
            >
              <div
                style={{
                  backgroundColor: "#eeeeee",
                  borderRadius: "20px",
                  padding: "5px 20px 5px 20px",
                }}
              >
                <i
                  className="material-icons"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    color: "red",
                  }}
                >
                  photo_library
                </i>
                <span
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                  }}
                >
                  Upload your own
                </span>
                <input type="file" onChange={(e) => onPosterFileUpload(e)} />
              </div>
            </div>

            <button
              className="btn"
              disabled={postButtonDisabled && !userPosterSelected}
              onClick={() => {
                userPhoto
                  ? props.submitPoster("", "", descriptionText, userPhoto)
                  : props.submitPoster(
                      posterImageId,
                      posterImage,
                      descriptionText
                    );
                props.closeModal();
              }}
              style={{ width: "100%" }}
            >
              Post
            </button>
            <span>* Upload poster of size 300 x 450 pixels</span>
          </div>
        </div>
      </div>
      <ToastContainer style={{ marginBottom: "0px", marginTop: "10px" }} />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitPoster: (posterId, posterImage, descriptionText, userPhoto) =>
      dispatch(posterSubmit(posterId, posterImage, descriptionText, userPhoto)),
  };
};

export default connect(null, mapDispatchToProps)(PosterModal);
