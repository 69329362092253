import React, { useEffect, useState, useRef } from "react";
import { IMAGE_URL } from "../../axios";
import { connect } from "react-redux";
import M from "materialize-css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadPost } from "../../store/actions/create-post";
import {
  updateProfilePic,
  updateProfileBackground,
} from "../../store/actions/index";
import axios from "../../axios";
import Avatar from "react-avatar";
import { isMobile } from "react-device-detect";
import { Player, BigPlayButton } from "video-react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import ImageCrop from "../ImageCrop/ImageCrop";

const CreatePost = (props) => {
  const profilePic =
    props.user.profilePic && props.user.profilePic !== ""
      ? IMAGE_URL + props.user.profilePic
      : null;
  const profileBackgroundPic =
    props.user.profileBackground && props.user.profileBackground !== ""
      ? IMAGE_URL + props.user.profileBackground
      : IMAGE_URL + "images/profile-background.png";

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [profileBackgrounds, setProfileBackgrounds] = useState([]);
  const [media, setMedia] = useState(null);
  const [mediaUrls, setMediaUrl] = useState([]);
  const [upload_preview, setUpPreview] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showCrop, setShowCrop] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    M.AutoInit();

    axios
      .get("getProfileBackgrounds")
      .then((response) =>
        setProfileBackgrounds(response.data.profileBackgrounds)
      )
      .catch((err) => console.log(err.data));
  }, []);

  const profilePicChooser = useRef();

  const onFileChanged = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setShowCrop(true);
    }
  };

  const onDescriptionChange = (value) => {
    setDescription(value);
    if (value.length == 0 && mediaUrls.length == 0) setButtonDisabled(true);
    else setButtonDisabled(false);
  };

  const onFilesChanged = (event) => {
    var files = event.target.files;
    if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
      // if return true allow to setState
      setMedia(files);
      setButtonDisabled(false);

      let media = [];
      let uploads = [];
      for (let x = 0; x < files.length; x++) {
        media.push({
          name: URL.createObjectURL(files[x]),
          mediaType: files[x].type,
        });
        let option = {};
        if (files[x].type === "video/mp4") {
          option = {
            original: "",
            renderItem: () => {
              return (
                <div className="card-image">
                  {isMobile ? (
                    <video width="300" controls>
                      <source
                        src={URL.createObjectURL(files[x])}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <video width="580" controls>
                      <source
                        src={URL.createObjectURL(files[x])}
                        type="video/mp4"
                      />
                    </video>
                  )}
                </div>
              );
            },
          };
        } else {
          option = { original: URL.createObjectURL(files[x]) };
        }
        uploads.push(option);
      }
      setMediaUrl(media);
      setUpPreview(uploads);
    } else {
      setButtonDisabled(true);
    }
    if (files.length == 0 && description.length == 0) {
      setButtonDisabled(true);
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    uploadPost(media, description);
    setDescription("");
    setButtonDisabled(true);
    setMedia([]);
    setMediaUrl([]);
    setUpPreview([]);
  };

  const uploadPost = (media, description) => {
    setLoading(true);
    const data = new FormData();
    if (media) {
      for (var x = 0; x < media.length; x++) {
        data.append("photos", media[x]);
      }
    }
    data.append("description", description);
    axios
      .post("createPost", data)
      .then((res) => {
        setLoading(false);
        props.fetchPosts();
      })
      .catch((err) => {
        // then print response status
      });
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = [];
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/gif", "video/mp4"];
    // loop access array
    let status = true;
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err[x] = files[x].type + " is not a supported format\n";
        status = false;
      }
    }
    for (let z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
      status = false;
    }
    return status;
  };

  const maxSelectFile = (event) => {
    let files = event.target.files;
    if (files.length > 5) {
      const msg = "Only 5 images can be uploaded at a time";
      event.target.value = null;
      toast.warn(msg);
      return false;
    }
    return true;
  };

  const checkFileSize = (event) => {
    let files = event.target.files;
    let size = 150000000;
    let err = [];
    let status = true;
    for (let x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + "is too large, please pick a smaller file\n";
        status = false;
      }
    }
    for (let z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
      status = false;
    }
    return status;
  };

  const handlePaste = (event) => {
    var items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;

    for (var index in items) {
      var item = items[index];
      if (item.kind === "file") {
        var blob = item.getAsFile();

        setMedia([blob]);
        setButtonDisabled(false);

        let media = [];
        let uploads = [];

        media.push({
          name: URL.createObjectURL(blob),
          mediaType: "image",
        });

        let option = { original: URL.createObjectURL(blob) };

        uploads.push(option);

        setMediaUrl(media);
        setUpPreview(uploads);
      }
    }
  };

  return (
    <div>
      {props.error ? toast.error(props.error) : null}
      <ImageCrop
        file={file}
        open={showCrop}
        setOpen={setShowCrop}
        updateFile={props.updateProfilePic}
      />
      <div id="profileBackgroundModal" className="modal">
        <div className="modal-content">
          <div className="row" style={{ backgroundColor: "white" }}>
            {profileBackgrounds.map((photo) => {
              return (
                <div
                  className="col modal-close"
                  key={photo._id}
                  style={{ marginBottom: "10px", cursor: "pointer" }}
                  onClick={() =>
                    props.updateProfileBackground(photo.profileBackground)
                  }
                >
                  <img
                    src={IMAGE_URL + photo.profileBackground}
                    height="100"
                    width="100"
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="card" style={{ width: "100%" }}>
        <div className="card-image responsive-img">
          <a className="modal-trigger" href="#profileBackgroundModal">
            <img src={profileBackgroundPic} alt="" />
          </a>
        </div>

        <div className="card-content">
          <div className="row">
            <div className="col s3">
              {isMobile ? (
                <Avatar
                  name={props.user.firstName + " " + props.user.lastName}
                  round
                  src={profilePic}
                  size="80px"
                  style={{
                    zIndex: "5",
                    position: "absolute",
                    marginTop: "-60px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => profilePicChooser.current.click()}
                />
              ) : (
                <Avatar
                  name={props.user.firstName + " " + props.user.lastName}
                  round
                  src={profilePic}
                  size="150px"
                  style={{
                    zIndex: "5",
                    position: "absolute",
                    marginTop: "-100px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => profilePicChooser.current.click()}
                />
              )}

              <input
                type="file"
                disabled={props.userLoading}
                style={{ display: "none" }}
                ref={profilePicChooser}
                onChange={(e) => onFileChanged(e)}
              />
            </div>
            <div className="col s9">
              <div className="left" style={{ marginTop: "0px" }}>
                <span style={{ fontWeight: "600", fontSize: "1.5em" }}>
                  {props.user.lastName + " " + props.user.firstName}
                </span>
                <br />
                <span
                  style={{ fontWeight: "600", fontSize: "1em", color: "grey" }}
                >
                  {props.user.about}
                </span>

                <br />
                {props.user.about !== "" ? (
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "1em",
                      color: "grey",
                    }}
                  >
                    {props.user.about +
                      " | " +
                      props.user.city +
                      " | " +
                      props.user.country}
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "1em",
                      color: "grey",
                    }}
                  >
                    {props.user.city + " | " + props.user.country}
                  </span>
                )}
              </div>
            </div>
          </div>
          <br />
          <form encType="multipart/form-data" onSubmit={(e) => submitForm(e)}>
            <div className="row">
              <div className="col s6 m9 l9 xl9">
                <textarea
                  id="textarea2"
                  className="materialize-textarea"
                  onChange={(e) => onDescriptionChange(e.target.value)}
                  value={description}
                  data-length="50"
                  maxLength="4000"
                  placeholder="Write something here..."
                  rows="4"
                  style={{ width: "100%" }}
                  onPaste={handlePaste}
                ></textarea>
                <small className="right">{description.length}/4000</small>
              </div>
              <div className="col s6 m3 l3 x3">
                <div
                  className="file-field input-field"
                  style={{ display: "inline-block" }}
                >
                  <input
                    type="file"
                    multiple
                    onChange={(e) => onFilesChanged(e)}
                    onClick={() => {
                      setMedia([]);
                      setMediaUrl([]);
                      setUpPreview([]);
                    }}
                  />
                  <i
                    className="material-icons"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      fontSize: "2.2em",
                    }}
                  >
                    add_a_photo
                  </i>
                </div>
                <div style={{ display: "inline-block", cursor: "pointer" }}>
                  <button
                    style={{
                      display: "inline-block",
                      textAlign: "center",
                      marginLeft: "25px",
                    }}
                    className="btn waves-effect waves-light red"
                    disabled={buttonDisabled || props.loading}
                    type="submit"
                  >
                    POST
                  </button>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{ marginBottom: "0px", marginTop: "10px" }}
            >
              {upload_preview.length != 0 ? (
                upload_preview.length === 1 ? (
                  <div className="row" key={mediaUrls[0].name}>
                    <div className="col s12">
                      <div className="card-image">
                        {mediaUrls[0].mediaType == "video/mp4" ? (
                          <Player playsInline src={mediaUrls[0].name}>
                            <BigPlayButton position="center" />
                          </Player>
                        ) : (
                          <img
                            className="materialboxed responsive-img"
                            src={mediaUrls[0].name}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row" key="slider">
                    <div className="col s12">
                      <ImageGallery
                        items={upload_preview}
                        showFullscreenButton={false}
                        infinite={false}
                        showPlayButton={false}
                        showThumbnails={false}
                        showBullets={true}
                        showNav={false}
                      />
                    </div>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>

            <ToastContainer
              style={{ marginBottom: "0px", marginTop: "10px" }}
            />
            {loading ? (
              <div>
                <span>Uploading...</span>
                <div className="progress">
                  <div className="indeterminate"></div>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userLoading: state.auth.loading,

    post: state.post.post,
    loading: state.post.loading,
    error: state.post.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadPost: (media, description) =>
      dispatch(uploadPost(media, description)),
    updateProfilePic: (profilePic) => dispatch(updateProfilePic(profilePic)),
    updateProfileBackground: (profileBackground) =>
      dispatch(updateProfileBackground(profileBackground)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);
