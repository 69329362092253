import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";
import "./ForgotPassword.css";
import axios from "../../axios";

const ForgotPassword = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [email, setEmail] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, setError] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    setButtonDisabled(true);
    setError("");
    axios
      .post("forgotPassword", { email })
      .then((response) => {
        console.log(response.data);
        setButtonDisabled(false);
        if (response.data === "We didn't find any user with this email address")
          setError(response.data);
        else {
          M.toast({ html: "Recovery email sent" });
        }
      })
      .catch((error) => {
        setButtonDisabled(false);
        console.log(error.data);
      });
  };

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    M.AutoInit();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const onEmailChange = (value) => {
    setEmail(value);
    if (value.length > 0) setButtonDisabled(false);
    else setButtonDisabled(true);
  };

  return (
    <div
      style={{
        backgroundColor: "#fe0000",
        height: "100vh",
        position: "relative",
      }}
    >
      <div
        style={{
          textAlign: "center",
          top: "50%",
          position: "relative",
          margin: "0",
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
        }}
      >
        <div
          className="card "
          style={{
            width: "25rem",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "20px",
          }}
        >
          <div className="card-content">
            <h5 className="card-title">Forgot password ?</h5>
            <br />
            <form onSubmit={(e) => sendEmail(e)}>
              <div className="input-field">
                <i
                  className="material-icons prefix"
                  style={{ color: "#fe0000" }}
                >
                  email
                </i>
                <input
                  id="email"
                  type="email"
                  className="validate"
                  value={email}
                  onChange={(e) => onEmailChange(e.target.value)}
                  required
                />
                <label htmlFor="email">Email</label>
              </div>

              <br />

              <div className="container">
                <div className="row">
                  <div className="col s12">
                    <button
                      type="submit"
                      disabled={buttonDisabled}
                      className="btn waves-effect waves-light red"
                    >
                      {buttonDisabled && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      Send Password Reset Email
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <br />
            <Link to="/reset">Go back to Login</Link>
            <br />
            {error ? <p style={{ color: "red" }}> * {error}</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
