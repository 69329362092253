import React from 'react';
import NavNotSignedIn from '../../NavNotSignedIn/NavNotSignedIn';
import Footer from '../Footer';

const UserAgreement = (props) => {

    return (
        <div>
            <NavNotSignedIn />
            <h4 style={{ textAlign: 'center' }}>User Agreement</h4>
            <div className="container">
                <div className="row">
                    <p>
                        By visiting or accessing www.bongkonect.com you are agreeing to be bound by the following terms and conditions. We may change these terms and conditions time to time. Your continued use of Bongkonect means that you accept any new or modified terms and conditions that we come up with. Please re‐visit the `Terms of Use' link at our site from time to time to stay abreast of any changes that we may introduce or else if you subscribe to our mailing list then you shall be notified to time about any changes Terms and Conditions.
                    </p>
                </div>

                <div className="row">
                    <p>
                        The term "Bongkonect' is used through this entire Terms of Use document to refer to the website, its Parental body M/S Bongkonect; and the employees and associates of the company.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        1) Signing Up
                    </h6>
                </div>

                <div className="row">
                    <p>
                        By Signing Up, you certify that all information you provide, now or in the future, is accurate.
                        Bongkont reserves the right, in its sole discretion, to deny you access to this Web APP or any portion thereof without notice for the following reasons <br /> (a) immediately by Bongkonect for any unauthorized access or use by you <br /> (b) immediately by Bongkonect if you assign or transfer (or attempt the same) any rights granted to you under this Agreement. <br /> (c) immediately, if you violate any of the other terms and conditions of this User Agreement.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        2) LICENSE
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Bongkonect, hereby grants you a limited, non‐exclusive, non‐assignable and non‐transferable license to access Bongkonect, provided and expressly conditioned upon your agreement that all such access and use shall be governed by all of the terms and conditions set forth in this USER AGREEMENT and that a user “may not rent, lease, lend, sell, redistribute, or sublicense the licensed application,” nor may a user “copy, decompile, reverse engineer, disassemble” the Website.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        3) COPYRIGHT & NO RETRANSMISSION OF INFORMATION
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Bongkonect as well as the design and information contained in this site is the valuable, exclusive property of Bongkonect & also its parental body M/S Bongkonect , and nothing in this Agreement shall be construed as transferring or assigning any such ownership rights to you or any other person or entity. All stock call information on Bongkonect is the proprietary, confidential property of Bongkonectand cannot be repeated for any reason outside the Bongkonect. You agree not to repeat or rebroadcast in any way any of the stock call information made on Bongkonect for any reason whatsoever. You agree that if you do repeat or re‐post any of Bongkonect listings by any mean, you will be liable for actual and punitive damages as determined by Bongkonect and additional damages to be determined by court of Law.
                    </p>

                    <p>
                        You may not resell, redistribute, broadcast or transfer the information or use the information in a searchable, machine‐readable database unless separately and specifically authorized in writing by Bongkonect prior to such use. You may not license, lease, distribute, transfer, copy, reproduce, publicly display, publish, adapt, store or time‐share Bongkonect, any part thereof, or any of the information received or accessed therefrom to or through any other person or entity unless separately and specifically authorized in writing by Bongkonect prior to such use. In addition, you may not remove, alter or obscure any copyright, legal or proprietary notices in or on any portions of Bongkonect without prior written authorization Except as set forth herein, any other use of the information contained in this site requires the prior written consent of Bongkonect and may require a separate fee.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        4) DELAYS IN SERVICES
                    </h6>
                </div>

                <div className="row">
                    <p>
                        Neither Bongkonect(including its and their directors, employees, affiliates, agents, representatives or
                        subcontractors) shall be liable for any loss or liability resulting, directly or indirectly, from delays or interruptions due to electronic or mechanical equipment failures, telephone interconnect problems, defects, weather, strikes, walkouts, fire, acts of God, riots, armed conflicts, acts of war, or other like causes.Bongkonect shall have no responsibility to provide you access to Bongkonect while interruption of website is due to any such cause shall continue.
                    </p>

                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        5) LIABILITY DISCLAIMER
                    </h6>
                </div>

                <div className="row">
                    <p>
                        YOU EXPRESSLY AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK.
                    </p>

                    <p>
                        THE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES PUBLISHED ON THIS WEB SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE CONTENTS HEREIN .Bongkonect AND/OR ITS RESPECTIVE SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THIS WEBSITE AT ANY TIME. THIS WEBSITE MAY BE TEMPORARILY UNAVAILABLE FROM TIME TO TIME DUE TO REQUIRED MAINTENANCE, TELECOMMUNICATIONS INTERRUPTIONS, OR OTHER DISRUPTIONS. Bongkonect(AND ITS OWNERS, SUPPLIERS, CONSULTANTS, ADVERTISERS, AFFILIATES, PARTNERS, EMPLOYEES OR ANY OTHER ASSOCIATED ENTITIES, ALL COLLECTIVELY REFERRED TO AS ASSOCIATED ENTITIES HEREAFTER) SHALL NOT BE LIABLE TO USER OR MEMBER OR ANY THIRD PARTY SHOULD Bongkonect EXERCISE ITS RIGHT TO MODIFY OR DISCONTINUE ANY OR ALL OF THE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES PUBLISHED ON THIS WEBSITE.
                    </p>

                    <p>
                        Bongkonect AND/OR ITS RESPECTIVE ASSOCIATED ENTITIES MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES CONTAINED ON THIS WEBSITE FOR ANY PURPOSE. ALL SUCH CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. Bongkonect AND/OR ITS ASSOCIATED ENTITIES HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THESE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON‐INFRINGEMENT, AND AVAILABILITY.
                    </p>

                    <p>
                        IN NO EVENT SHALL Bongkonect AND/OR ITS ASSOCIATED ENTITIES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS WEBSITE OR WITH THE DELAY OR INABILITY TO USE THIS WEBSITE, OR FOR ANY CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES OBTAINED THROUGH THIS WEBSITE, OR OTHERWISE ARISING OUT OF THE USE OF THIS WEBSITE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF Bongkonect OR ANY OF ITS ASSOCIATED ENTITIES HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
                    </p>

                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        6) INFORMATION DISCLAIMER
                    </h6>
                </div>

                <div className="row">
                    <p>
                        You acknowledge that the information provided through Bongkonect is compiled from sources, which are beyond the control of Bongkonect though such information is recognized by the parties to be generally reliable, the parties acknowledge that inaccuracies may occur and Bongkonect does not warrant the accuracy or suitability of the information. For this reason, as well as the possibility of human and mechanical errors and other factors, YOU ACKNOWLEDGE THAT Bongkonect IS PROVIDED TO YOU ON AN "AS IS, WITH ALL FAULTS" BASIS. Bongkonect EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS, ORAL, IMPLIED, STATUTORY OR OTHERWISE, OF ANY KIND TO THE USERS AND/OR ANY THIRD PARTY, INCLUDING ANY IMPLIED WARRANTIES OF ACCURACY, TIMELINESS, COMPLETENESS, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS ANY WARRANTIES ARISING BY VIRTUE OF CUSTOM OF TRADE OR COURSE OF DEALING AND ANY IMPLIED WARRANTIES OF TITLE OR
                        NON‐INFRINGEMENT. IN ADDITION,Bongkonect, IN PROVIDING THE INFORMATION MAKES NO ENDORSEMENT OF ANY PARTICULAR SECURITY, MARKET PARTICIPANT, OR BROKERAGE. FURTHER,Bongkonect DOES NOT REPRESENT OR WARRANT THAT IT WILL MEET YOUR REQUIREMENTS OR IS SUITABLE FOR YOUR NEEDS.
                    </p>

                    <p>
                        Under this User Agreement, you assume all risk of errors and/or omissions in Bongkonect, including the transmission or translation of information. You assume full responsibility for implementing sufficient procedures and checks to satisfy your requirements for the accuracy and suitability of Bongkonect, including the information, and for maintaining any means, which you may require for the reconstruction of lost data or subsequent manipulations or analysis of the information under the User Agreement.
                        YOU AGREE THAT Bongkonect(INCLUDING ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, GROUP COMPANIES AGENTS, REPRESENTATIVES OR SUBCONTRACTORS) SHALL NOT IN ANY EVENT BE LIABLE FOR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE Bongkonect FOR ANY PURPOSE WHATSOEVER.Bongkonect AND ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS SHALL HAVE NO LIABILITY IN TORT, CONTRACT, OR OTHERWISE TO USER AND/OR ANY THIRD PARTY.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        7) INDEMNIFICATION
                    </h6>
                </div>

                <div className="row">
                    <p>
                        YOU SHALL INDEMNIFY, DEFEND AND HOLD HARMLESS Bongkonect(INCLUDING ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, GROUP COMPANIES, AGENTS, REPRESENTATIVES OR SUBCONTRACTORS) FROM ANY AND ALL CLAIMS AND LOSSES IMPOSED ON, INCURRED BY OR ASSERTED AS A RESULT OF OR RELATED TO: (a) your access and use of Bongkonect
                        (b) any non‐compliance by user with the terms and conditions hereof; or (c) any third party actions related to users receipt and use of the information, whether authorized or unauthorized. Any clause declared invalid shall be deemed severable and not affect the validity or enforceability of the remainder. These terms may only be amended in a writing signed by
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        8) ATTORNEY'S FEES
                    </h6>
                </div>

                <div className="row">
                    <p>
                        If Bongkonect takes action (by itself or through its associate companies) to enforce any of the provisions of this User Agreement, including collection of any amounts due hereunder, M/S Bongkonect shall be entitled to recover from you (and you agree to pay), in addition to all sums to which it is entitled or any other relief, at law or in equity, reasonable and necessary attorney's fees and any costs of any litigation.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        9) ENTIRE AGREEMENT
                    </h6>
                </div>

                <div className="row">
                    <p>
                        This User Agreement constitutes the entire agreement between the parties, and no other agreement, written or oral, exists between you and Bongkonect. This agreement represents the entire understanding between the parties with respect to its subject matter and supersedes any previous communication or agreements that may exist.
                    </p>
                </div>

                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>
                        10) JURISDICTION
                    </h6>
                </div>

                <div className="row">
                    <p>
                        The terms of this agreement are exclusively based on and subject to Indian law. You hereby consent to the exclusive jurisdiction and venue of courts in India, all disputes arising out of or relating to the use of this site is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this paragraph.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default UserAgreement;