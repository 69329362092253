import React, { useCallback, useEffect, useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CustomButton = withStyles({
  root: {
    "& .MuiButton-label": {
      width: "6rem",
      textTransform: "capitalize",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "space-between",
    },
  },
})(Button);
const DialogTitle = withStyles({
  root: {
    "& .MuiDialogTitle-root": {
      alignItems: "center",
      color: "red",
    },
  },
})(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ImageCrop = ({
  onChange,
  disabled,
  aspect = 1,
  open,
  setOpen,
  file,
  updateFile,
  ...otherProps
}) => {
  console.log(open);
  const handleClose = () => {
    setUpImg(null);
    setCompletedCrop(null);
    setOpen(false);
  };

  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(() => {
    if (file) setUpImg(URL.createObjectURL(file));
  }, [file]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
    const width =
      img.width / aspect < img.height * aspect
        ? 100
        : ((img.height * aspect) / img.width) * 100;
    const height =
      img.width / aspect > img.height * aspect
        ? 100
        : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;

    setCrop({
      unit: "%",
      width,
      height,
      x,
      y,
      aspect,
    });

    return false;
  }, []);

  useEffect(() => {
    if (!crop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;

    const scaleX = image.naturalWidth * (crop.x / 100);
    const scaleY = image.naturalHeight * (crop.y / 100);
    const sWidth = image.naturalWidth * (crop.width / 100);
    const sHeight = image.naturalHeight * (crop.height / 100);
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = sWidth;
    canvas.height = sHeight;

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      scaleX,
      scaleY,
      sWidth,
      sHeight,
      0,
      0,
      sWidth,
      sHeight
    );
  }, [crop]);

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
        updateFile(blob);
        handleClose();
      },
      "image/png",
      1
    );
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          className="self-center"
          onClose={handleClose}
        >
          Crop Image
        </DialogTitle>
        <hr className="text-darkerBlue w-44 self-center" />
        <DialogContent dividers>
          <ReactCrop
            className="w-full"
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(_, percentCrop) => setCrop(percentCrop)}
            locked={false}
          />

          <div className="hidden">
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <CustomButton
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => generateDownload(previewCanvasRef.current, crop)}
            color="primary"
            variant="outlined"
          >
            Save
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageCrop;
