import React, { useState, useEffect } from "react";
import MyNav from "../../components/Nav/Nav";
import JobPoster from "../../components/JobPoster/JobPoster";
import CandidateProfile from "../../components/CandidateProfile/CandidateProfile";
import axios from "../../axios";
import "react-toastify/dist/ReactToastify.css";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavNotSignedIn from "../../components/NavNotSignedIn/NavNotSignedIn";
import { connect } from "react-redux";

const Jobs = (props) => {
  const [jobsLoading, setJobsLoading] = useState(true);
  const [profilesLoading, setProfilesLoading] = useState(true);

  const [jobs, setJobs] = useState([]);
  const [jobProfiles, setJobProfiles] = useState([]);

  const [profileSearchText, setProfileSearchText] = useState("");
  const [profileSearchLocation, setProfileSearchLocation] = useState("");
  const [jobsSearchText, setJobsSearchText] = useState("");
  const [jobsSearchLocation, setJobsSearchLocation] = useState("");

  useEffect(() => {
    getJobProfiles();
    getJobs();

    const jobProfilesInterval = setInterval(() => getJobProfiles(), 10000);

    const jobsInterval = setInterval(() => getJobs(), 10000);

    return () => {
      clearInterval(jobProfilesInterval);
      clearInterval(jobsInterval);
    };
  }, []);

  const getJobProfiles = () => {
    axios
      .get("getJobProfiles")
      .then((response) => {
        setProfilesLoading(false);
        setJobProfiles(response.data.jobProfiles);
      })
      .catch((err) => setProfilesLoading(false));
  };

  const getJobs = () => {
    axios
      .get("getJobs")
      .then((response) => {
        setJobsLoading(false);
        setJobs(response.data.jobs);
      })
      .catch((err) => setJobsLoading(false));
  };

  const searchProfileFormSubmit = (e) => {
    e.preventDefault();
    props.history.push(
      "search-profile?query=" +
        profileSearchText +
        "&" +
        "location=" +
        profileSearchLocation.toLowerCase()
    );
  };

  const searchJobFormSubmit = (e) => {
    e.preventDefault();
    props.history.push(
      "search-job?query=" +
        jobsSearchText +
        "&" +
        "location=" +
        jobsSearchLocation.toLowerCase()
    );
  };

  const MyLoader = () => (
    <ContentLoader
      height={400}
      width={320}
      speed={2}
      primaryColor="#dfdcdc"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="320" height="400" />
    </ContentLoader>
  );

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Bongkonect - konect your social business and professional community
        </title>
        <meta
          name="description"
          content="Bongkonect - konect your social business and professional community"
        />
        <meta
          name="keywords"
          content="Bengali biya, bangali shadi, bangla cultre, bengalee association,Bengali connection"
        />
      </Helmet>
      {props.isAuthenticatedIndividual || props.isAuthenticatedBusiness ? (
        <MyNav location={props.location} />
      ) : (
        <NavNotSignedIn location={props.location} />
      )}

      <br />

      <div className="container">
        <form className="row" onSubmit={(e) => searchProfileFormSubmit(e)}>
          <div className="col s3" style={{ marginRight: "22px" }}>
            <i
              className="material-icons"
              style={{ position: "absolute", padding: "10px", color: "grey" }}
            >
              search
            </i>
            <input
              type="search"
              name="query"
              placeholder="Search Skill"
              value={profileSearchText}
              onChange={(e) => setProfileSearchText(e.target.value)}
              style={{
                backgroundColor: "white",
                padding: "0px 20px 0px 40px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "grey",
              }}
            />
          </div>

          <div className="col s3" style={{ marginLeft: "22px" }}>
            <i
              className="material-icons"
              style={{ position: "absolute", padding: "10px", color: "grey" }}
            >
              location_on
            </i>
            <input
              type="search"
              name="location"
              placeholder="Location"
              value={profileSearchLocation}
              onChange={(e) => setProfileSearchLocation(e.target.value)}
              style={{
                backgroundColor: "white",
                padding: "0px 20px 0px 40px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "grey",
              }}
            />
          </div>

          <div className="col s2">
            <button
              className="btn"
              type="submit"
              style={{
                height: "45px",
                marginLeft: "45px",
                backgroundColor: "#fe0000",
              }}
            >
              <i
                className="material-icons"
                style={{ fontSize: "2em", paddingTop: "5px" }}
              >
                search
              </i>
            </button>
          </div>

          <div className="col s3 right hide-on-small-only">
            {props.isAuthenticatedIndividual ||
            props.isAuthenticatedBusiness ? (
              <Link to="/post-profile">
                <button
                  className="btn right"
                  style={{
                    height: "45px",
                    width: "140px",
                    marginLeft: "20px",
                    backgroundColor: "#fe0000",
                  }}
                >
                  Post Profile
                </button>
              </Link>
            ) : (
              <Link to="/login">
                <button
                  className="btn right"
                  style={{
                    height: "45px",
                    width: "140px",
                    marginLeft: "20px",
                    backgroundColor: "#fe0000",
                  }}
                >
                  Post Profile
                </button>
              </Link>
            )}
          </div>
        </form>
      </div>

      <div className="container">
        {profilesLoading && (
          <div className="row">
            <div className="col s12 m3 l3 xl3">
              <MyLoader />
            </div>
            <div className="col s12 m3 l3 xl3">
              <MyLoader />
            </div>
            <div className="col s12 m3 l3 xl3">
              <MyLoader />
            </div>
            <div className="col s12 m3 l3 xl3">
              <MyLoader />
            </div>
          </div>
        )}

        <div className="row">
          {jobProfiles.map((profile, index) => {
            return (
              <div className="col s12 m3 l3 xl3" key={profile._id}>
                <CandidateProfile profile={profile} />
              </div>
            );
          })}
        </div>
      </div>

      <div className="container">
        <form className="row" onSubmit={(e) => searchJobFormSubmit(e)}>
          <div className="col s3" style={{ marginRight: "22px" }}>
            <i
              className="material-icons"
              style={{ position: "absolute", padding: "10px", color: "grey" }}
            >
              search
            </i>
            <input
              type="search"
              placeholder="Search Jobs..."
              value={jobsSearchText}
              onChange={(e) => setJobsSearchText(e.target.value)}
              style={{
                backgroundColor: "white",
                padding: "0px 20px 0px 40px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "grey",
              }}
              required
            />
          </div>

          <div className="col s3" style={{ marginLeft: "22px" }}>
            <i
              className="material-icons"
              style={{ position: "absolute", padding: "10px", color: "grey" }}
            >
              location_on
            </i>
            <input
              type="search"
              placeholder="Location"
              value={jobsSearchLocation}
              onChange={(e) => setJobsSearchLocation(e.target.value)}
              style={{
                backgroundColor: "white",
                padding: "0px 20px 0px 40px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "grey",
              }}
            />
          </div>

          <div className="col s2">
            <button
              type="submit"
              className="btn"
              style={{
                height: "45px",
                marginLeft: "45px",
                backgroundColor: "#fe0000",
              }}
            >
              <i
                className="material-icons"
                style={{ fontSize: "2em", paddingTop: "5px" }}
              >
                search
              </i>
            </button>
          </div>

          <div className="col s3 right hide-on-small-only">
            {props.isAuthenticatedIndividual ||
            props.isAuthenticatedBusiness ? (
              <Link to="post-job">
                <button
                  className="btn right"
                  style={{
                    height: "45px",
                    width: "140px",
                    marginLeft: "20px",
                    backgroundColor: "#fe0000",
                    textAlign: "center",
                  }}
                >
                  Post a Job
                </button>
              </Link>
            ) : (
              <Link to="login">
                <button
                  className="btn right"
                  style={{
                    height: "45px",
                    width: "140px",
                    marginLeft: "20px",
                    backgroundColor: "#fe0000",
                    textAlign: "center",
                  }}
                >
                  Post a Job
                </button>
              </Link>
            )}
          </div>
        </form>
      </div>

      <div className="container">
        {jobsLoading && (
          <div className="row">
            <div className="col s12 m3 l3 xl3">
              <MyLoader />
            </div>
            <div className="col s12 m3 l3 xl3">
              <MyLoader />
            </div>
            <div className="col s12 m3 l3 xl3">
              <MyLoader />
            </div>
            <div className="col s12 m3 l3 xl3">
              <MyLoader />
            </div>
          </div>
        )}

        <div className="row">
          {jobs.map((job, index) => {
            return (
              <div className="col s12 m3 l3 xl3" key={job._id}>
                <JobPoster job={job} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticatedIndividual:
      state.auth.token !== null && state.auth.isAuthenticatedIndividual,
    isAuthenticatedBusiness:
      state.auth.token !== null && state.auth.isAuthenticatedBusiness,
  };
};

export default connect(mapStateToProps)(Jobs);
