import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    post: null,
    error: null,
    loading: false
}

const createPostReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.POST_UPLOAD_START:
            return {
                ...state,
                loading: true,
                error: null
            }

        case actionTypes.POST_UPLOAD_SUCCESS:
            return {
                ...state,
                post: action.payload.post,
                loading: false
            }

        case actionTypes.POST_UPLOAD_FAILED:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            }

        default:
            return state;
    }
}

export default createPostReducer;